import styled from "styled-components";

export const TabSelectSectionBtn = styled.button`
  background-color: ${(props) =>
    props.isselected === "true" ? "#ddb20b" : "#fff"};
  color: ${(props) => (props.isselected === "true" ? "#ffffff" : "#000")};
  border: 1px solid
    ${(props) => (props.isselected === "true" ? "#ddb20b" : "#ddb20b")};
  cursor: pointer;
  border-radius: 5px;
  height: 100%;
  width: content;
  font-weight: bold;
  font-size: 1rem;
  flex-grow: 1;
`;

import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FaUnlock, FaUser } from "../../design_system/icons/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../data_system/context/auth_context/AuthContext";
import {
  best_home_inner_router_url,
  home_inner_router_url,
  login_inner_router_url,
  marketing_policy_inner_router_url,
  privacy_policy_inner_router_url,
  refund_policy_inner_router_url,
  sign_up_inner_router_url,
  terms_and_conditions_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import axios from "axios";
import {
  postCompanyNumberCheck,
  postSendAuthSMS,
  uploadImage,
} from "../../communication_system/axios_apis/axios_apis";
import { isOnlyNumber } from "../../data_system/data_function/is_only_number";
import { usePopup } from "../../data_system/context/popup_context/PopupContext";
import { emailCheckFunction } from "../../data_system/data_function/email_check_function";
import { phoneNumberCheckFunction } from "../../data_system/data_function/phone_number_check_function";

const SignUpPage = () => {
  const { show } = usePopup();
  let navigate = useNavigate();

  const { handleSignUp, logout } = useAuth();

  const [userType, setUserType] = useState("");

  const handleSelectChange = (event) => {
    setUserType(event.target.value);
    setCompanyNumberChecked(false);
    setImgFile(null);
    setImgFileName("파일 선택");
    setPhoneNumberText("");
    setPhoneNumberCheckStart(false);
    setAuthNumberChecked(false);
    setGetAuthNumber("");
    setWriteAuthNumber("");
    setIsNecessaryAgreeChecked(false);
    setIsMarketingAgreeChecked(false);
    setAdvertiserNameText("");
    setCompanyNumber("");
    setBusinessNameText("");
    setEmailText("");
    setPasswordText("");
    setPasswordCheckText("");
  };

  const [isNecessaryAgreeChecked, setIsNecessaryAgreeChecked] = useState(false);

  const handleNecessaryAgreeCheckboxChange = (event) => {
    setIsNecessaryAgreeChecked(event.target.checked);
  };

  const [isMarketingAgreeChecked, setIsMarketingAgreeChecked] = useState(false);

  const handleMarketingAgreeCheckboxChange = (event) => {
    console.log(event.target.checked);
    setIsMarketingAgreeChecked(event.target.checked);
  };

  const [phoneNumberCheckStart, setPhoneNumberCheckStart] = useState(false);

  const [getAuthNumber, setGetAuthNumber] = useState("");
  const [writeAuthNumber, setWriteAuthNumber] = useState("");

  const [authNumberChecked, setAuthNumberChecked] = useState(false);

  const [companyNumberChecked, setCompanyNumberChecked] = useState(false);

  const [companyNumber, setCompanyNumber] = useState("");

  const [imgFile, setImgFile] = useState(null);
  const [imgFileName, setImgFileName] = useState("파일 선택");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setImgFile(file);
      setImgFileName(file.name);
    } else {
      setImgFile(null);
      setImgFileName("파일 선택");
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const [advertiserNameText, setAdvertiserNameText] = useState("");
  const [businessNameText, setBusinessNameText] = useState("");
  const [emailText, setEmailText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [passwordCheckText, setPasswordCheckText] = useState("");
  const [phoneNumberText, setPhoneNumberText] = useState("");

  const totalHandleSignUp = async (
    businessTypeId,
    advertiserName,
    businessRegistrationNumber,
    businessRegistrationImageUrl,
    businessName,
    email,
    password,
    phoneNumber,
    privacyPolicyAgreed,
    servicePolicyAgreed,
    refundPolicyAgreed,
    marketingPolicyAgreed
  ) => {
    const response = await handleSignUp(
      businessTypeId,
      advertiserName,
      businessRegistrationNumber,
      businessRegistrationImageUrl,
      businessName,
      email,
      password,
      phoneNumber,
      privacyPolicyAgreed,
      servicePolicyAgreed,
      refundPolicyAgreed,
      marketingPolicyAgreed
    );
    console.log(response);

    if (parseInt(response.status) === 201) {
      setCompanyNumberChecked(false);
      setImgFile(null);
      setImgFileName("파일 선택");
      setPhoneNumberText("");
      setPhoneNumberCheckStart(false);
      setAuthNumberChecked(false);
      setGetAuthNumber("");
      setWriteAuthNumber("");
      setIsNecessaryAgreeChecked(false);
      setIsMarketingAgreeChecked(false);
      setAdvertiserNameText("");
      setCompanyNumber("");
      setBusinessNameText("");
      setEmailText("");
      setPasswordText("");
      setPasswordCheckText("");
      alert("회원가입 신청을 완료하였습니다.");
      alert("승인 대기중입니다. 관리자 승인 후 로그인 가능합니다.");
      navigate(login_inner_router_url);
    } else if (parseInt(response.status) === 400) {
      setCompanyNumberChecked(false);
      setImgFile(null);
      setImgFileName("파일 선택");
      setPhoneNumberText("");
      setPhoneNumberCheckStart(false);
      setAuthNumberChecked(false);
      setGetAuthNumber("");
      setWriteAuthNumber("");
      setIsNecessaryAgreeChecked(false);
      setIsMarketingAgreeChecked(false);
      setAdvertiserNameText("");
      setCompanyNumber("");
      setBusinessNameText("");
      setEmailText("");
      setPasswordText("");
      setPasswordCheckText("");
      show("이메일을 확인해주세요.", false);
    } else if (parseInt(response.status) === 403) {
      setCompanyNumberChecked(false);
      setImgFile(null);
      setImgFileName("파일 선택");
      setPhoneNumberText("");
      setPhoneNumberCheckStart(false);
      setAuthNumberChecked(false);
      setGetAuthNumber("");
      setWriteAuthNumber("");
      setIsNecessaryAgreeChecked(false);
      setIsMarketingAgreeChecked(false);
      setAdvertiserNameText("");
      setCompanyNumber("");
      setBusinessNameText("");
      setEmailText("");
      setPasswordText("");
      setPasswordCheckText("");
      show("사업자등록번호를 확인해주세요.", false);
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <SignUpWrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn
            onClick={() => {
              navigate(login_inner_router_url);
            }}
          >
            로그인
          </ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn
              onClick={() => {
                navigate(sign_up_inner_router_url);
              }}
            >
              시작하기
            </ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Main>
        <Container>
          <SignUpMain>
            <SignUpMainWrap>
              <SignUpMainWrapH2>머니모아 회원가입</SignUpMainWrapH2>
              <SignUpForm>
                <JoinWrapDiv>
                  <JoinWrapDivLabel>회원 유형</JoinWrapDivLabel>
                  <JoinWrapDivSelect
                    id="userType"
                    name="userType"
                    onChange={handleSelectChange}
                    value={userType}
                  >
                    <option value="">선택하세요</option>
                    <option value="individual_business">개인사업자</option>
                    <option value="corp_business">법인사업자</option>
                  </JoinWrapDivSelect>
                </JoinWrapDiv>
                {userType === "" ? (
                  <></>
                ) : userType === "individual" ? (
                  <>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>이름</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="text"
                        value={advertiserNameText}
                        onChange={(e) => {
                          setAdvertiserNameText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>계정이메일</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="email"
                        value={emailText}
                        onChange={(e) => {
                          setEmailText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>비밀번호</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="password"
                        value={passwordText}
                        onChange={(e) => {
                          setPasswordText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>비밀번호 확인</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="password"
                        value={passwordCheckText}
                        onChange={(e) => {
                          setPasswordCheckText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>연락처</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        value={phoneNumberText}
                        onChange={(e) => {
                          if (
                            isOnlyNumber.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setPhoneNumberText(e.target.value);
                          }
                        }}
                        type="text"
                        placeholder="- 없이 번호를 입력해주세요"
                      ></JoinWrapDivInput>
                      <div
                        style={{
                          width: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "150px",
                          height: "30px",
                          fontSize: "0.8rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          backgroundColor: "#ffe100",
                          border: "1px solid #ffe100",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                        onClick={async () => {
                          console.log(phoneNumberText);
                          if (phoneNumberText !== "") {
                            if (
                              phoneNumberCheckFunction.test(phoneNumberText)
                            ) {
                              if (phoneNumberCheckStart === false) {
                                const response = await postSendAuthSMS(
                                  phoneNumberText
                                );

                                console.log(response);
                                if (response.status === 200) {
                                  alert("인증번호가 발송되었습니다.");
                                  setGetAuthNumber(response.data.authNumber);
                                  setPhoneNumberCheckStart(true);
                                }
                              } else {
                                const response = await postSendAuthSMS(
                                  phoneNumberText
                                );

                                console.log(response);
                                if (response.status === 200) {
                                  alert("인증번호가 발송되었습니다.");
                                  setGetAuthNumber(response.data.authNumber);
                                  setPhoneNumberCheckStart(true);
                                  setAuthNumberChecked(false);
                                }
                              }
                            } else {
                              alert("휴대폰 번호 형식을 확인해주세요");
                            }
                          } else {
                            alert("휴대폰 번호를 입력해주세요");
                          }
                        }}
                      >
                        {!phoneNumberCheckStart ? "인증하기" : "재전송"}
                      </div>
                    </JoinWrapDiv>
                    {phoneNumberCheckStart ? (
                      <JoinWrapDiv>
                        <JoinWrapDivLabel>인증번호 검증</JoinWrapDivLabel>
                        <JoinWrapDivInput
                          value={writeAuthNumber}
                          onChange={(e) => {
                            if (
                              isOnlyNumber.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setWriteAuthNumber(e.target.value);
                            }
                          }}
                          type="text"
                          placeholder="인증번호 입력"
                        ></JoinWrapDivInput>
                        <div
                          style={{
                            width: "10px",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "210px",
                            height: "30px",
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#000",
                            backgroundColor: "#ffe100",
                            border: "1px solid #ffe100",
                            borderRadius: "5px",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            if (getAuthNumber === writeAuthNumber) {
                              alert("인증되었습니다.");
                              setAuthNumberChecked(true);
                            } else {
                              alert("인증번호를 다시 확인해주세요");
                            }
                          }}
                        >
                          검증하기
                        </div>
                      </JoinWrapDiv>
                    ) : (
                      <></>
                    )}
                  </>
                ) : userType === "individual_business" ? (
                  <>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>이름</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="text"
                        value={advertiserNameText}
                        onChange={(e) => {
                          setAdvertiserNameText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>사업자등록번호</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="text"
                        placeholder="- 없이 번호를 입력해주세요"
                        value={companyNumber}
                        onChange={(e) => {
                          if (
                            isOnlyNumber.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setCompanyNumber(e.target.value);
                          }
                        }}
                      ></JoinWrapDivInput>
                      <div
                        style={{
                          width: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "150px",
                          height: "30px",
                          fontSize: "0.8rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          backgroundColor: "#ffe100",
                          border: "1px solid #ffe100",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                        onClick={async () => {
                          const response = await postCompanyNumberCheck(
                            companyNumber
                          );
                          console.log(response);
                          console.log(response.data.data.data[0].b_stt_cd);
                          if (response.status === 200) {
                            if (response.data.data.data[0].b_stt_cd === "01") {
                              alert("인증되었습니다.");
                              setCompanyNumberChecked(true);
                            } else if (
                              response.data.data.data[0].b_stt_cd === "02"
                            ) {
                              alert("휴업된 사업자입니다.");
                            } else if (
                              response.data.data.data[0].b_stt_cd === "03"
                            ) {
                              alert("폐업된 사업자입니다.");
                            } else {
                              alert("사업자등록번호를 다시 확인해주세요.");
                            }
                          }
                        }}
                      >
                        인증하기
                      </div>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>사업자등록증</JoinWrapDivLabel>
                      <JoinWrapDivLabel>{imgFileName}</JoinWrapDivLabel>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }} // input을 숨깁니다.
                        accept=".png, .jpg, .jpeg, .pdf" // 허용하는 파일 형식
                        onChange={handleFileChange}
                      />

                      <div
                        style={{
                          width: "150px",
                          height: "30px",
                          fontSize: "0.8rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          backgroundColor: "#ffe100",
                          border: "1px solid #ffe100",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                        onClick={handleClick}
                      >
                        업로드
                      </div>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>상호(업체명)</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="text"
                        value={businessNameText}
                        onChange={(e) => {
                          setBusinessNameText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>계정이메일</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="email"
                        value={emailText}
                        onChange={(e) => {
                          setEmailText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>비밀번호</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="password"
                        value={passwordText}
                        onChange={(e) => {
                          setPasswordText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>비밀번호 확인</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="password"
                        value={passwordCheckText}
                        onChange={(e) => {
                          setPasswordCheckText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>연락처</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        value={phoneNumberText}
                        onChange={(e) => {
                          if (
                            isOnlyNumber.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setPhoneNumberText(e.target.value);
                          }
                        }}
                        type="text"
                        placeholder="- 없이 번호를 입력해주세요"
                      ></JoinWrapDivInput>
                      <div
                        style={{
                          width: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "150px",
                          height: "30px",
                          fontSize: "0.8rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          backgroundColor: "#ffe100",
                          border: "1px solid #ffe100",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                        onClick={async () => {
                          console.log(phoneNumberText);
                          if (phoneNumberText !== "") {
                            if (
                              phoneNumberCheckFunction.test(phoneNumberText)
                            ) {
                              const response = await postSendAuthSMS(
                                phoneNumberText
                              );

                              console.log(response);
                              if (response.status === 200) {
                                alert("인증번호가 발송되었습니다.");
                                setGetAuthNumber(response.data.authNumber);
                                setPhoneNumberCheckStart(true);
                              }
                            } else {
                              alert("휴대폰 번호 형식을 확인해주세요");
                            }
                          } else {
                            alert("휴대폰 번호를 입력해주세요");
                          }
                        }}
                      >
                        {!phoneNumberCheckStart ? "인증하기" : "재전송"}
                      </div>
                    </JoinWrapDiv>
                    {phoneNumberCheckStart ? (
                      <JoinWrapDiv>
                        <JoinWrapDivLabel>인증번호 검증</JoinWrapDivLabel>
                        <JoinWrapDivInput
                          value={writeAuthNumber}
                          onChange={(e) => {
                            if (
                              isOnlyNumber.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setWriteAuthNumber(e.target.value);
                            }
                          }}
                          type="text"
                          placeholder="인증번호 입력"
                        ></JoinWrapDivInput>
                        <div
                          style={{
                            width: "10px",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "210px",
                            height: "30px",
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#000",
                            backgroundColor: "#ffe100",
                            border: "1px solid #ffe100",
                            borderRadius: "5px",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            if (getAuthNumber === writeAuthNumber) {
                              alert("인증되었습니다.");
                              setAuthNumberChecked(true);
                            } else {
                              alert("인증번호를 다시 확인해주세요");
                            }
                          }}
                        >
                          검증하기
                        </div>
                      </JoinWrapDiv>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>이름</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="text"
                        value={advertiserNameText}
                        onChange={(e) => {
                          setAdvertiserNameText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>사업자등록번호</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="text"
                        placeholder="- 없이 번호를 입력해주세요"
                        value={companyNumber}
                        onChange={(e) => {
                          if (isOnlyNumber.test(e.target.value)) {
                            setCompanyNumber(e.target.value);
                          }
                        }}
                      ></JoinWrapDivInput>
                      <div
                        style={{
                          width: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "150px",
                          height: "30px",
                          fontSize: "0.8rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          backgroundColor: "#ffe100",
                          border: "1px solid #ffe100",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                        onClick={async () => {
                          const response = await postCompanyNumberCheck(
                            companyNumber
                          );
                          console.log(response.data);
                          if (response.status === 200) {
                            if (response.data.data.data[0].b_stt_cd === "01") {
                              alert("인증되었습니다.");
                              setCompanyNumberChecked(true);
                            } else if (
                              response.data.data.data[0].b_stt_cd === "02"
                            ) {
                              alert("휴업된 사업자입니다.");
                            } else if (
                              response.data.data.data[0].b_stt_cd === "03"
                            ) {
                              alert("폐업된 사업자입니다.");
                            } else {
                              alert("사업자등록번호를 다시 확인해주세요.");
                            }
                          }
                        }}
                      >
                        인증하기
                      </div>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>사업자등록증</JoinWrapDivLabel>
                      <JoinWrapDivLabel>{imgFileName}</JoinWrapDivLabel>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }} // input을 숨깁니다.
                        accept=".png, .jpg, .jpeg, .pdf" // 허용하는 파일 형식
                        onChange={handleFileChange}
                      />

                      <div
                        style={{
                          width: "150px",
                          height: "30px",
                          fontSize: "0.8rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          backgroundColor: "#ffe100",
                          border: "1px solid #ffe100",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                        onClick={handleClick}
                      >
                        업로드
                      </div>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>법인명(단체명)</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="text"
                        value={businessNameText}
                        onChange={(e) => {
                          setBusinessNameText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>계정이메일</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="email"
                        value={emailText}
                        onChange={(e) => {
                          setEmailText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>비밀번호</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="password"
                        value={passwordText}
                        onChange={(e) => {
                          setPasswordText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>비밀번호 확인</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        type="password"
                        value={passwordCheckText}
                        onChange={(e) => {
                          setPasswordCheckText(e.target.value);
                        }}
                      ></JoinWrapDivInput>
                    </JoinWrapDiv>
                    <JoinWrapDiv>
                      <JoinWrapDivLabel>연락처</JoinWrapDivLabel>
                      <JoinWrapDivInput
                        value={phoneNumberText}
                        onChange={(e) => {
                          if (
                            isOnlyNumber.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setPhoneNumberText(e.target.value);
                          }
                        }}
                        type="text"
                        placeholder="- 없이 번호를 입력해주세요"
                      ></JoinWrapDivInput>
                      <div
                        style={{
                          width: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          width: "150px",
                          height: "30px",
                          fontSize: "0.8rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#000",
                          backgroundColor: "#ffe100",
                          border: "1px solid #ffe100",
                          borderRadius: "5px",
                          fontWeight: "bold",
                        }}
                        onClick={async () => {
                          console.log(phoneNumberText);
                          if (phoneNumberText !== "") {
                            if (
                              phoneNumberCheckFunction.test(phoneNumberText)
                            ) {
                              const response = await postSendAuthSMS(
                                phoneNumberText
                              );

                              console.log(response);
                              if (response.status === 200) {
                                alert("인증번호가 발송되었습니다.");
                                setGetAuthNumber(response.data.authNumber);
                                setPhoneNumberCheckStart(true);
                              }
                            } else {
                              alert("휴대폰 번호 형식을 확인해주세요");
                            }
                          } else {
                            alert("휴대폰 번호를 입력해주세요");
                          }
                        }}
                      >
                        {!phoneNumberCheckStart ? "인증하기" : "재전송"}
                      </div>
                    </JoinWrapDiv>
                    {phoneNumberCheckStart ? (
                      <JoinWrapDiv>
                        <JoinWrapDivLabel>인증번호 검증</JoinWrapDivLabel>
                        <JoinWrapDivInput
                          value={writeAuthNumber}
                          onChange={(e) => {
                            if (
                              isOnlyNumber.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setWriteAuthNumber(e.target.value);
                            }
                          }}
                          type="text"
                          placeholder="인증번호 입력"
                        ></JoinWrapDivInput>
                        <div
                          style={{
                            width: "10px",
                          }}
                        ></div>
                        <div
                          style={{
                            width: "210px",
                            height: "30px",
                            fontSize: "0.8rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#000",
                            backgroundColor: "#ffe100",
                            border: "1px solid #ffe100",
                            borderRadius: "5px",
                            fontWeight: "bold",
                          }}
                          onClick={() => {
                            if (getAuthNumber === writeAuthNumber) {
                              alert("인증되었습니다.");
                              setAuthNumberChecked(true);
                            } else {
                              alert("인증번호를 다시 확인해주세요");
                            }
                          }}
                        >
                          검증하기
                        </div>
                      </JoinWrapDiv>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <AgreeSection>
                  <CheckBox
                    id="necessaryAgreeCheckbox"
                    type="checkbox"
                    checked={isNecessaryAgreeChecked}
                    onChange={handleNecessaryAgreeCheckboxChange}
                  />
                  <AgreeLabel>
                    머니모아의{" "}
                    <AgreeLink
                      href={terms_and_conditions_inner_router_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      이용약관
                    </AgreeLink>
                    과{" "}
                    <AgreeLink
                      href={privacy_policy_inner_router_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      개인정보처리방침
                    </AgreeLink>{" "}
                    그리고{" "}
                    <AgreeLink
                      href={refund_policy_inner_router_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      환불정책
                    </AgreeLink>
                    에 동의합니다.
                  </AgreeLabel>
                </AgreeSection>
                <AgreeSection>
                  <CheckBox
                    id="marketingAgreeCheckbox"
                    type="checkbox"
                    checked={isMarketingAgreeChecked}
                    onChange={handleMarketingAgreeCheckboxChange}
                  />
                  <AgreeLabel>
                    머니모아의{" "}
                    <AgreeLink
                      href={marketing_policy_inner_router_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      마케팅 수신동의
                    </AgreeLink>
                    에 동의합니다.(선택)
                  </AgreeLabel>
                </AgreeSection>
                <BtnPart>
                  <SignUpBtn
                    onClick={async () => {
                      console.log(isMarketingAgreeChecked);
                      if (userType === "") {
                        alert("회원 유형을 선택해주세요");
                      } else {
                        if (!companyNumberChecked) {
                          alert("사업자등록번호 인증을 해주세요");
                        } else {
                          if (!authNumberChecked) {
                            alert("휴대폰 인증을 해주세요");
                          } else {
                            if (!isNecessaryAgreeChecked) {
                              alert("약관에 동의해주세요");
                            } else {
                              if (advertiserNameText === "") {
                                alert("이름을 입력해주세요");
                              } else {
                                if (companyNumber === "") {
                                  alert("사업자등록번호를 입력해주세요");
                                } else {
                                  if (
                                    imgFile === "파일 선택" ||
                                    imgFile === "" ||
                                    imgFile === null
                                  ) {
                                    alert("사업자등록증을 업로드해주세요");
                                  } else {
                                    if (businessNameText === "") {
                                      alert(
                                        "상호(업체명) 또는 법인명(단체명)을 입력해주세요"
                                      );
                                    } else {
                                      if (emailText === "") {
                                        alert("이메일을 입력해주세요");
                                      } else {
                                        if (
                                          !emailCheckFunction.test(emailText)
                                        ) {
                                          alert("이메일 형식을 확인해주세요");
                                        } else {
                                          if (passwordText === "") {
                                            alert("비밀번호를 입력해주세요");
                                          } else {
                                            if (passwordCheckText === "") {
                                              alert(
                                                "비밀번호 확인을 입력해주세요"
                                              );
                                            } else {
                                              if (
                                                passwordText !==
                                                passwordCheckText
                                              ) {
                                                alert(
                                                  "비밀번호가 일치하지 않습니다"
                                                );
                                              } else {
                                                if (
                                                  phoneNumberCheckStart ===
                                                  false
                                                ) {
                                                  alert(
                                                    "휴대폰 인증을 해주세요"
                                                  );
                                                } else {
                                                  if (
                                                    authNumberChecked === false
                                                  ) {
                                                    alert(
                                                      "휴대폰 인증을 해주세요"
                                                    );
                                                  } else {
                                                    const response =
                                                      await postCompanyNumberCheck(
                                                        companyNumber
                                                      );
                                                    console.log(response.data);
                                                    if (
                                                      response.status === 200
                                                    ) {
                                                      if (
                                                        response.data.data
                                                          .data[0].b_stt_cd ===
                                                        "01"
                                                      ) {
                                                        if (
                                                          userType ===
                                                          "individual_business"
                                                        ) {
                                                          const imageUploadResponse =
                                                            await uploadImage(
                                                              imgFile
                                                            );
                                                          console.log(
                                                            imageUploadResponse
                                                          );
                                                          if (
                                                            imageUploadResponse.status ===
                                                            200
                                                          ) {
                                                            setImgFile(null);
                                                            setImgFileName(
                                                              "파일 선택"
                                                            );
                                                            await totalHandleSignUp(
                                                              1,
                                                              advertiserNameText,
                                                              companyNumber,
                                                              imageUploadResponse
                                                                .data.imageUrl,
                                                              businessNameText,
                                                              emailText,
                                                              passwordText,
                                                              phoneNumberText,
                                                              true,
                                                              true,
                                                              true,
                                                              isMarketingAgreeChecked
                                                            );
                                                          }
                                                        } else if (
                                                          userType ===
                                                          "corp_business"
                                                        ) {
                                                          const imageUploadResponse =
                                                            await uploadImage(
                                                              imgFile
                                                            );
                                                          console.log(
                                                            imageUploadResponse
                                                          );

                                                          if (
                                                            imageUploadResponse.status ===
                                                            200
                                                          ) {
                                                            setImgFile(null);
                                                            setImgFileName(
                                                              "파일 선택"
                                                            );

                                                            await totalHandleSignUp(
                                                              2,
                                                              advertiserNameText,
                                                              companyNumber,
                                                              imageUploadResponse
                                                                .data.imageUrl,
                                                              businessNameText,
                                                              emailText,
                                                              passwordText,
                                                              phoneNumberText,
                                                              true,
                                                              true,
                                                              true,
                                                              isMarketingAgreeChecked
                                                            );
                                                          }
                                                        }
                                                      } else if (
                                                        response.data.data
                                                          .data[0].b_stt_cd ===
                                                        "02"
                                                      ) {
                                                        alert(
                                                          "휴업된 사업자입니다."
                                                        );
                                                      } else if (
                                                        response.data.data
                                                          .data[0].b_stt_cd ===
                                                        "03"
                                                      ) {
                                                        alert(
                                                          "폐업된 사업자입니다."
                                                        );
                                                      } else {
                                                        alert(
                                                          "사업자등록번호를 다시 확인해주세요."
                                                        );
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }}
                  >
                    {userType === "" ? "회원 유형을 선택해주세요" : "회원가입"}
                  </SignUpBtn>
                </BtnPart>
              </SignUpForm>
            </SignUpMainWrap>
          </SignUpMain>
        </Container>
      </Main>
    </SignUpWrapper>
  );
};

export default SignUpPage;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const SignUpWrapper = styled.div`
  background-color: #353535;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Main = styled.div`
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
`;

const Container = styled.div`
  width: 1000px;
  height: 90vh;
  background-color: #ffffff90;
  border-radius: 20px;
  position: relative;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const SignUpMain = styled.div`
  width: 50%;
  height: 90%;
  margin: 50px;
  box-sizing: border-box;
`;

const SignUpMainWrap = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SignUpMainWrapH2 = styled.h2`
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 1.7rem;
`;

const SignUpForm = styled.div`
  width: 85%;
  height: auto;
  box-sizing: border-box;
`;

const BtnPart = styled.div`
  border: none;
  background-color: transparent;
  margin-bottom: 0;
  display: flex;
  line-height: 45px;
  overflow: hidden;
`;

const SignUpBtn = styled.button`
  padding: 0;
  margin-right: 0;
  height: 50px;
  line-height: 55px;
  background-color: #ffe100;
  color: #353535;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  border-radius: 5px;
  flex-grow: 1;
  cursor: pointer;
  width: 100%;
`;

const JoinWrapDiv = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const JoinWrapDivLabel = styled.label`
  font-weight: bold;
  font-size: 1rem;
  min-width: 150px;
`;

const JoinWrapDivInput = styled.input`
  height: 30px;
  padding: 5px;
  border: 1px solid #9f9f9f;
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const JoinWrapDivSelect = styled.select`
  height: 30px;
  padding: 5px;
  border: 1px solid #9f9f9f;
  background-color: #ffffff;
  border-radius: 5px;
  width: calc(100% - 10px); // padding을 고려하여 width 조정

  &:focus {
    outline: none;
  }
`;

const AgreeSection = styled.div`
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  justify-content: center;
`;

const CheckBox = styled.input`
  width: auto;
  margin-top: 2px;
  padding: 0;
  background-color: transparent;
`;

const AgreeLabel = styled.label`
  font-weight: 400;
  width: 350px;
  margin-left: 5px;
  font-size: 0.8rem;
`;

const AgreeLink = styled.a`
  color: black;

  &:visited {
    color: black;
  }
`;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import styled from "styled-components";

const RefundPolicyPage = () => {
  let navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn
            onClick={() => {
              navigate(login_inner_router_url);
            }}
          >
            로그인
          </ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn
              onClick={() => {
                navigate(sign_up_inner_router_url);
              }}
            >
              시작하기
            </ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Content>
        <ContentInfo>
          <ContentInfoInner>
            <ContentInfoInnerTitle>환불정책</ContentInfoInnerTitle>
            <p>
              <br></br>1. 회원사는 회사에 환불을 요구할 수 있습니다. 환불은
              회사가 안내하는 정책 및 방법에 따라 진행됩니다.<br></br>2. 환불은
              의뢰인이 광고포인트를 충전한 후 '보유중'인 금액의 반환을 요청하는
              것을 말합니다. 별도의 환불 수수료가 발생합니다.<br></br>-
              보유포인트 100% 환불을 원칙으로 합니다. (단, 의뢰인의 과실이나
              단순 변심, 광고 매체 규제 등으로 인한 불이익은 환불이 불가하오니
              이점에 유의해 주시기 바랍니다.)<br></br>- 의뢰인의 과실이라함은
              잘못된 정보를 기입함에 따라 이미 잘못된 정보로 광고가 등록이
              되었을 때를 말합니다.<br></br>- 광고 매체 규제라함은 불법적이거나
              음란한 정보를 등록하여 회사에 해를 끼쳤을 경우를 말합니다.
              <br></br>- 환불 수수료<br></br>- 단순 변심에 의한 요청 시
              보유포인트 금액의 35% 차감 (보너스포인트 포함)<br></br>-
              보너스포인트 지급 후 사용을 일부만 하였다 할지라도 보너스포인트를
              포함한 전체 포인트는 환불이 불가합니다.<br></br>- 내부 시스템
              문제로 7일 이내에 작업진행이 안될 때 0% (충전시 지급되었던
              보너스포인트만 공제함)<br></br>- 환불은 기본적으로 동일 결제
              수단을 통해 환불됩니다.<br></br>3. 보유 포인트 유효 기간은
              365일입니다.<br></br>- 365일 동안 보유한 포인트를 한번도 사용하지
              않은 보유 포인트를 말합니다.<br></br>- 365일 이전에 보유 포인트를
              사용 시 사용 일자 기준으로 365일이 연장됩니다.
            </p>
            <br></br>
            <p>
              <strong>부칙</strong>
              <br></br>본 이용약관은 2024년 01월 16일부터 시행합니다.
            </p>
          </ContentInfoInner>
        </ContentInfo>
      </Content>
    </Wrapper>
  );
};

export default RefundPolicyPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #353535;
  font-size: 18px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  margin: 90px auto 0 auto;
  box-sizing: border-box;
`;

const ContentInfo = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0 150px 0;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

const ContentInfoInner = styled.div`
  line-height: 26px;
  padding-bottom: 50px;
  margin-top: 90px;
  letter-spacing: -1.5px;
  font-size: 15px;
  border-bottom: 1px solid #464646;
  margin-bottom: 130px;
  font-family: "Noto Sans Korean";
  font-weight: 400;
  color: #656565;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 102px;
  }
`;

const ContentInfoInnerTitle = styled.h1`
  font-size: 2.1875em;
  line-height: 49px;
  font-family: HelveticaNeueLT-Bold, "Noto Sans Korean Bold",
    HelveticaNeueLT-Medium, "Noto Sans Korean Regular", AppleSDGothic,
    "Malgun Gothic", "Gulim", sans-serif;
  font-weight: normal;
  margin: 0.67em 0;
  box-sizing: border-box;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

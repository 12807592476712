import React from "react";
import { useNavigate } from "react-router-dom";
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import styled from "styled-components";

const MarketingPolicyPage = () => {
  let navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn
            onClick={() => {
              navigate(login_inner_router_url);
            }}
          >
            로그인
          </ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn
              onClick={() => {
                navigate(sign_up_inner_router_url);
              }}
            >
              시작하기
            </ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Content>
        <ContentInfo>
          <ContentInfoInner>
            <ContentInfoInnerTitle>마케팅 수신동의 약관</ContentInfoInnerTitle>
            <p>
              본 약관은 (주)핑거네이션에서 운영하는 머니모아(이하 "회사"라 함)를
              통해 제공되는 마케팅 및 프로모션 활동에 관한 것입니다.
            </p>
            <br></br>
            <p>
              <strong>제1조 (수신 동의의 의미)</strong>
              <br></br>1. 회원은 본 동의서에 서명함으로써 회사로부터 마케팅 및
              프로모션 관련 정보를 전달받는 데 동의합니다.
              <br></br>2. 이 동의는 이메일, SMS, SNS, 모바일 푸시 알림 등 다양한
              수단을 통해 전달될 수 있음을 이해합니다.
            </p>
            <br></br>
            <p>
              <strong>제2조 (정보 수집 및 사용)</strong>
              <br></br>1. 회사는 회원의 개인정보를 수집하고 이를 마케팅 및
              프로모션 목적으로 사용할 수 있습니다.<br></br>2. 회사는 회원의
              개인정보를 제3자와 공유하지 않으며, 오직 회사의 마케팅 및 프로모션
              목적으로만 사용될 것입니다.
            </p>
            <br></br>
            <p>
              <strong>제3조 (동의 철회)</strong>
              <br></br>1. 회원은 언제든지 본 동의를 철회할 수 있으며, 이를
              위해서는 회사에 서면 또는 전자적으로 요청해야 합니다.<br></br>2.
              동의 철회 후에는 추가적인 마케팅 및 프로모션 메시지를 받지 않을
              것임을 이해합니다.
            </p>
            <br></br>
            <p>
              <strong>제4조 (변경 및 업데이트)</strong>
              <br></br>1. 회사는 본 약관을 수정하거나 업데이트할 권리를
              갖습니다.
              <br></br>2. 변경된 약관은 회사 웹사이트나 앱 내 공지를 통해 효력을
              발생합니다.
            </p>
            <br></br>
            <p>
              <strong>제5조 (유의 사항)</strong>
              <br></br>1. 회원은 마케팅 및 프로모션 메시지를 받음으로써 발생하는
              어떠한 손실이나 불편함에 대해서도 회사가 책임지지 않음을
              인지합니다.
            </p>
            <br></br>
            <p>
              위의 약관은 회원의 마케팅 수신에 관한 동의를 명확하게 규정합니다.
              회원은 이를 숙지하고 서명함으로써 회사의 마케팅 및 프로모션
              메시지를 받는 데 동의한 것으로 간주됩니다.
            </p>
            <br></br>
            <p>
              <strong>부칙</strong>
              <br></br>본 개인정보처리방침은 2024년 01월 16일부터 시행합니다.
            </p>
          </ContentInfoInner>
        </ContentInfo>
      </Content>
    </Wrapper>
  );
};

export default MarketingPolicyPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #353535;
  font-size: 18px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  margin: 90px auto 0 auto;
  box-sizing: border-box;
`;

const ContentInfo = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0 150px 0;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

const ContentInfoInner = styled.div`
  line-height: 26px;
  padding-bottom: 50px;
  margin-top: 90px;
  letter-spacing: -1.5px;
  font-size: 15px;
  border-bottom: 1px solid #464646;
  margin-bottom: 130px;
  font-family: "Noto Sans Korean";
  font-weight: 400;
  color: #656565;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 102px;
  }
`;

const ContentInfoInnerTitle = styled.h1`
  font-size: 2.1875em;
  line-height: 49px;
  font-family: HelveticaNeueLT-Bold, "Noto Sans Korean Bold",
    HelveticaNeueLT-Medium, "Noto Sans Korean Regular", AppleSDGothic,
    "Malgun Gothic", "Gulim", sans-serif;
  font-weight: normal;
  margin: 0.67em 0;
  box-sizing: border-box;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

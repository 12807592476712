import styled from "styled-components";

export const SelectOptionDropdown = styled.select`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

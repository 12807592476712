import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentHeader } from "../../../design_system/components/content_header/ContentHeader";
import { TabSelectSectionBtn } from "../../../design_system/components/tab_select_section_btn/TabSelectSectionBtn";
import KakaoPresentWishPostTab from "./kakao_present_wish_post_tab/KakaoPresentWishPostTab";
import KakaoPresentReviewLikePostTab from "./kakao_present_review_like_post_tab/KakaoPresentReviewLikePostTab";
import KakaoMapSavePostTab from "./kakao_map_save_post_tab/KakaoMapSavePostTab";
import { useAdministratorAdvertisementProduct } from "../../../data_system/context/administrator_advertisement_product_context/AdministratorAdvertisementProductContext";

const KakaoProductPostPage = () => {
  const {
    administratorAdvertisementProducts,
    refetchAdministratorAdvertisementProduct,
  } = useAdministratorAdvertisementProduct();

  const [
    productSelectSectionBtnSelectedId,
    setProductSelectSectionBtnSelectedId,
  ] = useState(null);

  // 버튼 클릭 이벤트 핸들러
  const productSelectSectionBtnClick = (id) => {
    // 이미 선택된 버튼을 클릭하면 선택 취소
    if (productSelectSectionBtnSelectedId === id) {
      setProductSelectSectionBtnSelectedId(null);
    } else {
      setProductSelectSectionBtnSelectedId(id); // 새로운 버튼 선택
    }
  };

  const productSelectSectionBtnData = [
    {
      id: 0,
      title: "카카오 선물 위시",
    },
    {
      id: 1,
      title: "카카오 선물 후기 좋아요",
    },
    {
      id: 2,
      title: "카카오 맵 저장",
    },
  ];

  useEffect(() => {
    async function getData() {
      await refetchAdministratorAdvertisementProduct();
    }

    getData();
  }, []);

  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>카카오 상품등록</HeaderTitleH2>
      </ContentHeader>
      <MainBody>
        <Frame>
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <ProductPostDivTitle>상품유형 선택</ProductPostDivTitle>
            <ProductPostDivFrame>
              <ProductSelectSectionBtnList>
                {productSelectSectionBtnData.map((data) => {
                  return (
                    <TabSelectSectionBtn
                      key={data.id}
                      isselected={
                        productSelectSectionBtnSelectedId === data.id
                          ? "true"
                          : "false"
                      }
                      onClick={() => productSelectSectionBtnClick(data.id)}
                    >
                      {data.title}
                    </TabSelectSectionBtn>
                  );
                })}
              </ProductSelectSectionBtnList>
            </ProductPostDivFrame>
          </div>
          {productSelectSectionBtnSelectedId === 0 && (
            <KakaoPresentWishPostTab></KakaoPresentWishPostTab>
          )}
          {productSelectSectionBtnSelectedId === 1 && (
            <KakaoPresentReviewLikePostTab></KakaoPresentReviewLikePostTab>
          )}
          {productSelectSectionBtnSelectedId === 2 && (
            <KakaoMapSavePostTab></KakaoMapSavePostTab>
          )}
        </Frame>
      </MainBody>
    </Section>
  );
};

export default KakaoProductPostPage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 93px);
`;

const Frame = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 20px;
`;

const ProductPostDivFrame = styled.div`
  flex-wrap: wrap;
  display: flex;
  line-height: 40px;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ProductPostDivTitle = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

const ProductSelectSectionBtnList = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  box-sizing: border-box;
  height: 46px;
  gap: 0.5rem;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentHeader } from "../../../design_system/components/content_header/ContentHeader";
import { TabSelectSectionBtn } from "../../../design_system/components/tab_select_section_btn/TabSelectSectionBtn";
import NPlaceTrafficPostTab from "./n_place_traffic_post_tab/NPlaceTrafficPostTab";
import NPlaceSavePostTab from "./n_place_save_post_tab/NPlaceSavePostTab";
import NPlaceAlarmPostTab from "./n_place_alarm_post_tab/NPlaceAlarmPostTab";
import NPlaceMapJjimPostTab from "./n_place_map_jjim_post_tab/NPlaceMapJjimPostTab";
import NPlaceBlogSharePostTab from "./n_place_blog_share_post_tab/NPlaceBlogSharePostTab";
import NPlaceKeepSharePostTab from "./n_place_keep_share_post_tab/NPlaceKeepSharePostTab";
import NPlaceBlogReviewPostTab from "./n_place_blog_review_post_tab/NPlaceBlogReviewPostTab";
import { useAdministratorAdvertisementProduct } from "../../../data_system/context/administrator_advertisement_product_context/AdministratorAdvertisementProductContext";

const NPlaceProductPostPage = () => {
  const {
    administratorAdvertisementProducts,
    refetchAdministratorAdvertisementProduct,
  } = useAdministratorAdvertisementProduct();

  const [
    productSelectSectionBtnSelectedId,
    setProductSelectSectionBtnSelectedId,
  ] = useState(null);

  // 버튼 클릭 이벤트 핸들러
  const productSelectSectionBtnClick = (id) => {
    // 이미 선택된 버튼을 클릭하면 선택 취소
    if (productSelectSectionBtnSelectedId === id) {
      setProductSelectSectionBtnSelectedId(null);
    } else {
      setProductSelectSectionBtnSelectedId(id); // 새로운 버튼 선택
    }
  };

  const productSelectSectionBtnData = [
    {
      id: 0,
      title: "N플레이스 트래픽",
    },
    {
      id: 1,
      title: "N플레이스 저장",
    },
    {
      id: 2,
      title: "N플레이스 알림",
    },
    {
      id: 3,
      title: "N플레이스 지도 찜",
    },
    {
      id: 4,
      title: "N플레이스 블로그 공유",
    },
    {
      id: 5,
      title: "N플레이스 Keep 공유",
    },
    // {
    //   id: 6,
    //   title: "N플레이스 영수증 리뷰",
    // },
    {
      id: 6,
      title: "N플레이스 블로그 리뷰",
    },
  ];

  useEffect(() => {
    async function getData() {
      await refetchAdministratorAdvertisementProduct();
    }

    getData();
  }, []);

  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>N플레이스 상품등록</HeaderTitleH2>
      </ContentHeader>
      <MainBody>
        <Frame>
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <ProductPostDivTitle>상품유형 선택</ProductPostDivTitle>
            <ProductPostDivFrame>
              <ProductSelectSectionBtnList>
                {productSelectSectionBtnData.map((data) => {
                  return (
                    <TabSelectSectionBtn
                      key={data.id}
                      isselected={
                        productSelectSectionBtnSelectedId === data.id
                          ? "true"
                          : "false"
                      }
                      onClick={() => productSelectSectionBtnClick(data.id)}
                    >
                      {data.title}
                    </TabSelectSectionBtn>
                  );
                })}
              </ProductSelectSectionBtnList>
            </ProductPostDivFrame>
          </div>
          {productSelectSectionBtnSelectedId === 0 && (
            <NPlaceTrafficPostTab></NPlaceTrafficPostTab>
          )}
          {productSelectSectionBtnSelectedId === 1 && (
            <NPlaceSavePostTab></NPlaceSavePostTab>
          )}
          {productSelectSectionBtnSelectedId === 2 && (
            <NPlaceAlarmPostTab></NPlaceAlarmPostTab>
          )}
          {productSelectSectionBtnSelectedId === 3 && (
            <NPlaceMapJjimPostTab></NPlaceMapJjimPostTab>
          )}
          {productSelectSectionBtnSelectedId === 4 && (
            <NPlaceBlogSharePostTab></NPlaceBlogSharePostTab>
          )}
          {productSelectSectionBtnSelectedId === 5 && (
            <NPlaceKeepSharePostTab></NPlaceKeepSharePostTab>
          )}
          {/* {productSelectSectionBtnSelectedId === 6 && (
            <>
              <>
                <ProductPostDivTitle>그룹 *</ProductPostDivTitle>
                <ProductPostDivFrame>
                  <InputFrame>
                    <ProductPostDropdown>
                      <option>그룹을 선택해주세요</option>
                      <option>도미누스</option>
                    </ProductPostDropdown>
                  </InputFrame>
                </ProductPostDivFrame>
              </>
              <>
                <ProductPostDivTitle>플레이스명 *</ProductPostDivTitle>
                <ProductPostDivFrame>
                  <InputFrame>
                    <ProductPostInput placeholder="플레이스명을 입력해주세요."></ProductPostInput>
                  </InputFrame>
                </ProductPostDivFrame>
              </>
              <>
                <ProductPostDivTitle>플레이스 URL *</ProductPostDivTitle>
                <ProductPostDivFrame>
                  <InputFrame>
                    <ProductPostInput placeholder="모바일 주소(플레이스 URL)"></ProductPostInput>
                  </InputFrame>
                </ProductPostDivFrame>
              </>
              <>
                <ProductPostDivTitle>영수증 업로드 *</ProductPostDivTitle>
                <ProductPostDivFrame>
                  <InputFrame as="label">
                    {fileName !== "" ? `파일 이름: ${fileName}` : "업로드"}
                    <UploadInput type="file" onChange={handleFileChange} />
                  </InputFrame>
                </ProductPostDivFrame>
              </>
              <>
                <ProductPostDivTitle>일일 목표 작업량 *</ProductPostDivTitle>
                <ProductPostDivFrame>
                  <InputFrame>
                    <ProductPostInput placeholder="0 이상으로 입력(최소 1건 이상)"></ProductPostInput>
                  </InputFrame>
                </ProductPostDivFrame>
              </>
              <>
                <ProductPostDivTitle>광고 단가</ProductPostDivTitle>
                <ProductPostDivFrame>
                  <InputFrame>
                    <ProductPostInput
                      value={`${60}원`}
                      disabled
                    ></ProductPostInput>
                  </InputFrame>
                </ProductPostDivFrame>
              </>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <BigRoundBrownBtn>광고 등록하기</BigRoundBrownBtn>
              </div>
              <TipTextBox>
                <TipTextTitle>TIP.</TipTextTitle>
                <TipTextBody>
                  <TipText>
                    상품유형 선택: 진행할 상품을 선택 후 등록해주시면 됩니다.
                  </TipText>
                  <TipText>
                    플레이스 URL: 플레이스 URL입니다. 모바일 주소여야 합니다.
                  </TipText>
                  <TipText>
                    일일 목표 작업량: 하루에 몇 건의 작업량을 목표로 하는지의
                    수치입니다. 0 이상으로 입력해주세요(최소 1건 이상). 일일
                    목표 작업량은 하루에 얼마나 광고를 집행할지 결정하는
                    수치입니다.
                  </TipText>
                  <TipText>진행순서: 광고 등록하기 &#62; 진행</TipText>
                  <TipText>
                    주의 및 참고사항:<br></br>1. 일일 목표 작업량은 서버 점검
                    시간 이외에 언제든지 변경할 수 있습니다.<br></br>2. 당일
                    저녁 11시 전에 다음 날 작업을 진행하지 않도록 광고 멈춤
                    설정을 할 수 있습니다.
                    <br></br>
                    3. 당일 등록한 광고는 당일 저녁 11시에 시작하는 서버 점검
                    기간부터 작업합니다.
                    <br></br>4. 매일 저녁 11시부터 다음 날 새벽 1시까지는 서버
                    점검으로 광고 등록 및 관리가 불가능합니다.<br></br>
                    5. 저녁 11시부터 다음 날 새벽 1시까지 서버 점검 동안 다음 날
                    광고를 서버에서 유저에게 전달합니다. 즉, 이미 저녁 11시부터
                    광고가 전달되었으므로 저녁 11시부터 환불이 불가능합니다.
                    <br></br>6. 일일 목표 작업량을 당일 전부 수행하지 못 하면
                    당일 저녁 11시부터 새벽 1시까지 점검 기간 동안 수행하지 못
                    한 작업량은 포인트로 환불됩니다.<br></br>7. 잘못된 정보
                    등록으로 등록 광고의 평균 작업 속도가 해당 광고의 평균 작업
                    속도 보다 현저하게 느리다면 자동으로 광고가 중단됩니다.
                    <br></br>8. 잘못된 정보로 등록하여 작업된 광고는 환불이
                    불가능합니다.
                  </TipText>
                </TipTextBody>
              </TipTextBox>
              <div
                style={{
                  height: 20,
                }}
              ></div>
            </>
          )} */}
          {productSelectSectionBtnSelectedId === 6 && (
            <NPlaceBlogReviewPostTab></NPlaceBlogReviewPostTab>
          )}
        </Frame>
      </MainBody>
    </Section>
  );
};

export default NPlaceProductPostPage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 93px);
`;

const Frame = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 20px;
`;

const ProductPostDivFrame = styled.div`
  flex-wrap: wrap;
  display: flex;
  line-height: 40px;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ProductPostDivTitle = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

const ProductSelectSectionBtnList = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  box-sizing: border-box;
  height: 46px;
  gap: 0.5rem;
`;

const InputFrame = styled.div`
  background-color: #fff;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 0px 10px;
  line-height: 40px;
`;

const ProductPostInput = styled.input`
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    outline: none;
    cursor: not-allowed;
  }
`;

const ProductPostDropdown = styled.select`
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const TipText = styled.div`
  padding: 0;
  margin: 0;
  line-height: 130%;
  display: block;
  margin-top: 0.5rem;
`;

const UploadInput = styled.input`
  display: none;
`;

import React, { createContext, useContext, useState, useEffect } from "react";
import { getAdvertiserAccount } from "../../../communication_system/axios_apis/axios_apis";
import { useAuth } from "../auth_context/AuthContext";

const AdvertiserAccountContext = createContext();

export const useAdvertiserAccount = () => {
  return useContext(AdvertiserAccountContext);
};

export const AdvertiserAccountProvider = ({ children }) => {
  const [advertiserAccount, setAdvertiserAccount] = useState(null);
  const { isLoggedIn } = useAuth();

  const fetchAdvertiserAccount = async () => {
    try {
      const response = await getAdvertiserAccount();
      console.log("response:", response);
      setAdvertiserAccount(response.data.advertiser);
    } catch (error) {
      console.error("Error while fetching advertiser account:", error);
    }
  };

  const refetchAdvertiserAccount = () => {
    if (isLoggedIn) {
      fetchAdvertiserAccount();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchAdvertiserAccount();
    }
  }, [isLoggedIn]);

  return (
    <AdvertiserAccountContext.Provider
      value={{
        advertiserAccount,
        setAdvertiserAccount,
        refetchAdvertiserAccount,
      }}
    >
      {children}
    </AdvertiserAccountContext.Provider>
  );
};

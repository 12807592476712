import {
  announcement_inner_router_url,
  baemin_jjim_inner_router_url,
  baemin_one_jjim_inner_router_url,
  baemin_product_characteristic_inner_router_url,
  baemin_product_post_inner_router_url,
  baemin_total_search_inner_router_url,
  google_map_review_inner_router_url,
  google_map_save_inner_router_url,
  google_product_characteristic_inner_router_url,
  google_product_post_inner_router_url,
  google_total_search_inner_router_url,
  insta_follow_inner_router_url,
  insta_like_inner_router_url,
  insta_product_characteristic_inner_router_url,
  insta_product_post_inner_router_url,
  insta_save_inner_router_url,
  insta_total_search_inner_router_url,
  kakao_map_save_inner_router_url,
  kakao_present_review_like_inner_router_url,
  kakao_present_wish_inner_router_url,
  kakao_product_characteristic_inner_router_url,
  kakao_product_post_inner_router_url,
  kakao_total_search_inner_router_url,
  n_place_alarm_inner_router_url,
  n_place_blog_review_inner_router_url,
  n_place_blog_share_inner_router_url,
  n_place_keep_share_inner_router_url,
  n_place_map_jjim_inner_router_url,
  n_place_product_characteristic_inner_router_url,
  n_place_product_post_inner_router_url,
  n_place_save_inner_router_url,
  n_place_total_search_inner_router_url,
  n_place_traffic_inner_router_url,
  n_store_alarm_inner_router_url,
  n_store_product_characteristic_inner_router_url,
  n_store_product_jjim_inner_router_url,
  n_store_product_post_inner_router_url,
  n_store_total_search_inner_router_url,
  n_store_traffic_price_comparison_inner_router_url,
  n_store_traffic_single_inner_router_url,
  youtube_like_inner_router_url,
  youtube_product_characteristic_inner_router_url,
  youtube_product_post_inner_router_url,
  youtube_subscribe_inner_router_url,
  youtube_total_search_inner_router_url,
} from "../../../communication_system/inner_router_url/inner_router_url";
import {
  FaCaretRight,
  FaCheck,
  FaCircleInfo,
  FaPlus,
  FaStore,
  FaUsersCog,
} from "../../../design_system/icons/icons";
import AnnouncementPage from "../../../pages/announcement_page/AnnouncementPage";
import { generateUuid } from "../../data_function/generate_uuid";
import NStoreTrafficSinglePage from "../../../pages/n_store/n_store_traffic_single_page/NStoreTrafficSinglePage";
import NStoreTrafficPriceComparisonPage from "../../../pages/n_store/n_store_traffic_price_comparison_page/NStoreTrafficPriceComparisonPage";
import NStoreProductCharacteristicPage from "../../../pages/n_store/n_store_product_characteristic_page/NStoreProductCharacteristicPage";
import NStoreProductPostPage from "../../../pages/n_store/n_store_product_post_page/NStoreProductPostPage";
import NStoreProductJjimPage from "../../../pages/n_store/n_store_product_jjim_page/NStoreProductJjimPage";
import NStoreAlarmPage from "../../../pages/n_store/n_store_alarm_page/NStoreAlarmPage";
import NPlaceProductPostPage from "../../../pages/n_place/n_place_product_post_page/NPlaceProductPostPage";
import NPlaceTrafficPage from "../../../pages/n_place/n_place_traffic_page/NPlaceTrafficPage";
import NPlaceSavePage from "../../../pages/n_place/n_place_save_page/NPlaceSavePage";
import NPlaceAlarmPage from "../../../pages/n_place/n_place_alarm_page/NPlaceAlarmPage";
import NPlaceMapJjimPage from "../../../pages/n_place/n_place_map_jjim_page/NPlaceMapJjimPage";
import NPlaceProductCharacteristicPage from "../../../pages/n_place/n_place_product_characteristic_page/NPlaceProductCharacteristicPage";
import NPlaceBlogSharePage from "../../../pages/n_place/n_place_blog_share_page/NPlaceBlogSharePage";
import NPlaceKeepSharePage from "../../../pages/n_place/n_place_keep_share_page/NPlaceKeepSharePage";
import KakaoProductCharacteristicPage from "../../../pages/kakao/kakao_product_characteristic_page/KakaoProductCharacteristicPage";
import KakaoProductPostPage from "../../../pages/kakao/kakao_product_post_page/KakaoProductPostPage";
import KakaoPresentWishPage from "../../../pages/kakao/kakao_present_wish_page/KakaoPresentWishPage";
import KakaoPresentReviewLikePage from "../../../pages/kakao/kakao_present_review_like_page/KakaoPresentReviewLikePage";
import KakaoMapSavePage from "../../../pages/kakao/kakao_map_save_page/KakaoMapSavePage";
import InstaProductCharacteristicPage from "../../../pages/insta/insta_product_characteristic_page/InstaProductCharacteristicPage";
import InstaProductPostPage from "../../../pages/insta/insta_product_post_page/InstaProductPostPage";
import InstaFollowPage from "../../../pages/insta/insta_follow_page/InstaFollowPage";
import InstaLikePage from "../../../pages/insta/insta_like_page/InstaLikePage";
import InstaSavePage from "../../../pages/insta/insta_save_page/InstaSavePage";
import YoutubeProductCharacteristicPage from "../../../pages/youtube/youtube_product_characteristic_page/YoutubeProductCharacteristicPage";
import YoutubeProductPostPage from "../../../pages/youtube/youtube_product_post_page/YoutubeProductPostPage";
import YoutubeSubscribePage from "../../../pages/youtube/youtube_subscribe_page/YoutubeSubscribePage";
import YoutubeLikePage from "../../../pages/youtube/youtube_like_page/YoutubeLikePage";
import NPlaceBlogReviewPage from "../../../pages/n_place/n_place_blog_review_page/NPlaceBlogReviewPage";
import NPlaceTotalSearchPage from "../../../pages/n_place/n_place_total_search_page/NPlaceTotalSearchPage";
import NStoreTotalSearchPage from "../../../pages/n_store/n_store_total_search_page/NStoreTotalSearchPage";
import KakaoTotalSearchPage from "../../../pages/kakao/kakao_total_search_page/KakaoTotalSearchPage";
import InstaTotalSearchPage from "../../../pages/insta/insta_total_search_page/InstaTotalSearchPage";
import YoutubeTotalSearchPage from "../../../pages/youtube/youtube_total_search_page/YoutubeTotalSearchPage";
import GoogleProductCharacteristicPage from "../../../pages/google/google_product_characteristic_page/GoogleProductCharacteristicPage";
import GoogleProductPostPage from "../../../pages/google/google_product_post_page/GoogleProductPostPage";
import GoogleTotalSearchPage from "../../../pages/google/google_total_search_page/GoogleTotalSearchPage";
import GoogleMapSavePage from "../../../pages/google/google_map_save_page/GoogleMapSavePage";
import GoogleMapReviewPage from "../../../pages/google/google_map_review_page/GoogleMapReviewPage";
import BaeminProductCharacteristicPage from "../../../pages/baemin/baemin_product_characteristic_page/BaeminProductCharacteristicPage";
import BaeminProductPostPage from "../../../pages/baemin/baemin_product_post_page/BaeminProductPostPage";
import BaeminTotalSearchPage from "../../../pages/baemin/baemin_total_search_page/BaeminTotalSearchPage";
import BaeminJjimPage from "../../../pages/baemin/baemin_jjim_page/BaeminJjimPage";
import BaeminOneJjimPage from "../../../pages/baemin/baemin_one_jjim_page/BaeminOneJjimPage";

export const sidebar_data = [
  {
    mainId: generateUuid(),
    mainTitle: "N스토어",
    mainIcon: <FaStore></FaStore>,
    is_active: 0,
    router_url: null,
    components: null,
    subTitleList: [
      {
        subId: generateUuid(),
        name: "N스토어 상품 특징",
        subIcon: <FaCheck></FaCheck>,
        is_active: 0,
        router_url: n_store_product_characteristic_inner_router_url,
        components: (
          <NStoreProductCharacteristicPage></NStoreProductCharacteristicPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "N스토어 상품등록",
        subIcon: <FaPlus></FaPlus>,
        is_active: 0,
        router_url: n_store_product_post_inner_router_url,
        components: <NStoreProductPostPage></NStoreProductPostPage>,
      },
      {
        subId: generateUuid(),
        name: "N스토어 전체 검색",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_store_total_search_inner_router_url,
        components: <NStoreTotalSearchPage></NStoreTotalSearchPage>,
      },
      {
        subId: generateUuid(),
        name: "N스토어 트래픽 - 단독",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_store_traffic_single_inner_router_url,
        components: <NStoreTrafficSinglePage></NStoreTrafficSinglePage>,
      },
      {
        subId: generateUuid(),
        name: "N스토어 트래픽 - 가격비교",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_store_traffic_price_comparison_inner_router_url,
        components: (
          <NStoreTrafficPriceComparisonPage></NStoreTrafficPriceComparisonPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "N스토어 알림",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_store_alarm_inner_router_url,
        components: <NStoreAlarmPage></NStoreAlarmPage>,
      },
      {
        subId: generateUuid(),
        name: "N스토어 상품 찜",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_store_product_jjim_inner_router_url,
        components: <NStoreProductJjimPage></NStoreProductJjimPage>,
      },
    ],
  },
  {
    mainId: generateUuid(),
    mainTitle: "N플레이스",
    mainIcon: <FaStore></FaStore>,
    is_active: 0,
    router_url: null,
    components: null,
    subTitleList: [
      {
        subId: generateUuid(),
        name: "N플레이스 상품 특징",
        subIcon: <FaCheck></FaCheck>,
        is_active: 0,
        router_url: n_place_product_characteristic_inner_router_url,
        components: (
          <NPlaceProductCharacteristicPage></NPlaceProductCharacteristicPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "N플레이스 상품등록",
        subIcon: <FaPlus></FaPlus>,
        is_active: 0,
        router_url: n_place_product_post_inner_router_url,
        components: <NPlaceProductPostPage></NPlaceProductPostPage>,
      },
      {
        subId: generateUuid(),
        name: "N플레이스 전체 검색",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_total_search_inner_router_url,
        components: <NPlaceTotalSearchPage></NPlaceTotalSearchPage>,
      },
      {
        subId: generateUuid(),
        name: "N플레이스 트래픽",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_traffic_inner_router_url,
        components: <NPlaceTrafficPage></NPlaceTrafficPage>,
      },
      {
        subId: generateUuid(),
        name: "N플레이스 저장",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_save_inner_router_url,
        components: <NPlaceSavePage></NPlaceSavePage>,
      },
      {
        subId: generateUuid(),
        name: "N플레이스 알림",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_alarm_inner_router_url,
        components: <NPlaceAlarmPage></NPlaceAlarmPage>,
      },
      {
        subId: generateUuid(),
        name: "N플레이스 지도 찜",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_map_jjim_inner_router_url,
        components: <NPlaceMapJjimPage></NPlaceMapJjimPage>,
      },
      {
        subId: generateUuid(),
        name: "N플레이스 블로그 공유",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_blog_share_inner_router_url,
        components: <NPlaceBlogSharePage></NPlaceBlogSharePage>,
      },
      {
        subId: generateUuid(),
        name: "N플레이스 Keep 공유",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_keep_share_inner_router_url,
        components: <NPlaceKeepSharePage></NPlaceKeepSharePage>,
      },
      // {
      //   subId: generateUuid(),
      //   name: "N플레이스 영수증 리뷰",
      //   subIcon: <FaCaretRight></FaCaretRight>,
      //   is_active: 0,
      //   router_url: n_place_receipt_review_inner_router_url,
      //   components: <NPlaceReceiptReviewPage></NPlaceReceiptReviewPage>,
      // },
      {
        subId: generateUuid(),
        name: "N플레이스 블로그 리뷰",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: n_place_blog_review_inner_router_url,
        components: <NPlaceBlogReviewPage></NPlaceBlogReviewPage>,
      },
    ],
  },
  {
    mainId: generateUuid(),
    mainTitle: "카카오",
    mainIcon: <FaStore></FaStore>,
    is_active: 0,
    router_url: null,
    components: null,
    subTitleList: [
      {
        subId: generateUuid(),
        name: "카카오 상품 특징",
        subIcon: <FaCheck></FaCheck>,
        is_active: 0,
        router_url: kakao_product_characteristic_inner_router_url,
        components: (
          <KakaoProductCharacteristicPage></KakaoProductCharacteristicPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "카카오 상품등록",
        subIcon: <FaPlus></FaPlus>,
        is_active: 0,
        router_url: kakao_product_post_inner_router_url,
        components: <KakaoProductPostPage></KakaoProductPostPage>,
      },
      {
        subId: generateUuid(),
        name: "카카오 전체 검색",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: kakao_total_search_inner_router_url,
        components: <KakaoTotalSearchPage></KakaoTotalSearchPage>,
      },
      {
        subId: generateUuid(),
        name: "카카오 선물 위시",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: kakao_present_wish_inner_router_url,
        components: <KakaoPresentWishPage></KakaoPresentWishPage>,
      },
      {
        subId: generateUuid(),
        name: "카카오 선물 후기 좋아요",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: kakao_present_review_like_inner_router_url,
        components: <KakaoPresentReviewLikePage></KakaoPresentReviewLikePage>,
      },
      {
        subId: generateUuid(),
        name: "카카오 맵 저장",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: kakao_map_save_inner_router_url,
        components: <KakaoMapSavePage></KakaoMapSavePage>,
      },
    ],
  },
  {
    mainId: generateUuid(),
    mainTitle: "구글",
    mainIcon: <FaStore></FaStore>,
    is_active: 0,
    router_url: null,
    components: null,
    subTitleList: [
      {
        subId: generateUuid(),
        name: "구글 상품 특징",
        subIcon: <FaCheck></FaCheck>,
        is_active: 0,
        router_url: google_product_characteristic_inner_router_url,
        components: (
          <GoogleProductCharacteristicPage></GoogleProductCharacteristicPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "구글 상품등록",
        subIcon: <FaPlus></FaPlus>,
        is_active: 0,
        router_url: google_product_post_inner_router_url,
        components: <GoogleProductPostPage></GoogleProductPostPage>,
      },
      {
        subId: generateUuid(),
        name: "구글 전체 검색",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: google_total_search_inner_router_url,
        components: <GoogleTotalSearchPage></GoogleTotalSearchPage>,
      },
      {
        subId: generateUuid(),
        name: "구글 맵 저장",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: google_map_save_inner_router_url,
        components: <GoogleMapSavePage></GoogleMapSavePage>,
      },
      {
        subId: generateUuid(),
        name: "구글 맵 리뷰",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: google_map_review_inner_router_url,
        components: <GoogleMapReviewPage></GoogleMapReviewPage>,
      },
    ],
  },
  {
    mainId: generateUuid(),
    mainTitle: "인스타",
    mainIcon: <FaStore></FaStore>,
    is_active: 0,
    router_url: null,
    components: null,
    subTitleList: [
      {
        subId: generateUuid(),
        name: "인스타 상품 특징",
        subIcon: <FaCheck></FaCheck>,
        is_active: 0,
        router_url: insta_product_characteristic_inner_router_url,
        components: (
          <InstaProductCharacteristicPage></InstaProductCharacteristicPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "인스타 상품등록",
        subIcon: <FaPlus></FaPlus>,
        is_active: 0,
        router_url: insta_product_post_inner_router_url,
        components: <InstaProductPostPage></InstaProductPostPage>,
      },
      {
        subId: generateUuid(),
        name: "인스타 전체 검색",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: insta_total_search_inner_router_url,
        components: <InstaTotalSearchPage></InstaTotalSearchPage>,
      },
      {
        subId: generateUuid(),
        name: "인스타 팔로우",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: insta_follow_inner_router_url,
        components: <InstaFollowPage></InstaFollowPage>,
      },
      {
        subId: generateUuid(),
        name: "인스타 좋아요",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: insta_like_inner_router_url,
        components: <InstaLikePage></InstaLikePage>,
      },
      {
        subId: generateUuid(),
        name: "인스타 저장",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: insta_save_inner_router_url,
        components: <InstaSavePage></InstaSavePage>,
      },
    ],
  },
  {
    mainId: generateUuid(),
    mainTitle: "유튜브",
    mainIcon: <FaStore></FaStore>,
    is_active: 0,
    router_url: null,
    components: null,
    subTitleList: [
      {
        subId: generateUuid(),
        name: "유튜브 상품 특징",
        subIcon: <FaCheck></FaCheck>,
        is_active: 0,
        router_url: youtube_product_characteristic_inner_router_url,
        components: (
          <YoutubeProductCharacteristicPage></YoutubeProductCharacteristicPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "유튜브 상품등록",
        subIcon: <FaPlus></FaPlus>,
        is_active: 0,
        router_url: youtube_product_post_inner_router_url,
        components: <YoutubeProductPostPage></YoutubeProductPostPage>,
      },
      {
        subId: generateUuid(),
        name: "유튜브 전체 검색",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: youtube_total_search_inner_router_url,
        components: <YoutubeTotalSearchPage></YoutubeTotalSearchPage>,
      },
      {
        subId: generateUuid(),
        name: "유튜브 구독",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: youtube_subscribe_inner_router_url,
        components: <YoutubeSubscribePage></YoutubeSubscribePage>,
      },
      {
        subId: generateUuid(),
        name: "유튜브 좋아요",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: youtube_like_inner_router_url,
        components: <YoutubeLikePage></YoutubeLikePage>,
      },
    ],
  },
  {
    mainId: generateUuid(),
    mainTitle: "배민",
    mainIcon: <FaStore></FaStore>,
    is_active: 0,
    router_url: null,
    components: null,
    subTitleList: [
      {
        subId: generateUuid(),
        name: "배민 상품 특징",
        subIcon: <FaCheck></FaCheck>,
        is_active: 0,
        router_url: baemin_product_characteristic_inner_router_url,
        components: (
          <BaeminProductCharacteristicPage></BaeminProductCharacteristicPage>
        ),
      },
      {
        subId: generateUuid(),
        name: "배민 상품등록",
        subIcon: <FaPlus></FaPlus>,
        is_active: 0,
        router_url: baemin_product_post_inner_router_url,
        components: <BaeminProductPostPage></BaeminProductPostPage>,
      },
      {
        subId: generateUuid(),
        name: "배민 전체 검색",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: baemin_total_search_inner_router_url,
        components: <BaeminTotalSearchPage></BaeminTotalSearchPage>,
      },
      {
        subId: generateUuid(),
        name: "배민 찜",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: baemin_jjim_inner_router_url,
        components: <BaeminJjimPage></BaeminJjimPage>,
      },
      {
        subId: generateUuid(),
        name: "배민 클럽[원] 찜",
        subIcon: <FaCaretRight></FaCaretRight>,
        is_active: 0,
        router_url: baemin_one_jjim_inner_router_url,
        components: <BaeminOneJjimPage></BaeminOneJjimPage>,
      },
    ],
  },
  {
    mainId: generateUuid(),
    mainTitle: "공지사항",
    mainIcon: <FaCircleInfo></FaCircleInfo>,
    is_active: 0,
    router_url: announcement_inner_router_url,
    components: <AnnouncementPage></AnnouncementPage>,
    subTitleList: [],
  },
];

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "../../../design_system/components/pagination/Pagination";
import { numberFormatFunction } from "../../../data_system/data_function/number_format_function";
import { dateFormatFunction } from "../../../data_system/data_function/date_format_function";
import Tooltip from "../../../design_system/components/tooltip/Tooltip";
import { ContentHeader } from "../../../design_system/components/content_header/ContentHeader";
import { SearchBodyHeader } from "../../../design_system/components/search_body_header/SearchBodyHeader";
import { SearchWrapInputFrame } from "../../../design_system/components/search_wrap_input_frame/SearchWrapInputFrame";
import { SearchWrapDropdownFrame } from "../../../design_system/components/search_wrap_dropdown_frame/SearchWrapDropdownFrame";
import { SmallBrownBtn } from "../../../design_system/components/small_brown_btn/SmallBrownBtn";
import { SmallBorderBlackBtn } from "../../../design_system/components/small_border_black_btn/SmallBorderBlackBtn";
import { useNavigate } from "react-router-dom";
import { useAdvertiserAccount } from "../../../data_system/context/advertiser_account_context/AdvertiserAccountContext";
import { useLoading } from "../../../data_system/context/loading_context/LoadingContext";
import {
  getKakaoPresentReviewLikeByConditions,
  putKakaoPresentReviewLike,
} from "../../../communication_system/axios_apis/axios_apis";
import { best_point_charge_inner_router_url } from "../../../communication_system/inner_router_url/inner_router_url";
import StandardModal from "../../../design_system/components/modal/standard_modal/StandardModal";
import { ModalSectionTitle } from "../../../design_system/components/modal_section_title/ModalSectionTitle";
import { isOnlyNumber } from "../../../data_system/data_function/is_only_number";
import { WrapInputFrame } from "../../../design_system/components/wrap_input_frame/WrapInputFrame";
import { WrapInput } from "../../../design_system/components/wrap_input/WrapInput";
import { WrapDropdownFrame } from "../../../design_system/components/wrap_dropdown_frame/WrapDropdownFrame";
import { SelectOptionDropdown } from "../../../design_system/components/select_option_dropdown/SelectOptionDropdown";
import { WrapTextareaFrame } from "../../../design_system/components/wrap_textarea_frame/WrapTextareaFrame";
import { WrapTextarea } from "../../../design_system/components/wrap_textarea/WrapTextarea";
import { TipTextBox } from "../../../design_system/components/tip_text_box/TipTextBox";
import { TipTextBody } from "../../../design_system/components/tip_text_body/TipTextBody";
import { TipTextTitle } from "../../../design_system/components/tip_text_title/TipTextTitle";
import { TipText } from "../../../design_system/components/tip_text/TipText";

const KakaoPresentReviewLikePage = () => {
  const { advertiserAccount } = useAdvertiserAccount();
  const { showLoading, hideLoading } = useLoading();

  let navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [todayAdvertisementProgressType, setTodayAdvertisementProgressType] =
    useState("");

  const handleTodayAdvertisementProgressType = (e) => {
    if (e.target.value === "") {
      setTodayAdvertisementProgressType("");
    } else {
      setTodayAdvertisementProgressType(e.target.value);
    }
  };

  const [searchTypeId, setSearchTypeId] = useState("");

  const handleSearchTypeId = (e) => {
    if (e.target.value === "") {
      setSearchTypeId("");
    } else {
      setSearchTypeId(e.target.value);
    }
  };

  const [searchText, setSearchText] = useState("");

  const [
    kakaoPresentReviewLikeByConditions,
    setKakaoPresentReviewLikeByConditions,
  ] = useState(null);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [
    selectedAdvertiserAdvertisementProductId,
    setSelectedAdvertiserAdvertisementProductId,
  ] = useState(null);

  const [modalNextTargetDailyWorkload, setModalNextTargetDailyWorkload] =
    useState("");

  const [
    modalNextAdvertisementProgressType,
    setModalNextAdvertisementProgressType,
  ] = useState("");

  const handleModalNextAdvertisementProgressType = (e) => {
    if (e.target.value === "") {
      setModalNextAdvertisementProgressType("");
    } else {
      setModalNextAdvertisementProgressType(e.target.value);
    }
  };

  const [showMemoModal, setShowMemoModal] = useState(false);

  const [memo, setMemo] = useState("");

  const [showExcelModal, setShowExcelModal] = useState(false);

  const updateKakaoPresentReviewLikeData = (
    selectedAdvertiserAdvertisementProductId, // 업데이트할 요소를 식별하기 위한 ID
    updatedData // 적용할 업데이트 데이터
  ) => {
    setKakaoPresentReviewLikeByConditions((prevState) => {
      // 배열을 매핑하여 적절한 요소를 찾고 업데이트
      const updatedKakaoPresentReviewLikes =
        prevState.kakaoPresentReviewLikes.map((item) => {
          if (
            item.advertiserAdvertisementProductId ===
            selectedAdvertiserAdvertisementProductId
          ) {
            // 해당 요소를 찾았을 경우 업데이트 실행
            return {
              ...item,
              ...updatedData, // 전달된 업데이트 데이터를 통합
            };
          }
          return item; // 다른 요소는 변경 없이 그대로 반환
        });

      return {
        ...prevState,
        kakaoPresentReviewLikes: updatedKakaoPresentReviewLikes, // 업데이트된 배열을 상태에 반영
      };
    });
  };

  useEffect(() => {
    async function getData() {
      showLoading();

      const response = await getKakaoPresentReviewLikeByConditions(
        todayAdvertisementProgressType === ""
          ? null
          : parseInt(todayAdvertisementProgressType),
        searchTypeId === "" ? null : parseInt(searchTypeId),
        searchText,
        startDate,
        endDate,
        page,
        10
      );

      console.log(response);
      setKakaoPresentReviewLikeByConditions(
        response.data.kakaoPresentReviewLikeByConditions
      );

      hideLoading();
    }

    getData();
  }, [page]);

  return (
    <>
      <Section>
        <ContentHeader>
          <HeaderTitleH2>카카오 선물 후기 좋아요</HeaderTitleH2>
          <p
            style={{
              color: "#707070",
              fontSize: "0.9rem",
            }}
          >
            ※ 3개월간 실행이력이 없는 상품은 삭제됩니다.
          </p>
        </ContentHeader>
        <MainBody>
          <SearchBodyHeader>
            <SearchWrap>
              <SearchWrapDropdownFrame>
                <SearchSelectOptionDropdown
                  onChange={handleTodayAdvertisementProgressType}
                  value={todayAdvertisementProgressType}
                >
                  <option value={""}>광고 실행 상태</option>
                  <option value={1}>실행</option>
                  <option value={2}>중단</option>
                </SearchSelectOptionDropdown>
              </SearchWrapDropdownFrame>
              <SearchWrapDropdownFrame>
                <SearchSelectOptionDropdown
                  onChange={handleSearchTypeId}
                  value={searchTypeId}
                >
                  <option value={""}>검색항목</option>
                  <option value={1}>상품명</option>
                </SearchSelectOptionDropdown>
              </SearchWrapDropdownFrame>
              <SearchWrapInputFrame>
                <SearchWrapInput
                  type="text"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                ></SearchWrapInput>
              </SearchWrapInputFrame>
              <SearchWrapInputFrame>
                <SearchWrapInput
                  type="date"
                  value={startDate === null ? "" : startDate}
                  onChange={(e) => {
                    if (e.target.value > endDate) {
                      alert("시작일은 종료일 이전의 날짜만 선택 가능합니다.");
                      setStartDate(endDate);
                    } else {
                      setStartDate(e.target.value);
                    }
                  }}
                ></SearchWrapInput>
              </SearchWrapInputFrame>
              <SearchWrapInputFrame>
                <SearchWrapInput
                  type="date"
                  value={endDate === null ? "" : endDate}
                  onChange={(e) => {
                    if (e.target.value < startDate) {
                      alert("종료일은 시작일 이후의 날짜만 선택 가능합니다.");
                      setEndDate(startDate);
                    } else {
                      setEndDate(e.target.value);
                    }
                  }}
                ></SearchWrapInput>
              </SearchWrapInputFrame>
              <SearchWrapFlexLi>
                <SmallBrownBtn
                  onClick={async () => {
                    showLoading();

                    const response =
                      await getKakaoPresentReviewLikeByConditions(
                        todayAdvertisementProgressType === ""
                          ? null
                          : parseInt(todayAdvertisementProgressType),
                        searchTypeId === "" ? null : parseInt(searchTypeId),
                        searchText,
                        startDate,
                        endDate,
                        page,
                        10
                      );

                    console.log(response);
                    setKakaoPresentReviewLikeByConditions(
                      response.data.kakaoPresentReviewLikeByConditions
                    );

                    hideLoading();
                  }}
                >
                  검색
                </SmallBrownBtn>
              </SearchWrapFlexLi>
            </SearchWrap>
          </SearchBodyHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                border: "1px solid #000",
                boxShadow: "0 2px 6px #0B44A530",
                display: "block",
                padding: "7px 10px",
                borderRadius: "5px",
                backgroundColor: "#fff",
                fontWeight: "bold",
                textDecoration: "none",
                color: "#222",
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(best_point_charge_inner_router_url);
              }}
            >
              <span
                style={{
                  fontSize: "1.1rem",
                  marginRight: "10px",
                }}
              >
                보유 포인트
              </span>
              <span
                style={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              >
                {advertiserAccount === null
                  ? 0
                  : numberFormatFunction(advertiserAccount.leftPoint)}
              </span>
            </div>
          </div>
          <div
            style={{
              height: "auto",
              backgroundColor: "#fff",
              marginBottom: "20px",
              padding: "20px",
              border: "1px solid #d3dbec",
            }}
          >
            <TableFrame>
              <thead>
                <tr
                  style={{
                    backgroundColor: "#fff",
                    display: "table-row",
                    verticalAlign: "inherit",
                  }}
                >
                  <TableTitleDarkGray>등록일</TableTitleDarkGray>
                  <TableTitleDarkGray>상품명</TableTitleDarkGray>
                  <TableTitleDarkGray>상품 ID</TableTitleDarkGray>
                  <TableTitleRed>총 작업량</TableTitleRed>
                  <TableTitleRed>오늘 목표 작업량</TableTitleRed>
                  <TableTitleRed>오늘 달성 작업량</TableTitleRed>
                  <TableTitleRed>내일 목표 작업량</TableTitleRed>
                  <TableTitleDarkGray>오늘 실행 상태</TableTitleDarkGray>
                  <TableTitleDarkGray>내일 실행 상태</TableTitleDarkGray>
                  <TableTitleDarkGray colSpan={2}>설정</TableTitleDarkGray>
                  <TableTitleDarkGray colSpan={2}>내역</TableTitleDarkGray>
                  <TableTitleDarkGray colSpan={2}>메모</TableTitleDarkGray>
                </tr>
              </thead>
              {kakaoPresentReviewLikeByConditions !== null && (
                <tbody>
                  {kakaoPresentReviewLikeByConditions.kakaoPresentReviewLikes
                    .length === 0 ? (
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        display: "table-row",
                        verticalAlign: "inherit",
                      }}
                    >
                      <TableCellNormal colSpan={15}>
                        자료가 없습니다.
                      </TableCellNormal>
                    </tr>
                  ) : (
                    kakaoPresentReviewLikeByConditions.kakaoPresentReviewLikes.map(
                      (kakaoPresentReviewLike) => {
                        return (
                          <tr
                            key={kakaoPresentReviewLike.id}
                            style={{
                              backgroundColor: "#fff",
                              display: "table-row",
                              verticalAlign: "inherit",
                            }}
                          >
                            <TableCellNormal>
                              {dateFormatFunction(
                                new Date(kakaoPresentReviewLike.createdAt)
                              )}
                            </TableCellNormal>
                            <TableCellNormal>
                              {kakaoPresentReviewLike.productName}
                            </TableCellNormal>
                            <TableCellNormal>
                              {kakaoPresentReviewLike.productId}
                            </TableCellNormal>
                            <TableCellNormal>
                              {numberFormatFunction(
                                kakaoPresentReviewLike.totalWorkload
                              )}
                            </TableCellNormal>
                            <TableCellNormal>
                              {numberFormatFunction(
                                kakaoPresentReviewLike.todayTargetDailyWorkload
                              )}
                            </TableCellNormal>
                            <TableCellNormal>
                              {numberFormatFunction(
                                kakaoPresentReviewLike.todayCompleteDailyWorkload
                              )}
                            </TableCellNormal>
                            <TableCellNormal>
                              {numberFormatFunction(
                                kakaoPresentReviewLike.nextTargetDailyWorkload
                              )}
                            </TableCellNormal>
                            <TableCellNormal>
                              {kakaoPresentReviewLike.todayAdvertisementProgressType ===
                              1
                                ? "실행"
                                : "중단"}
                            </TableCellNormal>
                            <TableCellNormal>
                              {kakaoPresentReviewLike.nextAdvertisementProgressType ===
                              1
                                ? "실행"
                                : "중단"}
                            </TableCellNormal>
                            <TableCellNormal colSpan={2}>
                              <ModalHoverShadowWhiteBtn
                                onClick={() => {
                                  setSelectedAdvertiserAdvertisementProductId(
                                    kakaoPresentReviewLike.advertiserAdvertisementProductId
                                  );
                                  setModalNextTargetDailyWorkload(
                                    kakaoPresentReviewLike.nextTargetDailyWorkload
                                  );
                                  setModalNextAdvertisementProgressType(
                                    kakaoPresentReviewLike.nextAdvertisementProgressType
                                  );
                                  setShowUpdateModal(true);
                                }}
                              >
                                설정
                              </ModalHoverShadowWhiteBtn>
                            </TableCellNormal>
                            <TableCellNormal colSpan={2}>
                              <ModalHoverShadowGrayBtn
                                onClick={() => {
                                  setShowExcelModal(true);
                                }}
                              >
                                엑셀 다운로드
                              </ModalHoverShadowGrayBtn>
                            </TableCellNormal>
                            <TableCellNormal colSpan={2}>
                              <ModalHoverShadowWhiteBtn
                                onClick={() => {
                                  setSelectedAdvertiserAdvertisementProductId(
                                    kakaoPresentReviewLike.advertiserAdvertisementProductId
                                  );
                                  setMemo(kakaoPresentReviewLike.memo);
                                  setShowMemoModal(true);
                                }}
                              >
                                메모
                              </ModalHoverShadowWhiteBtn>
                            </TableCellNormal>
                          </tr>
                        );
                      }
                    )
                  )}
                </tbody>
              )}
            </TableFrame>
            <Pagination
              totalPages={
                kakaoPresentReviewLikeByConditions === null
                  ? 1
                  : kakaoPresentReviewLikeByConditions.totalPages === 0
                  ? 1
                  : kakaoPresentReviewLikeByConditions.totalPages
              }
              limit={10}
              page={page}
              setPage={setPage}
            ></Pagination>
          </div>
        </MainBody>
      </Section>
      {showUpdateModal && (
        <StandardModal
          onClose={() => {
            setShowUpdateModal(false);
          }}
          titleText={"광고 설정 수정"}
          modalBodyComponent={
            <>
              <ModalSectionTitle>내일 목표 작업량</ModalSectionTitle>
              <div
                style={{
                  height: 10,
                }}
              ></div>
              <WrapInputFrame>
                <WrapInput
                  placeholder="1 이상으로 입력(최소 1건 이상)"
                  value={modalNextTargetDailyWorkload}
                  onChange={(e) => {
                    if (
                      (parseInt(e.target.value) >= 1 &&
                        isOnlyNumber.test(e.target.value)) ||
                      e.target.value === ""
                    ) {
                      setModalNextTargetDailyWorkload(e.target.value);
                    }
                  }}
                ></WrapInput>
              </WrapInputFrame>
              <div
                style={{
                  height: 10,
                }}
              ></div>
              <ModalSectionTitle>내일 실행 상태</ModalSectionTitle>
              <div
                style={{
                  height: 10,
                }}
              ></div>
              <WrapDropdownFrame>
                <SelectOptionDropdown
                  onChange={handleModalNextAdvertisementProgressType}
                  value={modalNextAdvertisementProgressType}
                >
                  <option value={1}>실행</option>
                  <option value={2}>중단</option>
                </SelectOptionDropdown>
              </WrapDropdownFrame>
              <div
                style={{
                  height: 10,
                }}
              ></div>
            </>
          }
          rightBtnText={"저장"}
          useLeftBtn={true}
          onRightBtnClick={async () => {
            console.log(selectedAdvertiserAdvertisementProductId);
            if (modalNextTargetDailyWorkload === "") {
              alert("내일 목표 작업량을 입력해주세요.");
            } else {
              if (parseInt(modalNextTargetDailyWorkload) < 1) {
                alert("1 이상으로 입력해주세요.");
              } else {
                const response = await putKakaoPresentReviewLike(
                  parseInt(selectedAdvertiserAdvertisementProductId),
                  parseInt(modalNextTargetDailyWorkload),
                  parseInt(modalNextAdvertisementProgressType),
                  undefined
                );

                if (response.status === 200) {
                  alert("수정되었습니다.");
                  updateKakaoPresentReviewLikeData(
                    parseInt(selectedAdvertiserAdvertisementProductId),
                    {
                      nextTargetDailyWorkload: parseInt(
                        modalNextTargetDailyWorkload
                      ),
                      nextAdvertisementProgressType: parseInt(
                        modalNextAdvertisementProgressType
                      ),
                    }
                  );
                  setSelectedAdvertiserAdvertisementProductId(null);
                  setModalNextTargetDailyWorkload("");
                  setModalNextAdvertisementProgressType("");
                  setShowUpdateModal(false);
                }
              }
            }
          }}
        ></StandardModal>
      )}
      {showMemoModal && (
        <StandardModal
          onClose={() => {
            setShowMemoModal(false);
          }}
          titleText={"메모 수정"}
          modalBodyComponent={
            <>
              <ModalSectionTitle>메모</ModalSectionTitle>
              <div
                style={{
                  height: 10,
                }}
              ></div>
              <WrapTextareaFrame>
                <WrapTextarea
                  value={memo}
                  onChange={(e) => {
                    setMemo(e.target.value);
                  }}
                ></WrapTextarea>
              </WrapTextareaFrame>
              <div
                style={{
                  height: 10,
                }}
              ></div>
            </>
          }
          rightBtnText={"저장"}
          useLeftBtn={true}
          onRightBtnClick={async () => {
            console.log(selectedAdvertiserAdvertisementProductId);
            const response = await putKakaoPresentReviewLike(
              parseInt(selectedAdvertiserAdvertisementProductId),
              undefined,
              undefined,
              memo
            );

            if (response.status === 200) {
              alert("수정되었습니다.");
              updateKakaoPresentReviewLikeData(
                parseInt(selectedAdvertiserAdvertisementProductId),
                {
                  memo: memo,
                }
              );
              setSelectedAdvertiserAdvertisementProductId(null);
              setMemo("");
              setShowMemoModal(false);
            }
          }}
        ></StandardModal>
      )}
      {showExcelModal && (
        <StandardModal
          onClose={() => {
            setShowExcelModal(false);
          }}
          titleText={"엑셀 다운로드"}
          modalBodyComponent={
            <>
              <div>
                엑셀을 다운로드 받으시겠습니까?<br></br>
                <TipTextBox>
                  <TipTextBody>
                    <TipTextTitle>엑셀 다운로드 확인!</TipTextTitle>
                    <TipText>
                      엑셀 다운로드는 저번 달 내역만 다운로드가 가능합니다.
                    </TipText>
                    <TipText>
                      이번 달이 지나면 2개월 전의 내역은 다운로드할 수 없습니다.
                    </TipText>
                    <TipText>
                      그래서 저번 달의 내역이 필요하신 경우에는 이번 달 안으로
                      다운로드를 받아야 합니다.
                    </TipText>
                  </TipTextBody>
                </TipTextBox>
              </div>
            </>
          }
          rightBtnText={"엑셀 다운로드"}
          useLeftBtn={true}
          onRightBtnClick={async () => {
            setShowExcelModal(false);
          }}
        ></StandardModal>
      )}
    </>
  );
};

export default KakaoPresentReviewLikePage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 182px);
`;

const SearchWrap = styled.ul`
  justify-content: end;
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
`;

const SearchSelectOptionDropdown = styled.select`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const SearchWrapFlexLi = styled.li`
  display: flex;
  background-color: #f9fafe;
  border-radius: 5px;
  border: 1px solid #d3dbec;
  margin-right: 10px;
  overflow: hidden;
  height: 40px;
  list-style: none;
`;

const TableFrame = styled.table`
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d3dbec;
  table-layout: fixed;
  font-size: 0.85rem;
  display: table;
  text-indent: initial;
  border-spacing: 2px;
`;

const TableTitleDarkGray = styled.th`
  background-color: #454d55;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableTitleRed = styled.th`
  background-color: #e84e4e;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableCellNormal = styled.td`
  white-space: normal;
  word-break: break-all;
  border: 0.5px solid #dee2e6;
  padding: 5px;
  text-align: center;
  line-height: 150%;
`;

const SearchWrapInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const HoverA = styled.a`
  text-decoration: underline;
  text-decoration-color: #222;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

const ModalHoverShadowWhiteBtn = styled.button`
  border: 1px solid #999;
  background-color: #fff;
  padding: 6px 15px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
`;

const ModalHoverShadowGrayBtn = styled.button`
  background-color: rgb(99, 99, 99);
  color: #fff;
  border: 1px solid rgb(99, 99, 99);
  padding: 6px 15px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
`;

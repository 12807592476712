import React, { useEffect } from "react";
import styled from "styled-components";
import {
  login_inner_router_url,
  marketing_policy_inner_router_url,
  privacy_policy_inner_router_url,
  refund_policy_inner_router_url,
  sign_up_inner_router_url,
  terms_and_conditions_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import {
  ad_management,
  make_a_lot_of_money,
  many_products,
  moneymoa_phone_mock_up,
} from "../../design_system/images/images";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../data_system/context/auth_context/AuthContext";

const MainPage = () => {
  let navigate = useNavigate();

  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn
            onClick={() => {
              navigate(login_inner_router_url);
            }}
          >
            로그인
          </ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn
              onClick={() => {
                navigate(sign_up_inner_router_url);
              }}
            >
              시작하기
            </ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Content>
        <AboutUs>
          <AboutUsWrap>
            <AboutUsTitle>about moneymoa</AboutUsTitle>
            <AboutUsList>
              <AboutUsListItemFirst>
                <AboutUsListItemImgWrap>
                  <AboutUsListItemImg src={many_products}></AboutUsListItemImg>
                </AboutUsListItemImgWrap>
                <AboutUsListItemSpan>
                  다양한 광고 상품을 통한 다양화
                </AboutUsListItemSpan>
                <AboutUsListItemPFirstSecond>
                  모든 매체 광고 상품을 한 곳에서
                </AboutUsListItemPFirstSecond>
              </AboutUsListItemFirst>
              <AboutUsListItemSecond>
                <AboutUsListItemImgWrap>
                  <AboutUsListItemImg src={ad_management}></AboutUsListItemImg>
                </AboutUsListItemImgWrap>
                <AboutUsListItemSpan>
                  쉽고 편한 광고 관리 시스템 사용
                </AboutUsListItemSpan>
                <AboutUsListItemPFirstSecond>
                  몇 번의 클릭으로 광고를 관리
                </AboutUsListItemPFirstSecond>
              </AboutUsListItemSecond>
              <AboutUsListItemThird>
                <AboutUsListItemImgWrap>
                  <AboutUsListItemImg
                    src={make_a_lot_of_money}
                  ></AboutUsListItemImg>
                </AboutUsListItemImgWrap>
                <AboutUsListItemSpan>
                  다양한 광고와 시스템을 통한 성장
                </AboutUsListItemSpan>
                <AboutUsListItemPThird>
                  가파른 성장을 위한 다양한 시스템
                </AboutUsListItemPThird>
              </AboutUsListItemThird>
            </AboutUsList>
          </AboutUsWrap>
        </AboutUs>
        <Business>
          <BusinessWrap>
            <img
              style={{
                width: "300px",
              }}
              src={moneymoa_phone_mock_up}
            ></img>
            <BusinessP>
              전세계에서 가장 쉽고 편한 <b>광고 관리 시스템</b>을 만나보세요.
              <b> 언제 어디서나 원하는 시간</b>에 광고를 등록하고 관리할 수
              있습니다. 이미 많은 사람들이 사용하고 있습니다.{" "}
              <b>지금 시작하세요.</b>
            </BusinessP>
          </BusinessWrap>
        </Business>
      </Content>
      <Footer>
        <FooterHelpUl>
          <FooterHelpLi>
            <FooterHelpTextWhite
              onClick={() => {
                navigate(terms_and_conditions_inner_router_url);
              }}
            >
              이용약관
            </FooterHelpTextWhite>
          </FooterHelpLi>
          <FooterHelpLi>
            <FooterHelpTextOrange
              onClick={() => {
                navigate(privacy_policy_inner_router_url);
              }}
            >
              개인정보처리방침
            </FooterHelpTextOrange>
          </FooterHelpLi>
          <FooterHelpLi>
            <FooterHelpTextWhite
              onClick={() => {
                navigate(refund_policy_inner_router_url);
              }}
            >
              환불정책
            </FooterHelpTextWhite>
          </FooterHelpLi>
          <FooterHelpLi>
            <FooterHelpTextWhite
              onClick={() => {
                navigate(marketing_policy_inner_router_url);
              }}
            >
              마케팅 수신동의 약관
            </FooterHelpTextWhite>
          </FooterHelpLi>
        </FooterHelpUl>
        <FooterHelpInfo>
          <dt>회사명 </dt>
          <dd>주식회사 핑거네이션</dd>
          <dt>주소</dt>
          <dd>서울특별시 강남구 테헤란로82길 15, 325호(대치동, 디아이타워)</dd>
          <dt>전화</dt>
          <dd>02-6404-2442</dd>
          <dd>사업자 등록번호 567-88-02834</dd>
          <dt>대표자</dt>
          <dd>박준상</dd>
          <dd>Copyright by moneymoa. All rights reserved.</dd>
        </FooterHelpInfo>
      </Footer>
    </Wrapper>
  );
};

export default MainPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #353535;
  font-size: 18px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  margin: 62px auto 0 auto;
  display: block;
  box-sizing: border-box;
`;

const AboutUs = styled.div`
  padding-top: 100px;
  background-color: #ffe100;
  box-sizing: border-box;
`;

const AboutUsWrap = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  box-sizing: border-box;
`;

const AboutUsTitle = styled.h2`
  margin: 0 auto 19px auto;
  padding: 0;
  font-family: HelveticaNeueLT-Bold;
  text-align: center;
  font-size: 80px;
  line-height: 80px;
  letter-spacing: -5.5px;
  color: #2f2f2f;
  box-sizing: border-box;

  @media only screen and (max-width: 767px) {
    font-size: 70px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 65px;
  }
`;

const AboutUsList = styled.ul`
  margin: 0 0 124px 0;
  padding: 0;
  line-height: 1px;
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 1200px) {
    justify-content: space-around;
  }

  @media only screen and (min-width: 1025px) {
    text-align: center;
  }

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    margin: 0 0 124px 0;
    padding: 0;
  }

  @media only screen and (max-width: 767px) {
    margin: 40px 0 70px 0;
    padding: 0;
  }
`;

const AboutUsListItemFirst = styled.li`
  margin-left: 0;
  margin-top: 140px;
  width: 25.69444%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  list-style: none;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 295px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 295px;
    margin-left: 12.5px;
    margin-right: 12.5px;
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    margin: 50px auto 0;
  }
`;

const AboutUsListItemSecond = styled.li`
  margin-top: 140px;
  width: 25.69444%;
  margin-left: 11.11111%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  list-style: none;

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    margin-left: 9%;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 295px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 295px;
    margin-left: 12.5px;
    margin-right: 12.5px;
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    margin: 50px auto 0;
  }
`;

const AboutUsListItemThird = styled.li`
  margin-top: 140px;
  width: 25.69444%;
  margin-left: 11.11111%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  list-style: none;

  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    margin-left: 9%;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 295px;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 295px;
    margin-left: 12.5px;
    margin-right: 12.5px;
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    margin: 50px auto 0;
  }
`;

const AboutUsListItemImgWrap = styled.p`
  background-repeat: no-repeat;
  box-sizing: border-box;

  @media only screen and (min-width: 1025px) {
    margin-bottom: 54px;
    display: block;
    width: 308px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 767px) {
    margin: 0;
    text-align: center;
  }
`;

const AboutUsListItemImg = styled.img`
  width: 295px;
  max-width: 100%;
  border: 0;
  box-sizing: border-box;
`;

const AboutUsListItemSpan = styled.span`
  margin: 36px 0 0 0;
  font-family: "Noto Sans Korean";
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: #353535;
  letter-spacing: -1.5px;
  text-align: center;
  display: block;
  box-sizing: border-box;

  @media only screen and (min-width: 1025px) {
    margin-bottom: 20px;
    display: block;
    width: 308px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 34px;
    letter-spacing: -0.05em;
  }

  @media only screen and (max-width: 767px) {
    letter-spacing: -0.05em;
    display: block;
    width: 295px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 34px;
    font-family: "Noto Sans Korean";
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
  }
`;

const AboutUsListItemPFirstSecond = styled.p`
  margin: 15px 0 124px 0;
  font-family: "Noto Sans Korean";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #353535;
  letter-spacing: -1.5px;
  text-align: center;
  display: block;
  box-sizing: border-box;

  @media only screen and (min-width: 1025px) {
    display: block;
    width: 308px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 14px;
    letter-spacing: -0.05em;
    margin: 15px auto 62px auto;
  }

  @media only screen and (max-width: 767px) {
    letter-spacing: -0.05em;
    display: block;
    width: 295px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 16px;
    margin: 15px auto 47px auto;
  }
`;

const AboutUsListItemPThird = styled.p`
  margin: 15px 0 124px 0;
  font-family: "Noto Sans Korean";
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #353535;
  letter-spacing: -1.5px;
  text-align: center;
  display: block;
  box-sizing: border-box;

  @media only screen and (min-width: 1025px) {
    display: block;
    width: 308px;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 14px;
    letter-spacing: -0.05em;
    margin: 15px auto 102px auto;
  }

  @media only screen and (max-width: 767px) {
    letter-spacing: -0.05em;
    display: block;
    width: 295px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 16px;
    margin: 15px auto 102px auto;
  }
`;

const Business = styled.div`
  box-sizing: border-box;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    margin-bottom: 62px;
  }
`;

const BusinessWrap = styled.div`
  padding: 100px;
  max-width: 1440px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    padding: 50px;
  }
`;

const BusinessP = styled.p`
  float: left;
  width: 60%;
  display: block;
  font-family: "Noto Sans Korean Thin";
  font-weight: 100;
  font-size: 38px;
  line-height: 52px;
  letter-spacing: -3px;
  color: #353535;
  text-align: justify;
  margin-left: 5%;

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-left: 0;
    width: 80%;
    font-size: 38px;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;
    width: 100%;
    font-size: 26px;
  }
`;

const Footer = styled.footer`
  width: 100%;
  padding-left: 52px;
  padding-bottom: 60px;
  background-color: #282828;
  font-family: "Noto Sans Korean";
  font-weight: 500;
  color: #9a9a9a;
  box-sizing: border-box;
  display: flex;

  @media screen and (min-width: 768px) {
    padding-bottom: 143px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 102px;
    flex-direction: column;
  }
`;

const FooterHelpUl = styled.ul`
  display: inline-block;
  margin: 70px 126px 0 0;
  padding: 0;
  font-size: 18px;
  line-height: 38px;
  list-style: none;

  @media only screen and (max-width: 767px) {
    display: block;
    margin: 0 126px 0 0;
    padding: 0;
    font-size: 14px;
    line-height: 38px;
  }
`;

const FooterHelpLi = styled.li`
  cursor: pointer;
`;

const FooterHelpTextWhite = styled.div`
  color: #fff;
`;

const FooterHelpTextOrange = styled.div`
  color: #fb8748;
`;

const FooterHelpInfo = styled.dl`
  display: inline-block;
  margin: 70px 0 0 0;
  padding: 0;
  vertical-align: top;
  font-size: 14px;
  line-height: 28px;
  box-sizing: border-box;

  @media only screen and (max-width: 767px) {
    display: inline-block;
    margin: 17px 0 0 0;
    padding: 0;
    vertical-align: top;
    font-size: 14px;
    line-height: 28px;
  }
`;

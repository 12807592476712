import styled from "styled-components";

export const WrapDropdownFrame = styled.li`
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #353535;
  margin-right: 10px;
  overflow: hidden;
  height: 40px;
  flex-grow: 1;
  list-style: none;
`;

import styled from "styled-components";

export const ModalHoverShadowWhiteBtn = styled.button`
  border: 1px solid #999;
  background-color: #fff;
  padding: 6px 15px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
`;

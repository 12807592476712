import React, { createContext, useContext, useState } from "react";
import LoadingIndicator from "../../../design_system/components/loading_indicator/LoadingIndicator";

const LoadingContext = createContext();

export const useLoading = () => {
  return useContext(LoadingContext);
};

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const showLoading = () => setIsLoading(true);
  const hideLoading = () => setIsLoading(false);

  return (
    <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
      {children}
      {isLoading && <LoadingIndicator />}
    </LoadingContext.Provider>
  );
}

import React, { useState } from "react";

const Tooltip = ({ children, text }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={{ position: "relative", display: "inline-block" }}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: "absolute",
            bottom: "100%",
            left: "50%",
            transform: "translateX(-50%)",
            marginBottom: "10px",
            padding: "5px",
            backgroundColor: "black",
            color: "white",
            borderRadius: "4px",
            fontSize: "12px",
            zIndex: 1,
            width: "max-content",
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;

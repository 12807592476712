import React, { createContext, useState, useContext } from "react";
import axiosMiddleware from "../../../communication_system/axios_apis/axios_middleware/axios_middleware";
import {
  advertiserLogin,
  advertiserSignUp,
} from "../../../communication_system/axios_apis/axios_apis";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedValue = window.localStorage.getItem("isLoggedIn");
    return storedValue !== null || storedValue !== undefined
      ? JSON.parse(storedValue)
      : false;
  });

  const handleSignUp = async (
    businessTypeId,
    advertiserName,
    businessRegistrationNumber,
    businessRegistrationImageUrl,
    businessName,
    email,
    password,
    phoneNumber,
    privacyPolicyAgreed,
    servicePolicyAgreed,
    refundPolicyAgreed,
    marketingPolicyAgreed
  ) => {
    const response = await advertiserSignUp(
      businessTypeId,
      advertiserName,
      businessRegistrationNumber,
      businessRegistrationImageUrl,
      businessName,
      email,
      password,
      phoneNumber,
      privacyPolicyAgreed,
      servicePolicyAgreed,
      refundPolicyAgreed,
      marketingPolicyAgreed
    );
    console.log(response);
    if (parseInt(response.status) === 201) {
      login(response.accessToken, response.refreshToken);
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 400) {
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 403) {
      return {
        status: response.status,
      };
    }
  };

  const handleLogin = async (email, password) => {
    const response = await advertiserLogin(email, password);
    console.log(response);
    if (parseInt(response.status) === 200) {
      login(response.accessToken, response.refreshToken);
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 400) {
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 401) {
      return {
        status: response.status,
      };
    } else if (parseInt(response.status) === 403) {
      return {
        status: response.status,
      };
    }
  };

  const login = (accessToken, refreshToken) => {
    console.log(accessToken, refreshToken);
    window.localStorage.setItem("accessToken", accessToken);
    window.localStorage.setItem("refreshToken", refreshToken);
    window.localStorage.setItem("isLoggedIn", true);

    axiosMiddleware.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;

    setIsLoggedIn(true);
  };

  const logout = () => {
    window.localStorage.setItem("isLoggedIn", false);
    setIsLoggedIn(false);
  };

  const value = {
    isLoggedIn,
    handleSignUp,
    handleLogin,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TipTextBox } from "../../../../design_system/components/tip_text_box/TipTextBox";
import { TipTextBody } from "../../../../design_system/components/tip_text_body/TipTextBody";
import { TipTextTitle } from "../../../../design_system/components/tip_text_title/TipTextTitle";
import { BigRoundBrownBtn } from "../../../../design_system/components/big_round_brown_btn/BigRoundBrownBtn";
import { useAdministratorAdvertisementProduct } from "../../../../data_system/context/administrator_advertisement_product_context/AdministratorAdvertisementProductContext";
import { isOnlyNumber } from "../../../../data_system/data_function/is_only_number";
import { postGoogleMapSave } from "../../../../communication_system/axios_apis/axios_apis";
import StandardModal from "../../../../design_system/components/modal/standard_modal/StandardModal";
import { TipText } from "../../../../design_system/components/tip_text/TipText";
import { isValidHttpUrl } from "../../../../data_system/data_function/is_valid_http_url";
import { useGoogleMaps } from "../../../../data_system/context/google_maps_context/GoogleMapsContext";

const GoogleMapSavePostTab = () => {
  const { administratorAdvertisementProducts } =
    useAdministratorAdvertisementProduct();

  const [mapPlaceName, setMapPlaceName] = useState("");
  const [mapPlaceUrl, setMapPlaceUrl] = useState("");
  const [nextTargetDailyWorkload, setNextTargetDailyWorkload] = useState("");

  const [
    showPostAdvertiserAdvertisementProductCheckModal,
    setShowPostAdvertiserAdvertisementProductCheckModal,
  ] = useState(false);

  const { isLoaded, loadError } = useGoogleMaps();
  const [address, setAddress] = useState("");
  const [detailAddress, setDetailAddress] = useState("");
  const [position, setPosition] = useState({ latitude: 0, longitude: 0 });
  const [suggestions, setSuggestions] = useState([]);
  const [userInput, setUserInput] = useState(false);

  const handleInputChange = (e) => {
    setAddress(e.target.value);
    setUserInput(true); // 사용자 입력이 변경되면 true로 설정
  };

  const getPlaceDetails = (placeId) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    placesService.getDetails(
      {
        placeId: placeId,
        fields: ["geometry"],
        language: "ko",
      },
      (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          setPosition({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          });
          setSuggestions([]); // 해당 위치를 선택한 후에는 제안 목록을 초기화
        }
      }
    );
  };

  const handleSuggestionClick = (description, placeId) => {
    setAddress(description);
    setUserInput(false);
    getPlaceDetails(placeId); // 제안 클릭시에는 getPlaceDetails를 직접 호출
  };

  useEffect(() => {
    if (!address || !userInput) {
      setSuggestions([]);
      return;
    }

    if (window.google && window.google.maps && window.google.maps.places) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      autocompleteService.getPlacePredictions(
        {
          input: address,
          componentRestrictions: { country: "kr" },
          language: "ko",
        },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions);
          }
        }
      );
    }
  }, [address, userInput]);

  return (
    <>
      <>
        <>
          <ProductPostDivTitle>맵 플레이스명 *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="맵 플레이스명을 입력해주세요."
                value={mapPlaceName}
                onChange={(e) => {
                  setMapPlaceName(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>구글 맵 URL *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="구글 맵 URL(모바일 주소)"
                value={mapPlaceUrl}
                onChange={(e) => {
                  setMapPlaceUrl(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          {isLoaded && (
            <div>
              <ProductPostDivTitle>주소로 검색하기 *</ProductPostDivTitle>
              <ProductPostDivFrame>
                <InputFrame>
                  <ProductPostInput
                    backgroundColor="#fff"
                    placeholder="예) 경북 포항시 남구 희망대로 1189"
                    value={address}
                    onChange={handleInputChange}
                  ></ProductPostInput>
                </InputFrame>

                {suggestions.length !== 0 && (
                  <div
                    style={{
                      border: "1px solid #dcdfe6",
                      borderRadius: "8px",
                      boxSizing: "border-box",
                      maxHeight: "200px",
                      overflowY: "auto",
                      width: "100%",
                      backgroundColor: "#ffffff",
                      marginTop: 15,
                    }}
                  >
                    {suggestions.map((suggestion) => (
                      <div
                        key={suggestion.place_id}
                        style={{
                          padding: "8px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleSuggestionClick(
                            suggestion.description,
                            suggestion.place_id
                          );
                        }}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                )}
              </ProductPostDivFrame>
            </div>
          )}
        </>
        <>
          <ProductPostDivTitle>상세주소 *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="상세주소를 적어주세요. 예) 1층"
                value={detailAddress}
                onChange={(e) => {
                  setDetailAddress(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>일일 목표 작업량 *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="1 이상으로 입력(최소 1건 이상)"
                value={nextTargetDailyWorkload}
                onChange={(e) => {
                  if (
                    (parseInt(e.target.value) >= 1 &&
                      isOnlyNumber.test(e.target.value)) ||
                    e.target.value === ""
                  ) {
                    setNextTargetDailyWorkload(e.target.value);
                  }
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>광고 단가</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                value={`${administratorAdvertisementProducts[14].nextAdvertiserPrice}원`}
                disabled
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <BigRoundBrownBtn
            onClick={() => {
              if (mapPlaceName === "") {
                alert("맵 플레이스명을 입력해주세요.");
              } else {
                if (mapPlaceUrl === "") {
                  alert("구글 맵 URL을 입력해주세요.");
                } else {
                  if (nextTargetDailyWorkload === "") {
                    alert("일일 목표 작업량을 입력해주세요.");
                  } else {
                    if (parseInt(nextTargetDailyWorkload) < 1) {
                      alert("일일 목표 작업량은 1 이상으로 입력해주세요.");
                    } else {
                      if (address === "") {
                        alert("주소로 검색해주세요.");
                      } else {
                        if (detailAddress === "") {
                          alert("상세주소를 입력해주세요.");
                        } else {
                          if (isValidHttpUrl(mapPlaceUrl)) {
                            setShowPostAdvertiserAdvertisementProductCheckModal(
                              true
                            );
                          } else {
                            alert("구글 맵 URL 형식을 확인해주세요.");
                          }
                        }
                      }
                    }
                  }
                }
              }
            }}
          >
            광고 등록하기
          </BigRoundBrownBtn>
        </div>
        <TipTextBox>
          <TipTextTitle>TIP.</TipTextTitle>
          <TipTextBody>
            <TipText>
              상품유형 선택: 진행할 상품을 선택 후 등록해주시면 됩니다.
            </TipText>
            <TipText>
              구글 맵 URL: 구글 맵 URL입니다. 모바일 주소여야 합니다.
            </TipText>
            <TipText>
              일일 목표 작업량: 하루에 몇 건의 작업량을 목표로 하는지의
              수치입니다. 1 이상으로 입력해주세요(최소 1건 이상). 일일 목표
              작업량은 하루에 얼마나 광고를 집행할지 결정하는 수치입니다.
            </TipText>
            <TipText>진행순서: 광고 등록하기 &#62; 진행</TipText>
            <TipText>
              주의 및 참고사항:<br></br>1. 일일 목표 작업량은 서버 점검 시간
              이외에 언제든지 변경할 수 있습니다.<br></br>2. 당일 저녁 11시 전에
              다음 날 작업을 진행하지 않도록 광고 멈춤 설정을 할 수 있습니다.
              <br></br>
              3. 당일 등록한 광고는 당일 저녁 11시에 시작하는 서버 점검 기간부터
              작업합니다.
              <br></br>4. 매일 저녁 11시부터 다음 날 새벽 1시까지는 서버
              점검으로 광고 등록 및 관리가 불가능합니다.<br></br>
              5. 저녁 11시부터 다음 날 새벽 1시까지 서버 점검 동안 다음 날
              광고를 서버에서 유저에게 전달합니다. 즉, 이미 저녁 11시부터 광고가
              전달되었으므로 저녁 11시부터 환불이 불가능합니다.
              <br></br>6. 일일 목표 작업량을 당일 전부 수행하지 못 하면 당일
              저녁 11시부터 새벽 1시까지 점검 기간 동안 수행하지 못 한 작업량은
              포인트로 환불됩니다.<br></br>7. 잘못된 정보 등록으로 등록 광고의
              평균 작업 속도가 해당 광고의 평균 작업 속도 보다 현저하게 느리다면
              자동으로 광고가 중단됩니다.
              <br></br>8. 잘못된 정보로 등록하여 작업된 광고는 환불이
              불가능합니다.
            </TipText>
          </TipTextBody>
        </TipTextBox>
        <div
          style={{
            height: 20,
          }}
        ></div>
      </>
      {showPostAdvertiserAdvertisementProductCheckModal && (
        <StandardModal
          onClose={() => {
            setShowPostAdvertiserAdvertisementProductCheckModal(false);
          }}
          titleText={"광고 등록 전 확인!"}
          modalBodyComponent={
            <>
              <div>
                입력 내용이 맞는지 확인해주세요!<br></br>
                <br></br>잘못된 정보로 인한 광고 등록은 환불이 불가능합니다.
                <br></br>
                <br></br>한번 입력된 정보는 수정이 불가능합니다. 다만, 일일 목표
                작업량과 광고 실행 및 중단 변경은 가능합니다.
                <br></br>
                <br></br>광고를 등록하시겠습니까?<br></br>
                <TipTextBox>
                  <TipTextBody>
                    <TipTextTitle>포인트 충전 확인!</TipTextTitle>
                    <TipText>
                      포인트 충전을 확인해주세요. 포인트가 부족할 때 자동으로
                      광고가 중단됩니다.
                    </TipText>
                    <TipText>
                      당일 등록된 광고는 다음 날부터 광고가 집행됩니다.
                    </TipText>
                  </TipTextBody>
                </TipTextBox>
              </div>
            </>
          }
          rightBtnText={"확인"}
          onRightBtnClick={async () => {
            const response = await postGoogleMapSave(
              mapPlaceName.trim(), // mapPlaceName
              mapPlaceUrl.trim(), // mapPlaceUrl
              0, // totalWorkload
              0, // todayTargetWorkload
              0, // todayCompleteWorkload
              parseInt(nextTargetDailyWorkload), // nextTargetDailyWorkload
              0, // totalSpentPoint
              0, // todayAdvertisePrice
              parseInt(
                administratorAdvertisementProducts[14].nextAdvertiserPrice
              ), // nextAdvertiserPrice
              "",
              2,
              1,
              15,
              address,
              detailAddress,
              position.latitude,
              position.longitude,
              null
            );

            if (response.status === 201) {
              setMapPlaceName("");
              setMapPlaceUrl("");
              setAddress("");
              setDetailAddress("");
              setPosition({ latitude: 0, longitude: 0 });
              setNextTargetDailyWorkload("");
              setShowPostAdvertiserAdvertisementProductCheckModal(false);
              alert("광고가 성공적으로 등록되었습니다.");
            }
          }}
        ></StandardModal>
      )}
    </>
  );
};

export default GoogleMapSavePostTab;

const ProductPostDivFrame = styled.div`
  flex-wrap: wrap;
  display: flex;
  line-height: 40px;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ProductPostDivTitle = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

const InputFrame = styled.div`
  background-color: #fff;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 0px 10px;
  line-height: 40px;
`;

const ProductPostInput = styled.input`
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    outline: none;
    cursor: not-allowed;
  }
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentHeader } from "../../design_system/components/content_header/ContentHeader";
import { TabSelectSectionBtn } from "../../design_system/components/tab_select_section_btn/TabSelectSectionBtn";
import { BigRoundBrownBtn } from "../../design_system/components/big_round_brown_btn/BigRoundBrownBtn";
import { useLoading } from "../../data_system/context/loading_context/LoadingContext";
import {
  advertiserAccountBankInfo,
  advertiserAccountDetailInfo,
  postAdvertiserAccountResetPassword,
} from "../../communication_system/axios_apis/axios_apis";
import { useAdvertiserAccount } from "../../data_system/context/advertiser_account_context/AdvertiserAccountContext";

const MyPage = () => {
  const { advertiserAccount, setAdvertiserAccount, refetchAdvertiserAccount } =
    useAdvertiserAccount();
  const { showLoading, hideLoading } = useLoading();

  const [
    productSelectSectionBtnSelectedId,
    setProductSelectSectionBtnSelectedId,
  ] = useState(null);

  // 버튼 클릭 이벤트 핸들러
  const productSelectSectionBtnClick = (id) => {
    // 이미 선택된 버튼을 클릭하면 선택 취소
    if (productSelectSectionBtnSelectedId === id) {
      setProductSelectSectionBtnSelectedId(null);
    } else {
      setProductSelectSectionBtnSelectedId(id); // 새로운 버튼 선택
    }
  };

  const productSelectSectionBtnData = [
    {
      id: 0,
      title: "계정정보",
    },
    {
      id: 1,
      title: "은행정보",
    },
    {
      id: 2,
      title: "상세정보",
    },
  ];

  const [bankTypeId, setBankTypeId] = useState(0);
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [businessTypeId, setBusinessTypeId] = useState(1);
  const [advertiserName, setAdvertiserName] = useState("");
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState("");
  const [businessName, setBusinessName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [marketingPolicyAgreed, setMarketingPolicyAgreed] = useState(0);

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");

  const handleBankChange = (event) => {
    setBankTypeId(parseInt(event.target.value));
  };

  const handleMarketingPolicyAgreedChange = (event) => {
    setMarketingPolicyAgreed(parseInt(event.target.value));
  };

  useEffect(() => {
    async function getData() {
      showLoading();

      console.log(advertiserAccount);
      setBankTypeId(
        advertiserAccount === null ? 0 : advertiserAccount.bankTypeId
      );
      setBankAccountNumber(
        advertiserAccount === null ? "" : advertiserAccount.bankAccountNumber
      );
      setBusinessTypeId(
        advertiserAccount === null ? 1 : advertiserAccount.businessTypeId
      );
      setAdvertiserName(
        advertiserAccount === null ? "" : advertiserAccount.advertiserName
      );
      setBusinessRegistrationNumber(
        advertiserAccount === null
          ? ""
          : advertiserAccount.businessRegistrationNumber
      );
      setBusinessName(
        advertiserAccount === null ? "" : advertiserAccount.businessName
      );
      setPhoneNumber(
        advertiserAccount === null ? "" : advertiserAccount.phoneNumber
      );
      setMarketingPolicyAgreed(
        advertiserAccount === null
          ? 0
          : advertiserAccount.marketingPolicyAgreed === false
          ? 0
          : 1
      );

      hideLoading();
    }

    getData();
  }, [advertiserAccount]);

  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>마이페이지</HeaderTitleH2>
      </ContentHeader>

      <MainBody>
        {advertiserAccount !== null && (
          <Frame>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              <ProductPostDivFrame>
                <ProductSelectSectionBtnList>
                  {productSelectSectionBtnData.map((data) => {
                    return (
                      <TabSelectSectionBtn
                        key={data.id}
                        isselected={
                          productSelectSectionBtnSelectedId === data.id
                            ? "true"
                            : "false"
                        }
                        onClick={() => productSelectSectionBtnClick(data.id)}
                      >
                        {data.title}
                      </TabSelectSectionBtn>
                    );
                  })}
                </ProductSelectSectionBtnList>
              </ProductPostDivFrame>
            </div>
            {productSelectSectionBtnSelectedId === 0 && (
              <>
                <>
                  <ProductPostDivTitle>계정이메일 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        disabled
                        value={advertiserAccount.email}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>기존 비밀번호 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        placeholder="기존 비밀번호"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>새로운 비밀번호 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        placeholder="새로운 비밀번호"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>
                    새로운 비밀번호 확인 입력 *
                  </ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        placeholder="새로운 비밀번호 다시 입력"
                        value={newPasswordCheck}
                        onChange={(e) => setNewPasswordCheck(e.target.value)}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <BigRoundBrownBtn
                    onClick={async () => {
                      if (password === "") {
                        alert("기존 비밀번호를 입력해주세요.");
                      } else {
                        if (newPassword === "") {
                          alert("새로운 비밀번호를 입력해주세요.");
                        } else {
                          if (newPasswordCheck === "") {
                            alert("새로운 비밀번호 확인 다시 입력해주세요.");
                          } else {
                            if (newPassword !== newPasswordCheck) {
                              alert(
                                "새로운 비밀번호와 새로운 비밀번호 확인이 일치하지 않습니다."
                              );
                            } else {
                              const response =
                                await postAdvertiserAccountResetPassword(
                                  advertiserAccount.email,
                                  password,
                                  newPassword
                                );

                              if (parseInt(response.status) === 200) {
                                setPassword("");
                                setNewPassword("");
                                setNewPasswordCheck("");
                                alert("비밀번호가 변경되었습니다.");
                              } else if (parseInt(response.status) === 400) {
                                alert("기존 비밀번호가 일치하지 않습니다.");
                              } else {
                                alert("비밀번호 변경에 실패하였습니다.");
                              }
                            }
                          }
                        }
                      }
                    }}
                  >
                    비밀번호 변경하기
                  </BigRoundBrownBtn>
                </div>
                <div
                  style={{
                    height: 20,
                  }}
                ></div>
              </>
            )}
            {productSelectSectionBtnSelectedId === 1 && (
              <>
                <>
                  <ProductPostDivTitle>은행 선택 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostDropdown
                        value={bankTypeId}
                        onChange={handleBankChange}
                      >
                        <option value={0}>은행을 선택해주세요</option>
                        <option value={1}>KB국민은행</option>
                        <option value={2}>신한은행</option>
                        <option value={3}>우리은행</option>
                        <option value={4}>하나은행</option>
                        <option value={5}>카카오뱅크</option>
                        <option value={6}>토스뱅크</option>
                        <option value={7}>NH농협은행</option>
                        <option value={8}>기업은행</option>
                        <option value={9}>케이뱅크</option>
                      </ProductPostDropdown>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>계좌번호 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        placeholder="계좌번호를 입력해주세요."
                        value={bankAccountNumber}
                        onChange={(e) => setBankAccountNumber(e.target.value)}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <BigRoundBrownBtn
                    onClick={async () => {
                      if (bankTypeId === 0) {
                        alert("은행을 선택해주세요.");
                      } else {
                        if (bankAccountNumber === "") {
                          alert("계좌번호를 입력해주세요.");
                        } else {
                          const response = await advertiserAccountBankInfo(
                            bankTypeId,
                            bankAccountNumber
                          );

                          if (parseInt(response.status) === 200) {
                            setAdvertiserAccount({
                              ...advertiserAccount,
                              bankTypeId: bankTypeId,
                              bankAccountNumber: bankAccountNumber,
                            });
                            alert("은행정보가 변경되었습니다.");
                          }
                        }
                      }
                    }}
                  >
                    은행정보 변경하기
                  </BigRoundBrownBtn>
                </div>
                <div
                  style={{
                    height: 20,
                  }}
                ></div>
              </>
            )}
            {productSelectSectionBtnSelectedId === 2 && (
              <>
                <>
                  <ProductPostDivTitle>회원 유형 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        disabled
                        value={
                          businessTypeId === 1 ? "개인사업자" : "법인사업자"
                        }
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>이름 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        value={advertiserName}
                        onChange={(e) => setAdvertiserName(e.target.value)}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>사업자 등록번호 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        placeholder=""
                        disabled
                        value={businessRegistrationNumber}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>
                    {businessTypeId === 1
                      ? "상호(업체명) *"
                      : "법인명(단체명) *"}
                  </ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        placeholder=""
                        disabled
                        value={businessName}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>휴대폰 번호 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostInput
                        placeholder="휴대폰 번호를 입력해주세요"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      ></ProductPostInput>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <>
                  <ProductPostDivTitle>마케팅 수신 동의 *</ProductPostDivTitle>
                  <ProductPostDivFrame>
                    <InputFrame>
                      <ProductPostDropdown
                        value={marketingPolicyAgreed}
                        onChange={handleMarketingPolicyAgreedChange}
                      >
                        <option value={0}>비동의</option>
                        <option value={1}>동의</option>
                      </ProductPostDropdown>
                    </InputFrame>
                  </ProductPostDivFrame>
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <BigRoundBrownBtn
                    onClick={async () => {
                      const response = await advertiserAccountDetailInfo(
                        advertiserName,
                        phoneNumber,
                        marketingPolicyAgreed === 0 ? false : true
                      );

                      if (parseInt(response.status) === 200) {
                        setAdvertiserAccount({
                          ...advertiserAccount,
                          advertiserName: advertiserName,
                          phoneNumber: phoneNumber,
                          marketingPolicyAgreed:
                            marketingPolicyAgreed === 0 ? false : true,
                        });
                        alert("상세정보가 변경되었습니다.");
                      }
                    }}
                  >
                    상세정보 변경하기
                  </BigRoundBrownBtn>
                </div>
                <div
                  style={{
                    height: 20,
                  }}
                ></div>
              </>
            )}
          </Frame>
        )}
      </MainBody>
    </Section>
  );
};

export default MyPage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 93px);
`;

const Frame = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 20px;
`;

const ProductPostDivFrame = styled.div`
  flex-wrap: wrap;
  display: flex;
  line-height: 40px;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ProductPostDivTitle = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

const ProductSelectSectionBtnList = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  box-sizing: border-box;
  height: 46px;
  gap: 0.5rem;
`;

const InputFrame = styled.div`
  background-color: #fff;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 0px 10px;
  line-height: 40px;
`;

const ProductPostInput = styled.input`
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    outline: none;
    cursor: not-allowed;
  }
`;

const ProductPostDropdown = styled.select`
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { ContentHeader } from "../../design_system/components/content_header/ContentHeader";
import PointChargeChargeTab from "./point_charge_charge_tab/PointChargeChargeTab";
import PointChargeChargeHistoryTab from "./point_charge_charge_history_tab/PointChargeChargeHistoryTab";

const PointChargePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const selectTab = (index) => {
    setSelectedTab(index);
  };

  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>도미누스님, 안녕하세요</HeaderTitleH2>
        <HeaderMenuWrap>
          <div
            style={{
              marginRight: 20,
            }}
          >
            <HeaderMenuWrapLi>
              <TabButton
                onClick={() => selectTab(0)}
                isselected={selectedTab === 0 ? "true" : "false"}
              >
                충전하기
              </TabButton>
            </HeaderMenuWrapLi>
          </div>
          <HeaderMenuWrapLi>
            <TabButton
              onClick={() => selectTab(1)}
              isselected={selectedTab === 1 ? "true" : "false"}
            >
              충전내역(결제기준)
            </TabButton>
          </HeaderMenuWrapLi>
        </HeaderMenuWrap>
      </ContentHeader>
      <MainBody>
        {selectedTab === 0 && <PointChargeChargeTab></PointChargeChargeTab>}
        {selectedTab === 1 && (
          <PointChargeChargeHistoryTab></PointChargeChargeHistoryTab>
        )}
      </MainBody>
    </Section>
  );
};

export default PointChargePage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const HeaderMenuWrap = styled.div`
  padding: 20px 0 10px 0;
  display: flex;
  list-style: none;
`;

const HeaderMenuWrapLi = styled.div`
  margin-right: 0;
  height: auto;
  list-style: none;
`;

const TabButton = styled.button`
  background-color: ${(props) =>
    props.isselected === "true" ? "#ddb20b" : "#fff"};
  color: ${(props) => (props.isselected === "true" ? "#fff" : "#000")};
  font-weight: bold;
  width: 180px;
  height: 40px;
  line-height: 40px;
  border-radius: 25px;
  box-shadow: rgba(91, 47, 252, 0.19) 0px 0px 6px;
  text-align: center;
  text-decoration: none;
  display: block;
  cursor: pointer;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 163px);
`;

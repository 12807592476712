import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "../../../design_system/components/pagination/Pagination";
import { numberFormatFunction } from "../../../data_system/data_function/number_format_function";
import { ContentHeader } from "../../../design_system/components/content_header/ContentHeader";
import { SearchBodyHeader } from "../../../design_system/components/search_body_header/SearchBodyHeader";
import { SearchWrapInputFrame } from "../../../design_system/components/search_wrap_input_frame/SearchWrapInputFrame";
import { SearchWrapDropdownFrame } from "../../../design_system/components/search_wrap_dropdown_frame/SearchWrapDropdownFrame";
import { SmallBrownBtn } from "../../../design_system/components/small_brown_btn/SmallBrownBtn";
import { useAdvertiserAccount } from "../../../data_system/context/advertiser_account_context/AdvertiserAccountContext";
import { useLoading } from "../../../data_system/context/loading_context/LoadingContext";
import { useNavigate } from "react-router-dom";
import { getNStoreTrafficPriceComparisonByConditions } from "../../../communication_system/axios_apis/axios_apis";
import { best_point_charge_inner_router_url } from "../../../communication_system/inner_router_url/inner_router_url";
import { ModalHoverShadowWhiteBtn } from "../../../design_system/components/modal_hover_shadow_white_btn/ModalHoverShadowWhiteBtn";
import { ModalHoverShadowGrayBtn } from "../../../design_system/components/modal_hover_shadow_gray_btn/ModalHoverShadowGrayBtn";
import Tooltip from "../../../design_system/components/tooltip/Tooltip";
import { HoverUrlDiv } from "../../../design_system/components/hover_url_div/HoverUrlDiv";
import { dateFormatFunction } from "../../../data_system/data_function/date_format_function";

const NStoreTrafficPriceComparisonPage = () => {
  const { advertiserAccount } = useAdvertiserAccount();
  const { showLoading, hideLoading } = useLoading();

  let navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [todayAdvertisementProgressType, setTodayAdvertisementProgressType] =
    useState("");

  const handleTodayAdvertisementProgressType = (e) => {
    if (e.target.value === "") {
      setTodayAdvertisementProgressType("");
    } else {
      setTodayAdvertisementProgressType(e.target.value);
    }
  };

  const [searchTypeId, setSearchTypeId] = useState("");

  const handleSearchTypeId = (e) => {
    if (e.target.value === "") {
      setSearchTypeId("");
    } else {
      setSearchTypeId(e.target.value);
    }
  };

  const [searchText, setSearchText] = useState("");

  const [
    nStoreTrafficPriceComparisonByConditions,
    setNStoreTrafficPriceComparisonByConditions,
  ] = useState(null);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [
    selectedAdvertiserAdvertisementProductId,
    setSelectedAdvertiserAdvertisementProductId,
  ] = useState(null);

  const [modalNextTargetDailyWorkload, setModalNextTargetDailyWorkload] =
    useState("");

  const [
    modalNextAdvertisementProgressType,
    setModalNextAdvertisementProgressType,
  ] = useState("");

  const handleModalNextAdvertisementProgressType = (e) => {
    if (e.target.value === "") {
      setModalNextAdvertisementProgressType("");
    } else {
      setModalNextAdvertisementProgressType(e.target.value);
    }
  };

  const [showMemoModal, setShowMemoModal] = useState(false);

  const [memo, setMemo] = useState("");

  const [showExcelModal, setShowExcelModal] = useState(false);

  const updateNStoreTrafficPriceComparisonData = (
    selectedAdvertiserAdvertisementProductId, // 업데이트할 요소를 식별하기 위한 ID
    updatedData // 적용할 업데이트 데이터
  ) => {
    setNStoreTrafficPriceComparisonByConditions((prevState) => {
      // 배열을 매핑하여 적절한 요소를 찾고 업데이트
      const updatedNStoreTrafficPriceComparisons =
        prevState.nStoreTrafficPriceComparisons.map((item) => {
          if (
            item.advertiserAdvertisementProductId ===
            selectedAdvertiserAdvertisementProductId
          ) {
            // 해당 요소를 찾았을 경우 업데이트 실행
            return {
              ...item,
              ...updatedData, // 전달된 업데이트 데이터를 통합
            };
          }
          return item; // 다른 요소는 변경 없이 그대로 반환
        });

      return {
        ...prevState,
        nStoreTrafficPriceComparisons: updatedNStoreTrafficPriceComparisons, // 업데이트된 배열을 상태에 반영
      };
    });
  };

  useEffect(() => {
    async function getData() {
      showLoading();

      const response = await getNStoreTrafficPriceComparisonByConditions(
        todayAdvertisementProgressType === ""
          ? null
          : parseInt(todayAdvertisementProgressType),
        searchTypeId === "" ? null : parseInt(searchTypeId),
        searchText,
        startDate,
        endDate,
        page,
        10
      );

      console.log(response);
      setNStoreTrafficPriceComparisonByConditions(
        response.data.nStoreTrafficPriceComparisonByConditions
      );

      hideLoading();
    }

    getData();
  }, [page]);

  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>N스토어 트래픽 - 가격비교</HeaderTitleH2>
        <p
          style={{
            color: "#707070",
            fontSize: "0.9rem",
          }}
        >
          ※ 3개월간 실행이력이 없는 상품은 삭제됩니다.
        </p>
      </ContentHeader>
      <MainBody>
        <SearchBodyHeader>
          <SearchWrap>
            <SearchWrapDropdownFrame>
              <SearchSelectOptionDropdown
                onChange={handleTodayAdvertisementProgressType}
                value={todayAdvertisementProgressType}
              >
                <option value={""}>광고 실행 상태</option>
                <option value={1}>실행</option>
                <option value={2}>중단</option>
              </SearchSelectOptionDropdown>
            </SearchWrapDropdownFrame>
            <SearchWrapDropdownFrame>
              <SearchSelectOptionDropdown
                onChange={handleSearchTypeId}
                value={searchTypeId}
              >
                <option value={""}>검색항목</option>
                <option value={1}>스토어명</option>
              </SearchSelectOptionDropdown>
            </SearchWrapDropdownFrame>
            <SearchWrapInputFrame>
              <SearchWrapInput
                type="text"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              ></SearchWrapInput>
            </SearchWrapInputFrame>
            <SearchWrapInputFrame>
              <SearchWrapInput
                type="date"
                value={startDate === null ? "" : startDate}
                onChange={(e) => {
                  if (e.target.value > endDate) {
                    alert("시작일은 종료일 이전의 날짜만 선택 가능합니다.");
                    setStartDate(endDate);
                  } else {
                    setStartDate(e.target.value);
                  }
                }}
              ></SearchWrapInput>
            </SearchWrapInputFrame>
            <SearchWrapInputFrame>
              <SearchWrapInput
                type="date"
                value={endDate === null ? "" : endDate}
                onChange={(e) => {
                  if (e.target.value < startDate) {
                    alert("종료일은 시작일 이후의 날짜만 선택 가능합니다.");
                    setEndDate(startDate);
                  } else {
                    setEndDate(e.target.value);
                  }
                }}
              ></SearchWrapInput>
            </SearchWrapInputFrame>
            <SearchWrapFlexLi>
              <SmallBrownBtn
                onClick={async () => {
                  showLoading();

                  const response =
                    await getNStoreTrafficPriceComparisonByConditions(
                      todayAdvertisementProgressType === ""
                        ? null
                        : parseInt(todayAdvertisementProgressType),
                      searchTypeId === "" ? null : parseInt(searchTypeId),
                      searchText,
                      startDate,
                      endDate,
                      page,
                      10
                    );

                  console.log(response);
                  setNStoreTrafficPriceComparisonByConditions(
                    response.data.nStoreTrafficPriceComparisonByConditions
                  );

                  hideLoading();
                }}
              >
                검색
              </SmallBrownBtn>
            </SearchWrapFlexLi>
          </SearchWrap>
        </SearchBodyHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              border: "1px solid #000",
              boxShadow: "0 2px 6px #0B44A530",
              display: "block",
              padding: "7px 10px",
              borderRadius: "5px",
              backgroundColor: "#fff",
              fontWeight: "bold",
              textDecoration: "none",
              color: "#222",
              height: "100%",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(best_point_charge_inner_router_url);
            }}
          >
            <span
              style={{
                fontSize: "1.1rem",
                marginRight: "10px",
              }}
            >
              보유 포인트
            </span>
            <span
              style={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.1rem",
              }}
            >
              {advertiserAccount === null
                ? 0
                : numberFormatFunction(advertiserAccount.leftPoint)}
            </span>
          </div>
        </div>
        <div
          style={{
            height: "auto",
            backgroundColor: "#fff",
            marginBottom: "20px",
            padding: "20px",
            border: "1px solid #d3dbec",
          }}
        >
          <TableFrame>
            <thead>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableTitleDarkGray>등록일</TableTitleDarkGray>
                <TableTitleDarkGray>스토어명</TableTitleDarkGray>
                <TableTitleDarkGray>유저 검색 키워드</TableTitleDarkGray>
                <TableTitleDarkGray>원부 상품 MID</TableTitleDarkGray>
                <TableTitleDarkGray>최종 도착 상품 MID</TableTitleDarkGray>
                <TableTitleDarkGray>상품 ID</TableTitleDarkGray>
                <TableTitleRed>총 작업량</TableTitleRed>
                <TableTitleRed>오늘 목표 작업량</TableTitleRed>
                <TableTitleRed>오늘 달성 작업량</TableTitleRed>
                <TableTitleRed>내일 목표 작업량</TableTitleRed>
                <TableTitleDarkGray>오늘 실행 상태</TableTitleDarkGray>
                <TableTitleDarkGray>내일 실행 상태</TableTitleDarkGray>
                <TableTitleDarkGray colSpan={2}>설정</TableTitleDarkGray>
                <TableTitleDarkGray colSpan={2}>내역</TableTitleDarkGray>
                <TableTitleDarkGray colSpan={2}>메모</TableTitleDarkGray>
              </tr>
            </thead>
            {nStoreTrafficPriceComparisonByConditions !== null && (
              <tbody>
                {nStoreTrafficPriceComparisonByConditions
                  .nStoreTrafficPriceComparisons.length === 0 ? (
                  <tr
                    style={{
                      backgroundColor: "#fff",
                      display: "table-row",
                      verticalAlign: "inherit",
                    }}
                  >
                    <TableCellNormal colSpan={18}>
                      자료가 없습니다.
                    </TableCellNormal>
                  </tr>
                ) : (
                  nStoreTrafficPriceComparisonByConditions.nStoreTrafficPriceComparisons.map(
                    (nStoreTrafficPriceComparison) => {
                      return (
                        <tr
                          key={nStoreTrafficPriceComparison.id}
                          style={{
                            backgroundColor: "#fff",
                            display: "table-row",
                            verticalAlign: "inherit",
                          }}
                        >
                          <TableCellNormal>
                            {dateFormatFunction(
                              new Date(nStoreTrafficPriceComparison.createdAt)
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {nStoreTrafficPriceComparison.storeName}
                          </TableCellNormal>
                          <TableCellNormal>
                            {nStoreTrafficPriceComparison.userSearchKeyword}
                          </TableCellNormal>
                          <TableCellNormal>
                            {/* <Tooltip
                              text={nStoreTrafficPriceComparison.entryUrl}
                            >
                              <HoverUrlDiv
                                onClick={() => {
                                  window.open(
                                    nStoreTrafficPriceComparison.entryUrl,
                                    "_blank"
                                  );
                                }}
                              >
                                링크
                              </HoverUrlDiv>
                            </Tooltip> */}
                            {nStoreTrafficPriceComparison.entryMid}
                          </TableCellNormal>
                          <TableCellNormal>
                            {/* <Tooltip
                              text={nStoreTrafficPriceComparison.arrivalUrl}
                            >
                              <HoverUrlDiv
                                onClick={() => {
                                  window.open(
                                    nStoreTrafficPriceComparison.arrivalUrl,
                                    "_blank"
                                  );
                                }}
                              >
                                링크
                              </HoverUrlDiv>
                            </Tooltip> */}
                            {nStoreTrafficPriceComparison.arrivalMid}
                          </TableCellNormal>
                          <TableCellNormal>
                            {nStoreTrafficPriceComparison.productId}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              nStoreTrafficPriceComparison.totalWorkload
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              nStoreTrafficPriceComparison.todayTargetDailyWorkload
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              nStoreTrafficPriceComparison.todayCompleteDailyWorkload
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              nStoreTrafficPriceComparison.nextTargetDailyWorkload
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {nStoreTrafficPriceComparison.todayAdvertisementProgressType ===
                            1
                              ? "실행"
                              : "중단"}
                          </TableCellNormal>
                          <TableCellNormal>
                            {nStoreTrafficPriceComparison.nextAdvertisementProgressType ===
                            1
                              ? "실행"
                              : "중단"}
                          </TableCellNormal>
                          <TableCellNormal colSpan={2}>
                            <ModalHoverShadowWhiteBtn
                              onClick={() => {
                                setSelectedAdvertiserAdvertisementProductId(
                                  nStoreTrafficPriceComparison.advertiserAdvertisementProductId
                                );
                                setModalNextTargetDailyWorkload(
                                  nStoreTrafficPriceComparison.nextTargetDailyWorkload
                                );
                                setModalNextAdvertisementProgressType(
                                  nStoreTrafficPriceComparison.nextAdvertisementProgressType
                                );
                                setShowUpdateModal(true);
                              }}
                            >
                              설정
                            </ModalHoverShadowWhiteBtn>
                          </TableCellNormal>
                          <TableCellNormal colSpan={2}>
                            <ModalHoverShadowGrayBtn
                              onClick={() => {
                                setShowExcelModal(true);
                              }}
                            >
                              엑셀 다운로드
                            </ModalHoverShadowGrayBtn>
                          </TableCellNormal>
                          <TableCellNormal colSpan={2}>
                            <ModalHoverShadowWhiteBtn
                              onClick={() => {
                                setSelectedAdvertiserAdvertisementProductId(
                                  nStoreTrafficPriceComparison.advertiserAdvertisementProductId
                                );
                                setMemo(nStoreTrafficPriceComparison.memo);
                                setShowMemoModal(true);
                              }}
                            >
                              메모
                            </ModalHoverShadowWhiteBtn>
                          </TableCellNormal>
                        </tr>
                      );
                    }
                  )
                )}
              </tbody>
            )}
          </TableFrame>
          <Pagination
            totalPages={99}
            limit={10}
            page={page}
            setPage={setPage}
          ></Pagination>
        </div>
      </MainBody>
    </Section>
  );
};

export default NStoreTrafficPriceComparisonPage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 182px);
`;

const SearchWrap = styled.ul`
  justify-content: end;
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
`;

const SearchSelectOptionDropdown = styled.select`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const SearchWrapFlexLi = styled.li`
  display: flex;
  background-color: #f9fafe;
  border-radius: 5px;
  border: 1px solid #d3dbec;
  margin-right: 10px;
  overflow: hidden;
  height: 40px;
  list-style: none;
`;

const TableFrame = styled.table`
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d3dbec;
  table-layout: fixed;
  font-size: 0.85rem;
  display: table;
  text-indent: initial;
  border-spacing: 2px;
`;

const TableTitleDarkGray = styled.th`
  background-color: #454d55;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableTitleRed = styled.th`
  background-color: #e84e4e;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableCellNormal = styled.td`
  white-space: normal;
  word-break: break-all;
  border: 0.5px solid #dee2e6;
  padding: 5px;
  text-align: center;
  line-height: 150%;
`;

const SearchWrapInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

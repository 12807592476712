import React from "react";
import styled, { keyframes } from "styled-components";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 9999;
`;

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top-color: #ddb20b;
  animation: ${spinnerAnimation} 1s linear infinite;
`;

export default function LoadingIndicator() {
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  );
}

import axios from "axios";
import { googleMapsApiKey } from "./communication_system/communication_keys/communication_keys";
import { AuthProvider } from "./data_system/context/auth_context/AuthContext";
import { GoogleMapsProvider } from "./data_system/context/google_maps_context/GoogleMapsContext";
import { LoadingProvider } from "./data_system/context/loading_context/LoadingContext";
import { PopupProvider } from "./data_system/context/popup_context/PopupContext";
import Popup from "./design_system/components/popup/Popup";
import TotalRouter from "./router/TotalRouter";
import { AdvertiserAccountProvider } from "./data_system/context/advertiser_account_context/AdvertiserAccountContext";
import { AdministratorAdvertisementProductProvider } from "./data_system/context/administrator_advertisement_product_context/AdministratorAdvertisementProductContext";

const App = () => {
  axios.defaults.withCredentials = true;

  return (
    <GoogleMapsProvider googleMapsApiKey={googleMapsApiKey}>
      <AuthProvider>
        <AdvertiserAccountProvider>
          <AdministratorAdvertisementProductProvider>
            <LoadingProvider>
              <PopupProvider>
                <TotalRouter></TotalRouter>
                <Popup></Popup>
              </PopupProvider>
            </LoadingProvider>
          </AdministratorAdvertisementProductProvider>
        </AdvertiserAccountProvider>
      </AuthProvider>
    </GoogleMapsProvider>
  );
};

export default App;

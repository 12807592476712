export function dateFormatFunction(date = new Date()) {
  if (!(date instanceof Date)) {
    console.error("Provided value is not a Date object:", date);
    date = new Date();
  }

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 월은 0부터 시작하기 때문에 1을 더해줍니다.
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}.${month}.${day}`;
}

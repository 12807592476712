import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../data_system/context/auth_context/AuthContext";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import {
  best_home_inner_router_url,
  best_my_page_inner_router_url,
  best_point_charge_inner_router_url,
  best_point_history_inner_router_url,
  login_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import styled from "styled-components";
import { sidebar_data } from "../../data_system/data/sidebar_data/sidebar_data";
import { FaStore } from "../../design_system/icons/icons";
import { numberFormatFunction } from "../../data_system/data_function/number_format_function";
import { useAdvertiserAccount } from "../../data_system/context/advertiser_account_context/AdvertiserAccountContext";

export function PrivateRoute() {
  const { advertiserAccount } = useAdvertiserAccount();
  const { isLoggedIn, logout } = useAuth();

  let navigate = useNavigate();
  let location = useLocation();

  const [sidebarData, setSidebarData] = useState(sidebar_data);

  const handleMainClick = (mainId) => {
    const newSidebarData = sidebarData.map((main) => {
      const isCurrentlyActive = main.mainId === mainId && main.is_active === 1;
      return {
        ...main,
        is_active: isCurrentlyActive ? 0 : main.mainId === mainId ? 1 : 0,
        subTitleList: isCurrentlyActive
          ? main.subTitleList
          : main.subTitleList.map((sub) => ({ ...sub, is_active: 0 })),
      };
    });
    setSidebarData(newSidebarData);
  };

  const handleSubClick = (mainId, subId) => {
    const newSidebarData = sidebarData.map((main) => ({
      ...main,
      subTitleList: main.subTitleList.map((sub) => ({
        ...sub,
        is_active: main.mainId === mainId && sub.subId === subId ? 1 : 0,
      })),
    }));
    setSidebarData(newSidebarData);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(login_inner_router_url, { state: { from: location.pathname } });
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <MainWrapper>
      <SidePanel>
        <SidePanelHeader>
          <SidePanelHeaderH1>moneymoa</SidePanelHeaderH1>
        </SidePanelHeader>
        <SidePanelNav>
          <SidePanelNavUlEmailArea>
            <SidePanelNavUlEmailAreaLi1>도미누스</SidePanelNavUlEmailAreaLi1>
          </SidePanelNavUlEmailArea>
          <SidePanelNavUlLoginArea>
            <SidePanelNavUlLoginAreaLiMenu
              onClick={() => {
                navigate(best_home_inner_router_url);
              }}
            >
              홈
            </SidePanelNavUlLoginAreaLiMenu>
            <SidePanelNavUlLoginAreaLi>|</SidePanelNavUlLoginAreaLi>
            <SidePanelNavUlLoginAreaLiMenu
              onClick={() => {
                navigate(best_my_page_inner_router_url);
              }}
            >
              마이페이지
            </SidePanelNavUlLoginAreaLiMenu>
            <SidePanelNavUlLoginAreaLi>|</SidePanelNavUlLoginAreaLi>
            <SidePanelNavUlLoginAreaLiMenu
              onClick={() => {
                logout();
              }}
            >
              로그아웃
            </SidePanelNavUlLoginAreaLiMenu>
          </SidePanelNavUlLoginArea>
          <SidePanelNavUlRechargeArea>
            <SidePanelNavUlRechargeAreaA1>
              <SidePanelNavUlRechargeAreaABtn1
                onClick={() => {
                  navigate(best_point_charge_inner_router_url);
                }}
              >
                포인트 충전
              </SidePanelNavUlRechargeAreaABtn1>
            </SidePanelNavUlRechargeAreaA1>
            <SidePanelNavUlRechargeAreaA2>
              <SidePanelNavUlRechargeAreaABtn2
                onClick={() => {
                  navigate(best_point_history_inner_router_url);
                }}
              >
                보유 포인트{" "}
                {advertiserAccount === null
                  ? 0
                  : numberFormatFunction(advertiserAccount.leftPoint)}
              </SidePanelNavUlRechargeAreaABtn2>
            </SidePanelNavUlRechargeAreaA2>
          </SidePanelNavUlRechargeArea>
          <SidePanelNavUlMenuArea>
            {sidebarData.map((main) => (
              <SidePanelNavUlMenuAreaLi
                key={main.mainId}
                onClick={(e) => {
                  handleMainClick(main.mainId);
                  if (main.router_url !== null) {
                    navigate(main.router_url);
                  }
                }}
                style={{
                  backgroundColor: main.is_active === 1 ? "#ddb20b" : "#fff",
                  fontWeight: main.is_active === 1 ? "bold" : "normal",
                }}
              >
                <SidePanelNavUlMenuAreaLiA
                  style={{
                    color: main.is_active === 1 ? "#fff" : "#000",
                  }}
                >
                  <div
                    style={{
                      marginRight: "10px",
                      color: "#353535",
                      width: "18px",
                      textAlign: "center",
                    }}
                  >
                    {main.mainIcon}
                  </div>

                  {main.mainTitle}
                </SidePanelNavUlMenuAreaLiA>
                {main.is_active === 1 && (
                  <SidePanelNavUlMenuAreaLiUl>
                    {main.subTitleList.map((sub) => (
                      <SidePanelNavUlMenuAreaLiUlLi
                        key={sub.subId}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSubClick(main.mainId, sub.subId);
                          navigate(sub.router_url);
                        }}
                      >
                        <SidePanelNavUlMenuAreaLiUlLiA>
                          <div
                            style={{
                              marginRight: "8px",
                              width: "12px",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {sub.subIcon}
                          </div>
                          <div
                            style={{
                              fontWeight:
                                sub.is_active === 1 ? "bold" : "normal",
                            }}
                          >
                            {sub.name}
                          </div>
                        </SidePanelNavUlMenuAreaLiUlLiA>
                      </SidePanelNavUlMenuAreaLiUlLi>
                    ))}
                  </SidePanelNavUlMenuAreaLiUl>
                )}
              </SidePanelNavUlMenuAreaLi>
            ))}
          </SidePanelNavUlMenuArea>
        </SidePanelNav>
      </SidePanel>
      <Outlet />
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  background-color: rgba(221, 178, 11, 0.1);
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  backdrop-filter: blur(20px);
  padding: 10px;
`;

const SidePanel = styled.div`
  width: 300px;
  margin-right: 10px;
  height: 100%;
  box-shadow: 0 0 10px #00000030;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

const SidePanelHeader = styled.header`
  width: 100%;
  height: 92px;
  background-color: #fff;
  text-align: center;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SidePanelHeaderH1 = styled.h1`
  width: 100%;
`;

const SidePanelNav = styled.nav`
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  overflow-y: hidden;
`;

const SidePanelNavUlEmailArea = styled.ul`
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px 10px 10px;
  margin: 5px 10px 0 10px;
  background-color: #ddb20b;
  border-radius: 10px 10px 0 0;
  list-style: none;
`;

const SidePanelNavUlEmailAreaLi1 = styled.li`
  margin-bottom: 5px;
  list-style: none;
  color: #fff;
  font-size: 1rem;
`;

const SidePanelNavUlLoginArea = styled.ul`
  width: calc(100% - 20px);
  text-align: center;
  color: #cdcdcd;
  background-color: #353535;
  margin: 0 10px 10px 10px;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
`;

const SidePanelNavUlLoginAreaLiMenu = styled.li`
  padding: 15px;
  list-style: none;
  cursor: pointer;
  color: #fff;
  font-size: 13px;

  &:hover {
    font-weight: 700;
  }
`;

const SidePanelNavUlLoginAreaLi = styled.li`
  list-style: none;
`;

const SidePanelNavUlRechargeArea = styled.ul`
  display: block;
`;

const SidePanelNavUlRechargeAreaA1 = styled.a`
  margin: 20px 10px;
  text-decoration: none;
  color: #222;
  display: block;
  height: 100%;
  cursor: pointer;
`;

const SidePanelNavUlRechargeAreaA2 = styled.a`
  margin: 20px 10px;
  text-decoration: none;
  color: #222;
  display: block;
  height: 100%;
  cursor: pointer;
`;

const SidePanelNavUlRechargeAreaABtn1 = styled.button`
  border-radius: 50px;
  width: 100%;
  height: 40px;
  box-shadow: 0 3px 6px #cdcdcd;
  font-weight: bold;
  padding: 10px;
  font-size: 0.938rem;
  background-color: #fff;
  border: 3px solid rgba(255, 225, 0, 1);
  color: #000;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 225, 0, 1);
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
  }
`;

const SidePanelNavUlRechargeAreaABtn2 = styled.button`
  border-radius: 50px;
  width: 100%;
  height: 40px;
  box-shadow: 0 3px 6px #cdcdcd;
  font-weight: bold;
  padding: 10px;
  font-size: 0.938rem;
  background-color: #fff;
  border: 1px solid rgba(221, 178, 11, 0.3);
  color: #000;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background-color: rgba(221, 178, 11, 1);
    color: #fff;
  }
`;

const SidePanelNavUlMenuArea = styled.ul`
  width: 100%;
  padding: 0 10px;
  height: calc(100% - 243px);
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
`;

const SidePanelNavUlMenuAreaLi = styled.li`
  border-radius: 10px;
  list-style: none;
`;

const SidePanelNavUlMenuAreaLiA = styled.a`
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0px 15px;
  text-decoration: none;
  color: #222;
  display: flex;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

const SidePanelNavUlMenuAreaLiUl = styled.ul`
  display: block;
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  font-weight: normal;
  background-color: #353535;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
  list-style: none;
`;

const SidePanelNavUlMenuAreaLiUlLi = styled.li`
  border-radius: 0;
  text-align: left;
  list-style: none;
`;
const SidePanelNavUlMenuAreaLiUlLiA = styled.a`
  padding: 0 0 0 30px;
  height: auto;
  line-height: 50px;
  width: 100%;
  text-decoration: none;
  color: #fff;
  display: flex;
  cursor: pointer;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ContentHeader } from "../../design_system/components/content_header/ContentHeader";
import { useLoading } from "../../data_system/context/loading_context/LoadingContext";
import { getAdvertiserHomeInfo } from "../../communication_system/axios_apis/axios_apis";

const HomePage = () => {
  const { showLoading, hideLoading } = useLoading();

  const [advertiserHomeInfo, setAdvertiserHomeInfo] = useState(null);
  const [totalSum, setTotalSum] = useState(null);

  const calculateTotalSum = (advertiserHomeInfo) => {
    const totalSum = {
      totalWorkload: 0,
      todayTargetDailyWorkload: 0,
      todayCompleteDailyWorkload: 0,
      numberAdvertisementProducts: 0,
    };

    advertiserHomeInfo.forEach((info) => {
      const { rows, numberAdvertisementProducts } = info;

      rows.forEach((row) => {
        totalSum.totalWorkload +=
          row.totalWorkload === undefined || row.totalWorkload === null
            ? 0
            : parseInt(row.totalWorkload);
        totalSum.todayTargetDailyWorkload +=
          row.todayTargetDailyWorkload === undefined ||
          row.todayTargetDailyWorkload === null
            ? 0
            : parseInt(row.todayTargetDailyWorkload);
        totalSum.todayCompleteDailyWorkload +=
          row.todayCompleteDailyWorkload === undefined ||
          row.todayCompleteDailyWorkload === null
            ? 0
            : parseInt(row.todayCompleteDailyWorkload);
      });

      totalSum.numberAdvertisementProducts += parseInt(
        numberAdvertisementProducts
      );
    });

    return totalSum;
  };

  useEffect(() => {
    async function getData() {
      showLoading();
      const response = await getAdvertiserHomeInfo();
      console.log(response);

      setAdvertiserHomeInfo(response.data.advertiserHomeInfo);
      setTotalSum(calculateTotalSum(response.data.advertiserHomeInfo));

      hideLoading();
    }

    getData();
  }, []);

  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>
          머니모아 - <HeaderTitleH2Span>홈</HeaderTitleH2Span>
        </HeaderTitleH2>
      </ContentHeader>
      <MainBody>
        <SubTitle>상품 진행 상황</SubTitle>
        {advertiserHomeInfo !== null && (
          <TableFrame>
            <thead>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableTitleDarkGray>상품</TableTitleDarkGray>
                <TableTitleGray>진행중 광고수</TableTitleGray>
                <TableTitleGray>총 작업량</TableTitleGray>
                <TableTitleGray>오늘 총 목표 작업량</TableTitleGray>
                <TableTitleGray>오늘 총 달성 작업량</TableTitleGray>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              ></tr>
            </thead>
            <tbody>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N스토어 트래픽 - 단독</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[0].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[0].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[0].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[0].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[0].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[0].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[0].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N스토어 트래픽 - 가격비교</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[1].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[1].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[1].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[1].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[1].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[1].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[1].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N스토어 알림</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[2].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[2].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[2].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[2].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[2].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[2].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[2].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N스토어 상품 찜</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[3].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[3].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[3].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[3].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[3].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[3].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[3].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N플레이스 트래픽</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[4].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[4].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[4].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[4].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[4].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[4].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[4].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N플레이스 저장</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[5].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[5].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[5].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[5].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[5].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[5].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[5].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N플레이스 알림</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[6].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[6].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[6].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[6].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[6].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[6].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[6].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N플레이스 지도 찜</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[7].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[7].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[7].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[7].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[7].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[7].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[7].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N플레이스 블로그 공유</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[8].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[8].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[8].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[8].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[8].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[8].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[8].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N플레이스 Keep 공유</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[9].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[9].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[9].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[9].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[9].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[9].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[9].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>N플레이스 블로그 리뷰</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[10].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[10].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[10].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[10].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[10].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[10].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[10].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>카카오 선물 위시</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[11].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[11].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[11].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[11].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[11].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[11].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[11].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>카카오 선물 후기 좋아요</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[12].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[12].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[12].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[12].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[12].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[12].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[12].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>카카오 맵 저장</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[13].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[13].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[13].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[13].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[13].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[13].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[13].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>구글 맵 저장</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[14].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[14].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[14].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[14].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[14].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[14].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[14].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>구글 맵 리뷰</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[15].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[15].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[15].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[15].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[15].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[15].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[15].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>인스타 팔로우</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[16].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[16].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[16].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[16].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[16].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[16].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[16].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>인스타 좋아요</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[17].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[17].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[17].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[17].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[17].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[17].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[17].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>인스타 저장</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[18].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[18].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[18].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[18].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[18].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[18].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[18].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>유튜브 구독</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[19].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[19].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[19].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[19].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[19].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[19].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[19].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>유튜브 좋아요</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[20].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[20].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[20].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[20].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[20].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[20].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[20].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>배민 찜</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[21].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[21].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[21].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[21].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[21].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[21].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[21].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableCellNormal>배민 클럽[원] 찜</TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[22].numberAdvertisementProducts}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[22].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[22].rows[0].totalWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[22].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[22].rows[0].todayTargetDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
                <TableCellNormal>
                  <TableCellNormalDiv>
                    {advertiserHomeInfo[22].rows.length === 0
                      ? 0
                      : advertiserHomeInfo[22].rows[0]
                          .todayCompleteDailyWorkload}
                  </TableCellNormalDiv>
                </TableCellNormal>
              </tr>
              <TableResultFrame>
                <TableResultCellDarkGray>합계</TableResultCellDarkGray>
                <TableResultCellWhiteblue>
                  <TableResultCellDiv>
                    {totalSum.numberAdvertisementProducts}
                  </TableResultCellDiv>
                </TableResultCellWhiteblue>
                <TableResultCellWhiteblue>
                  <TableResultCellDiv>
                    {totalSum.totalWorkload}
                  </TableResultCellDiv>
                </TableResultCellWhiteblue>
                <TableResultCellWhiteblue>
                  <TableResultCellDiv>
                    {totalSum.todayTargetDailyWorkload}
                  </TableResultCellDiv>
                </TableResultCellWhiteblue>
                <TableResultCellWhiteblue>
                  <TableResultCellDiv>
                    {totalSum.todayCompleteDailyWorkload}
                  </TableResultCellDiv>
                </TableResultCellWhiteblue>
              </TableResultFrame>
            </tbody>
          </TableFrame>
        )}
      </MainBody>
    </Section>
  );
};

export default HomePage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const HeaderTitleH2Span = styled.span`
  color: #ddb20b;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 92px);
`;

const SubTitle = styled.div`
  font-size: 1.1rem;
  padding: 10px;
  text-align: center;
  background-color: #d3dbec80;
  color: #404040;
  font-weight: bold;
  margin-bottom: 10px;
`;

const TableFrame = styled.table`
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d3dbec;
  table-layout: fixed;
  font-size: 0.85rem;
  display: table;
  text-indent: initial;
  border-spacing: 2px;
`;

const TableTitleDarkGray = styled.th`
  background-color: #454d55;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableTitleGray = styled.th`
  background-color: rgb(126, 133, 139);
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableCellNormal = styled.td`
  white-space: normal;
  word-break: break-all;
  border: 0.5px solid #dee2e6;
  padding: 5px;
  text-align: center;
  line-height: 150%;
`;

const TableCellNormalDiv = styled.div`
  color: #222;
  height: 100%;
`;

const TableResultFrame = styled.tr`
  font-size: 0.9rem;
  font-weight: bold;
  background-color: #d3dbec50;
`;

const TableResultCellDarkGray = styled.td`
  background-color: #454d55;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0.5px solid #dee2e6;
  padding: 5px;
  text-align: center;
  line-height: 150%;
`;

const TableResultCellWhiteblue = styled.td`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0.5px solid #dee2e6;
  padding: 5px;
  text-align: center;
  line-height: 150%;
`;

const TableResultCellDiv = styled.div`
  color: #222;
  height: 100%;
`;

export const sign_up_inner_router_url = "/sign-up";
export const login_inner_router_url = "/login";
export const terms_and_conditions_inner_router_url = "/terms-and-conditions";
export const privacy_policy_inner_router_url = "/privacy-policy";
export const refund_policy_inner_router_url = "/refund-policy";
export const marketing_policy_inner_router_url = "/marketing-policy";
export const position_policy_inner_router_url = "/position-policy";

export const best_all_inner_router_url = "/best/*";
export const home_inner_router_url = "home";
export const best_home_inner_router_url = "/best/home";
export const point_history_inner_router_url = "point-history";
export const best_point_history_inner_router_url = "/best/point-history";
export const point_charge_inner_router_url = "point-charge";
export const best_point_charge_inner_router_url = "/best/point-charge";

//n store

//n store product characteristic
export const n_store_product_characteristic_inner_router_url =
  "n-store-product-characteristic";
export const best_n_store_product_characteristic_inner_router_url =
  "/best/n-store-product-characteristic";

//n store product post
export const n_store_product_post_inner_router_url = "n-store-product-post";
export const best_n_store_product_post_inner_router_url =
  "/best/n-store-product-post";

//n store traffic single
export const n_store_traffic_single_inner_router_url = "n-store-traffic-single";
export const best_n_store_traffic_single_inner_router_url =
  "/best/n-store-traffic-single";

//n store traffic price comparison
export const n_store_traffic_price_comparison_inner_router_url =
  "n-store-traffic-price-comparison";
export const best_n_store_traffic_price_comparison_inner_router_url =
  "/best/n-store-traffic-price-comparison";

//n store alarm receive
export const n_store_alarm_inner_router_url = "n-store-alarm";
export const best_n_store_alarm_inner_router_url = "/best/n-store-alarm";

//n store product jjim
export const n_store_product_jjim_inner_router_url = "n-store-product-jjim";
export const best_n_store_product_jjim_inner_router_url =
  "/best/n-store-product-jjim";

// n store total search
export const n_store_total_search_inner_router_url = "n-store-total-search";
export const best_n_store_total_search_inner_router_url =
  "/best/n-store-total-search";

//n place

//n place product characteristic
export const n_place_product_characteristic_inner_router_url =
  "n-place-product-characteristic";
export const best_n_place_product_characteristic_inner_router_url =
  "/best/n-place-product-characteristic";

//n place product post
export const n_place_product_post_inner_router_url = "n-place-product-post";
export const best_n_place_product_post_inner_router_url =
  "/best/n-place-product-post";

//n place traffic
export const n_place_traffic_inner_router_url = "n-place-traffic";
export const best_n_place_traffic_inner_router_url = "/best/n-place-traffic";

//n place save
export const n_place_save_inner_router_url = "n-place-save";
export const best_n_place_save_inner_router_url = "/best/n-place-save";

//n place alarm
export const n_place_alarm_inner_router_url = "n-place-alarm";
export const best_n_place_alarm_inner_router_url = "/best/n-place-alarm";

//n place map jjim
export const n_place_map_jjim_inner_router_url = "n-place-map-jjim";
export const best_n_place_map_jjim_inner_router_url = "/best/n-place-map-jjim";

//n place blog share
export const n_place_blog_share_inner_router_url = "n-place-blog-share";
export const best_n_place_blog_share_inner_router_url =
  "/best/n-place-blog-share";

//n place keep share
export const n_place_keep_share_inner_router_url = "n-place-keep-share";
export const best_n_place_keep_share_inner_router_url =
  "/best/n-place-keep-share";

//n place receipt review
export const n_place_receipt_review_inner_router_url = "n-place-receipt-review";
export const best_n_place_receipt_review_inner_router_url =
  "/best/n-place-receipt-review";

//n place blog review
export const n_place_blog_review_inner_router_url = "n-place-blog-review";
export const best_n_place_blog_review_inner_router_url =
  "/best/n-place-blog-review";

//n place total search
export const n_place_total_search_inner_router_url = "n-place-total-search";
export const best_n_place_total_search_inner_router_url =
  "/best/n-place-total-search";

//kakao

//kakao product characteristic
export const kakao_product_characteristic_inner_router_url =
  "kakao-product-characteristic";
export const best_kakao_product_characteristic_inner_router_url =
  "/best/kakao-product-characteristic";

//kakao product post
export const kakao_product_post_inner_router_url = "kakao-product-post";
export const best_kakao_product_post_inner_router_url =
  "/best/kakao-product-post";

//kakao present wish
export const kakao_present_wish_inner_router_url = "kakao-present-wish";
export const best_kakao_present_wish_inner_router_url =
  "/best/kakao-present-wish";

//kakao present review like
export const kakao_present_review_like_inner_router_url =
  "kakao-present-review-like";
export const best_kakao_present_review_like_inner_router_url =
  "/best/kakao-present-review-like";

//kakao map save
export const kakao_map_save_inner_router_url = "kakao-map-save";
export const best_kakao_map_save_inner_router_url = "/best/kakao-map-save";

//kakao total search
export const kakao_total_search_inner_router_url = "kakao-total-search";
export const best_kakao_total_search_inner_router_url =
  "/best/kakao-total-search";

//google

//google product characteristic
export const google_product_characteristic_inner_router_url =
  "google-product-characteristic";
export const best_google_product_characteristic_inner_router_url =
  "/best/google-product-characteristic";

//google product post
export const google_product_post_inner_router_url = "google-product-post";
export const best_google_product_post_inner_router_url =
  "/best/google-product-post";

//google map save
export const google_map_save_inner_router_url = "google-map-save";
export const best_google_map_save_inner_router_url = "/best/google-map-save";

//google map review
export const google_map_review_inner_router_url = "google-map-review";
export const best_google_map_review_inner_router_url =
  "/best/google-map-review";

//google total search
export const google_total_search_inner_router_url = "google-total-search";
export const best_google_total_search_inner_router_url =
  "/best/google-total-search";

//insta

//insta product characteristic
export const insta_product_characteristic_inner_router_url =
  "insta-product-characteristic";
export const best_insta_product_characteristic_inner_router_url =
  "/best/insta-product-characteristic";

//insta product post
export const insta_product_post_inner_router_url = "insta-product-post";
export const best_insta_product_post_inner_router_url =
  "/best/insta-product-post";

//insta follow
export const insta_follow_inner_router_url = "insta-follow";
export const best_insta_follow_inner_router_url = "/best/insta-follow";

//insta like
export const insta_like_inner_router_url = "insta-like";
export const best_insta_like_inner_router_url = "/best/insta-like";

//insta save
export const insta_save_inner_router_url = "insta-save";
export const best_insta_save_inner_router_url = "/best/insta-save";

//insta total search
export const insta_total_search_inner_router_url = "insta-total-search";
export const best_insta_total_search_inner_router_url =
  "/best/insta-total-search";

//youtube

//youtube product characteristic
export const youtube_product_characteristic_inner_router_url =
  "youtube-product-characteristic";
export const best_youtube_product_characteristic_inner_router_url =
  "/best/youtube-product-characteristic";

//youtube product post
export const youtube_product_post_inner_router_url = "youtube-product-post";
export const best_youtube_product_post_inner_router_url =
  "/best/youtube-product-post";

//youtube subscribe
export const youtube_subscribe_inner_router_url = "youtube-subscribe";
export const best_youtube_subscribe_inner_router_url =
  "/best/youtube-subscribe";

//youtube like
export const youtube_like_inner_router_url = "youtube-like";
export const best_youtube_like_inner_router_url = "/best/youtube-like";

//youtube total search
export const youtube_total_search_inner_router_url = "youtube-total-search";
export const best_youtube_total_search_inner_router_url =
  "/best/youtube-total-search";

//baemin

//baemin product characteristic
export const baemin_product_characteristic_inner_router_url =
  "baemin-product-characteristic";
export const best_baemin_product_characteristic_inner_router_url =
  "/best/baemin-product-characteristic";

//baemin product post
export const baemin_product_post_inner_router_url = "baemin-product-post";
export const best_baemin_product_post_inner_router_url =
  "/best/baemin-product-post";

//baemin jjim
export const baemin_jjim_inner_router_url = "baemin-jjim";
export const best_baemin_jjim_inner_router_url = "/best/baemin-jjim";

//baemin one jjim
export const baemin_one_jjim_inner_router_url = "baemin-one-jjim";
export const best_baemin_one_jjim_inner_router_url = "/best/baemin-one-jjim";

//baemin total search
export const baemin_total_search_inner_router_url = "baemin-total-search";
export const best_baemin_total_search_inner_router_url =
  "/best/baemin-total-search";

export const announcement_inner_router_url = "announcement";
export const best_announcement_inner_router_url = "/best/announcement";
export const my_page_inner_router_url = "my-page";
export const best_my_page_inner_router_url = "/best/my-page";

import styled from "styled-components";

export const TipTextBox = styled.div`
  background: #353535;
  display: block;
  line-height: 130%;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 0;
`;

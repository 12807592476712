import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SearchWrapInputFrame } from "../../../design_system/components/search_wrap_input_frame/SearchWrapInputFrame";
import Pagination from "../../../design_system/components/pagination/Pagination";
import { SearchBodyHeader } from "../../../design_system/components/search_body_header/SearchBodyHeader";
import { SmallBrownBtn } from "../../../design_system/components/small_brown_btn/SmallBrownBtn";
import { SmallBorderBlackBtn } from "../../../design_system/components/small_border_black_btn/SmallBorderBlackBtn";
import { numberFormatFunction } from "../../../data_system/data_function/number_format_function";
import { dateFormatFunction } from "../../../data_system/data_function/date_format_function";
import { getAdvertiserChargePointHistories } from "../../../communication_system/axios_apis/axios_apis";
import { useLoading } from "../../../data_system/context/loading_context/LoadingContext";
import StandardModal from "../../../design_system/components/modal/standard_modal/StandardModal";

const PointChargeChargeHistoryTab = () => {
  const { showLoading, hideLoading } = useLoading();

  const [page, setPage] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [advertiserChargePointHistories, setAdvertiserChargePointHistories] =
    useState(null);
  const [
    showAdvertiserChargePointHistoryDetailModal,
    setShowAdvertiserChargePointHistoryDetailModal,
  ] = useState(false);
  const [
    selectedAdvertiserChargePointHistory,
    setSelectedAdvertiserChargePointHistory,
  ] = useState(null);

  useEffect(() => {
    async function getData() {
      showLoading();

      const response = await getAdvertiserChargePointHistories(
        startDate,
        endDate,
        page,
        10
      );

      console.log(response);
      setAdvertiserChargePointHistories(
        response.data.advertiserChargePointHistories
      );

      hideLoading();
    }

    getData();
  }, [page]);

  return (
    <>
      <SearchBodyHeader>
        <SearchWrap>
          <SearchWrapInputFrame>
            <SearchWrapInput
              type="date"
              value={startDate === null ? "" : startDate}
              onChange={(e) => {
                if (e.target.value > endDate) {
                  alert("시작일은 종료일 이전의 날짜만 선택 가능합니다.");
                  setStartDate(endDate);
                } else {
                  setStartDate(e.target.value);
                }
              }}
            ></SearchWrapInput>
          </SearchWrapInputFrame>
          <SearchWrapInputFrame>
            <SearchWrapInput
              type="date"
              value={endDate === null ? "" : endDate}
              onChange={(e) => {
                if (e.target.value < startDate) {
                  alert("종료일은 시작일 이후의 날짜만 선택 가능합니다.");
                  setEndDate(startDate);
                } else {
                  setEndDate(e.target.value);
                }
              }}
            ></SearchWrapInput>
          </SearchWrapInputFrame>
          <SearchWrapFlexLi>
            <SmallBrownBtn
              onClick={async () => {
                showLoading();

                const response = await getAdvertiserChargePointHistories(
                  startDate,
                  endDate,
                  page,
                  10
                );

                console.log(response);
                setAdvertiserChargePointHistories(
                  response.data.advertiserChargePointHistories
                );

                hideLoading();
              }}
            >
              검색
            </SmallBrownBtn>
          </SearchWrapFlexLi>
          <SearchWrapFlexLi>
            <SmallBorderBlackBtn>엑셀 다운로드</SmallBorderBlackBtn>
          </SearchWrapFlexLi>
        </SearchWrap>
      </SearchBodyHeader>
      {advertiserChargePointHistories !== null && (
        <>
          <TableFrame>
            <thead>
              <tr
                style={{
                  backgroundColor: "#fff",
                  display: "table-row",
                  verticalAlign: "inherit",
                }}
              >
                <TableTitleDarkGray>충전일자</TableTitleDarkGray>
                <TableTitleDarkGray>충전 포인트</TableTitleDarkGray>
                <TableTitleDarkGray>보너스 포인트</TableTitleDarkGray>
                <TableTitleDarkGray>총 충전 포인트</TableTitleDarkGray>
                <TableTitleDarkGray>
                  총 입금금액<br></br>(vat 포함)
                </TableTitleDarkGray>
                <TableTitleDarkGray>입금자정보</TableTitleDarkGray>
                <TableTitleDarkGray>결제 방법</TableTitleDarkGray>
                <TableTitleDarkGray>예금주</TableTitleDarkGray>
                <TableTitleDarkGray>은행명</TableTitleDarkGray>
                <TableTitleDarkGray>계좌번호</TableTitleDarkGray>
                <TableTitleRed>결제 상태</TableTitleRed>
                <TableTitleRed>내역보기</TableTitleRed>
              </tr>
            </thead>
            <tbody>
              {advertiserChargePointHistories.advertiserPointHistories
                .length === 0 ? (
                <tr
                  style={{
                    backgroundColor: "#fff",
                    display: "table-row",
                    verticalAlign: "inherit",
                  }}
                >
                  <TableCellNormal colSpan="12">
                    데이터가 없습니다.
                  </TableCellNormal>
                </tr>
              ) : (
                <>
                  {advertiserChargePointHistories.advertiserPointHistories.map(
                    (advertiserPointHistory) => {
                      return (
                        <tr
                          key={advertiserPointHistory.id}
                          style={{
                            backgroundColor: "#fff",
                            display: "table-row",
                            verticalAlign: "inherit",
                          }}
                        >
                          <TableCellNormal>
                            {dateFormatFunction(
                              new Date(advertiserPointHistory.createdAt)
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(advertiserPointHistory.point)}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              advertiserPointHistory.bonusPoint
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              advertiserPointHistory.totalPoint
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              advertiserPointHistory.AdvertiserPaymentHistory
                                .totalPaymentPrice
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {
                              advertiserPointHistory.AdvertiserPaymentHistory
                                .depositorInfo
                            }
                          </TableCellNormal>
                          <TableCellNormal>
                            {advertiserPointHistory.AdvertiserPaymentHistory
                              .paymentMethodTypeId === 1
                              ? "무통장입금"
                              : advertiserPointHistory.AdvertiserPaymentHistory
                                  .paymentMethodTypeId === 2
                              ? "카드결제"
                              : "계좌이체"}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              advertiserPointHistory.AdvertiserPaymentHistory
                                .bankAccountHolder
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              advertiserPointHistory.AdvertiserPaymentHistory
                                .bankTypeId === 1
                                ? "KB국민은행"
                                : advertiserPointHistory
                                    .AdvertiserPaymentHistory.bankTypeId === 2
                                ? "신한은행"
                                : advertiserPointHistory
                                    .AdvertiserPaymentHistory.bankTypeId === 3
                                ? "우리은행"
                                : advertiserPointHistory
                                    .AdvertiserPaymentHistory.bankTypeId === 4
                                ? "하나은행"
                                : advertiserPointHistory
                                    .AdvertiserPaymentHistory.bankTypeId === 5
                                ? "카카오뱅크"
                                : advertiserPointHistory
                                    .AdvertiserPaymentHistory.bankTypeId === 6
                                ? "토스뱅크"
                                : advertiserPointHistory
                                    .AdvertiserPaymentHistory.bankTypeId === 7
                                ? "NH농협은행"
                                : advertiserPointHistory
                                    .AdvertiserPaymentHistory.bankTypeId === 8
                                ? "기업은행"
                                : "케이뱅크"
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {numberFormatFunction(
                              advertiserPointHistory.AdvertiserPaymentHistory
                                .bankAccountNumber
                            )}
                          </TableCellNormal>
                          <TableCellNormal>
                            {advertiserPointHistory.AdvertiserPaymentHistory
                              .paymentStateId === 1
                              ? "결제 신청"
                              : advertiserPointHistory.AdvertiserPaymentHistory
                                  .paymentStateId === 2
                              ? "결제 완료"
                              : advertiserPointHistory.AdvertiserPaymentHistory
                                  .paymentStateId === 3
                              ? "취소 신청"
                              : advertiserPointHistory.AdvertiserPaymentHistory
                                  .paymentStateId === 4
                              ? "취소 완료"
                              : advertiserPointHistory.AdvertiserPaymentHistory
                                  .paymentStateId === 5
                              ? "환불 신청"
                              : advertiserPointHistory.AdvertiserPaymentHistory
                                  .paymentStateId === 6
                              ? "환불 완료"
                              : advertiserPointHistory.AdvertiserPaymentHistory
                                  .paymentStateId === 7
                              ? "계산서발행 신청"
                              : "계산서발행 완료"}
                          </TableCellNormal>
                          <TableCellNormal>
                            <SmallBorderBlackBtn
                              onClick={() => {
                                setSelectedAdvertiserChargePointHistory(
                                  advertiserPointHistory
                                );
                                setShowAdvertiserChargePointHistoryDetailModal(
                                  true
                                );
                              }}
                            >
                              보기
                            </SmallBorderBlackBtn>
                          </TableCellNormal>
                        </tr>
                      );
                    }
                  )}
                </>
              )}
            </tbody>
          </TableFrame>
          <Pagination
            totalPages={
              advertiserChargePointHistories.totalPages === 0
                ? 1
                : advertiserChargePointHistories.totalPages
            }
            limit={10}
            page={page}
            setPage={setPage}
          ></Pagination>
        </>
      )}
      {showAdvertiserChargePointHistoryDetailModal && (
        <StandardModal
          onClose={() => {
            setShowAdvertiserChargePointHistoryDetailModal(false);
          }}
          titleText={"충전 내역 상세"}
          modalBodyComponent={
            <>
              <TableFrame>
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#fff",
                      display: "table-row",
                      verticalAlign: "inherit",
                    }}
                  >
                    <TableTitleDarkGray>처리항목</TableTitleDarkGray>
                    <TableTitleDarkGray>처리일자</TableTitleDarkGray>
                  </tr>
                </thead>
                <tbody>
                  <>
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        display: "table-row",
                        verticalAlign: "inherit",
                      }}
                    >
                      <TableCellNormal>결제 신청</TableCellNormal>
                      <TableCellNormal>
                        {selectedAdvertiserChargePointHistory
                          .AdvertiserPaymentHistory.paymentAppliedAt === null
                          ? ""
                          : dateFormatFunction(
                              new Date(
                                selectedAdvertiserChargePointHistory.AdvertiserPaymentHistory.paymentAppliedAt
                              )
                            )}
                      </TableCellNormal>
                    </tr>
                  </>
                  <>
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        display: "table-row",
                        verticalAlign: "inherit",
                      }}
                    >
                      <TableCellNormal>결제 완료</TableCellNormal>
                      <TableCellNormal>
                        {selectedAdvertiserChargePointHistory
                          .AdvertiserPaymentHistory.paymentCompletedAt === null
                          ? ""
                          : dateFormatFunction(
                              new Date(
                                selectedAdvertiserChargePointHistory.AdvertiserPaymentHistory.paymentCompletedAt
                              )
                            )}
                      </TableCellNormal>
                    </tr>
                  </>
                  <>
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        display: "table-row",
                        verticalAlign: "inherit",
                      }}
                    >
                      <TableCellNormal>취소 신청</TableCellNormal>
                      <TableCellNormal>
                        {selectedAdvertiserChargePointHistory
                          .AdvertiserPaymentHistory.cancelAppliedAt === null
                          ? ""
                          : dateFormatFunction(
                              new Date(
                                selectedAdvertiserChargePointHistory.AdvertiserPaymentHistory.cancelAppliedAt
                              )
                            )}
                      </TableCellNormal>
                    </tr>
                  </>
                  <>
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        display: "table-row",
                        verticalAlign: "inherit",
                      }}
                    >
                      <TableCellNormal>취소 완료</TableCellNormal>
                      <TableCellNormal>
                        {selectedAdvertiserChargePointHistory
                          .AdvertiserPaymentHistory.cancelCompletedAt === null
                          ? ""
                          : dateFormatFunction(
                              new Date(
                                selectedAdvertiserChargePointHistory.AdvertiserPaymentHistory.cancelCompletedAt
                              )
                            )}
                      </TableCellNormal>
                    </tr>
                  </>
                  <>
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        display: "table-row",
                        verticalAlign: "inherit",
                      }}
                    >
                      <TableCellNormal>계산서발행 신청</TableCellNormal>
                      <TableCellNormal>
                        {selectedAdvertiserChargePointHistory
                          .AdvertiserPaymentHistory
                          .issuanceOfInvoiceAppliedAt === null
                          ? ""
                          : dateFormatFunction(
                              new Date(
                                selectedAdvertiserChargePointHistory.AdvertiserPaymentHistory.issuanceOfInvoiceAppliedAt
                              )
                            )}
                      </TableCellNormal>
                    </tr>
                  </>
                  <>
                    <tr
                      style={{
                        backgroundColor: "#fff",
                        display: "table-row",
                        verticalAlign: "inherit",
                      }}
                    >
                      <TableCellNormal>계산서발행 완료</TableCellNormal>
                      <TableCellNormal>
                        {selectedAdvertiserChargePointHistory
                          .AdvertiserPaymentHistory
                          .issuanceOfInvoiceCompletedAt === null
                          ? ""
                          : dateFormatFunction(
                              new Date(
                                selectedAdvertiserChargePointHistory.AdvertiserPaymentHistory.issuanceOfInvoiceCompletedAt
                              )
                            )}
                      </TableCellNormal>
                    </tr>
                  </>
                </tbody>
              </TableFrame>
            </>
          }
          rightBtnText={"확인"}
          useLeftBtn={false}
          onRightBtnClick={() => {
            setShowAdvertiserChargePointHistoryDetailModal(false);
          }}
        ></StandardModal>
      )}
    </>
  );
};

export default PointChargeChargeHistoryTab;

const SearchWrap = styled.ul`
  justify-content: end;
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
`;

const SearchWrapInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const SearchWrapFlexLi = styled.li`
  display: flex;
  border-radius: 5px;
  margin-right: 10px;
  overflow: hidden;
  height: 40px;
  list-style: none;
`;

const TableFrame = styled.table`
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d3dbec;
  table-layout: fixed;
  font-size: 0.85rem;
  display: table;
  text-indent: initial;
  border-spacing: 2px;
`;

const TableTitleDarkGray = styled.th`
  background-color: #454d55;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableTitleRed = styled.th`
  background-color: #e84e4e;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableCellNormal = styled.td`
  white-space: normal;
  word-break: break-all;
  border: 0.5px solid #dee2e6;
  padding: 5px;
  text-align: center;
  line-height: 150%;
`;

import React from "react";
import styled from "styled-components";
import { ContentHeader } from "../../../design_system/components/content_header/ContentHeader";

const BaeminProductCharacteristicPage = () => {
  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>배민 상품 특징 안내</HeaderTitleH2>
      </ContentHeader>
      <MainBody>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            1.{" "}
          </span>
          <span>해당 배민 서비스는 </span>
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            불법적인 매크로 프로그램으로{" "}
          </span>
          <span
            style={{
              color: "#e84e4e",
            }}
          >
            트래픽 및 클릭수를 조작하는 서비스가 아닙니다.
          </span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            2.{" "}
          </span>
          <span>
            실제 사람이 상품 및 콘텐츠 게시물을 클릭하여 발생된 데이터가{" "}
          </span>
          <span
            style={{
              color: "#e84e4e",
            }}
          >
            유입, 체류시간, 메뉴 클릭으로 반영
          </span>
          <span>됩니다.</span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            3.{" "}
          </span>
          <span>
            어뷰징 프로그램을 사용하면 일시적인 순위 상승에 도움이 되더라도 어느
            순간 어뷰징으로 인한 큰 문제가 발생할 수 있습니다.
          </span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            4.{" "}
          </span>
          <span>
            배민 게시물 콘텐츠를 클릭 후 미션을 통해 인기 지수 효과를 높일 수
            있습니다.
          </span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            5.{" "}
          </span>
          <span>
            미션 성공 여부는 상품 게시물 페이지의 이미지 분석 OR 정답 매칭
            기반으로 미션을 완료하는 상품입니다.
          </span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            6.{" "}
          </span>
          <span>배민 상품이 특별한 이유</span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            -{" "}
          </span>
          <span>배민 상품은 실제 유저가 </span>
          <span
            style={{
              color: "#e84e4e",
            }}
          >
            모든 상품 유형
          </span>
          <span>에 도달, 방문, 참여, 조회 </span>
          <span
            style={{
              color: "#e84e4e",
            }}
          >
            수치가 증가하는 고품질 상품
          </span>
          <span>입니다.</span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            -{" "}
          </span>
          <span>
            위치 정보 시스템으로 국내 사람이면서 광고 설정한 위치에서 가까운
            사람부터 매칭됩니다.
          </span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            -{" "}
          </span>
          <span>
            이러한 매칭은 더욱 정교한 광고 상품으로 더욱 큰 효과를 보실 수
            있습니다.
          </span>
        </h3>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          <span
            style={{
              color: "#ddb20b",
            }}
          >
            -{" "}
          </span>
          <span>
            또한 이러한 잠재고객을 위한 추가적인 마케팅 계획을 세운다면 매출
            상승을 도모할 수 있습니다.
          </span>
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              textAlign: "center",
            }}
          >
            당사는 광고시장의 빠른 변화에 신속히 대처하고 있으며, 광고 참여형태
            및 완료사항을 인공지능화 하는데 투자하고 있습니다. 지속적인
            연구개발을 통해 광고주님들이 원하는 상품과 효율을 높일 수 있도록
            최선을 다하겠습니다.
          </span>
        </div>
      </MainBody>
    </Section>
  );
};

export default BaeminProductCharacteristicPage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 93px);
`;

import styled from "styled-components";

export const SmallBorderBlackBtn = styled.button`
  border: 1px solid #353535;
  background-color: #fff;
  color: #000;
  padding: 6px 15px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
`;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUnlock,
  faStore,
  faLocationDot,
  faCheck,
  faPlus,
  faCaretRight,
  faCircleInfo,
  faCircleUser,
  faGear,
  faKey,
  faBuilding,
  faPeopleGroup,
  faBuildingColumns,
  faMoneyCheck,
  faAt,
  faPhone,
  faUsersCog,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import arrow_forward_gray_1x from "./icons/arrow_forward_gray/arrow_forward_gray_1x.png";
import arrow_backward_gray_1x from "./icons/arrow_backward_gray/arrow_backward_gray_1x.png";
import styled from "styled-components";

export const FaUser = () => {
  return <FontAwesomeIcon icon={faUser} />;
};

export const FaUnlock = () => {
  return <FontAwesomeIcon icon={faUnlock} />;
};

export const FaStore = () => {
  return <FontAwesomeIcon icon={faStore} />;
};

export const FaLocationDot = () => {
  return <FontAwesomeIcon icon={faLocationDot} />;
};

export const FaCheck = () => {
  return <FontAwesomeIcon icon={faCheck} />;
};

export const FaPlus = () => {
  return <FontAwesomeIcon icon={faPlus} />;
};

export const FaCaretRight = () => {
  return <FontAwesomeIcon icon={faCaretRight} />;
};

export const FaCircleInfo = () => {
  return <FontAwesomeIcon icon={faCircleInfo} />;
};

export const FaCircleUser = () => {
  return <FontAwesomeIcon icon={faCircleUser} />;
};

export const FaGear = () => {
  return <FontAwesomeIcon icon={faGear} />;
};

export const FaKey = () => {
  return <FontAwesomeIcon icon={faKey} />;
};

export const FaBuilding = () => {
  return <FontAwesomeIcon icon={faBuilding} />;
};

export const FaPeopleGroup = () => {
  return <FontAwesomeIcon icon={faPeopleGroup} />;
};

export const FaBuildingColumns = () => {
  return <FontAwesomeIcon icon={faBuildingColumns} />;
};

export const FaMoneyCheck = () => {
  return <FontAwesomeIcon icon={faMoneyCheck} />;
};

export const FaAt = () => {
  return <FontAwesomeIcon icon={faAt} />;
};

export const FaPhone = () => {
  return <FontAwesomeIcon icon={faPhone} />;
};

export const FaUsersCog = () => {
  return <FontAwesomeIcon icon={faUsersCog} />;
};

export const ArrowForwardGray1x = () => {
  return (
    <img
      src={arrow_forward_gray_1x}
      alt={""}
      width={`18px`}
      height={`18px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};

export const ArrowBackwardGray1x = () => {
  return (
    <img
      src={arrow_backward_gray_1x}
      alt={""}
      width={`18px`}
      height={`18px`}
      style={{
        margin: 0,
        padding: 0,
      }}
    ></img>
  );
};

export const XIcon = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <FontAwesomeIcon
        icon={faTimes}
        style={{
          cursor: "pointer",
          fontWeight: 700,
          color: "#303133",
        }}
      />
    </div>
  );
};

export const XIconHover = ({ onClick }) => {
  return (
    <div onClick={onClick}>
      <XIconHoverContainer icon={faTimes} />
    </div>
  );
};

const XIconHoverContainer = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-weight: 700;
  color: #303133;
  transition: color 0.3s;

  &:hover {
    color: #ddb20b;
  }
`;

import styled from "styled-components";

export const BigRoundBrownBtn = styled.button`
  background-color: #ddb20b;
  box-shadow: 0 0 5px #00000030;
  padding: 15px 20px;
  width: 240px;
  font-size: 1rem;
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  font-weight: bold;
  text-align: center;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { dateFormatFunction } from "../../data_system/data_function/date_format_function";
import { numberFormatFunction } from "../../data_system/data_function/number_format_function";
import Pagination from "../../design_system/components/pagination/Pagination";
import { ContentHeader } from "../../design_system/components/content_header/ContentHeader";
import { SearchBodyHeader } from "../../design_system/components/search_body_header/SearchBodyHeader";
import { SearchWrapInputFrame } from "../../design_system/components/search_wrap_input_frame/SearchWrapInputFrame";
import { SearchWrapDropdownFrame } from "../../design_system/components/search_wrap_dropdown_frame/SearchWrapDropdownFrame";
import { SmallBrownBtn } from "../../design_system/components/small_brown_btn/SmallBrownBtn";
import { SmallBorderBlackBtn } from "../../design_system/components/small_border_black_btn/SmallBorderBlackBtn";
import { useLoading } from "../../data_system/context/loading_context/LoadingContext";
import { getAdvertiserPointHistories } from "../../communication_system/axios_apis/axios_apis";
import { useAdvertiserAccount } from "../../data_system/context/advertiser_account_context/AdvertiserAccountContext";
import { useNavigate } from "react-router-dom";
import { best_point_charge_inner_router_url } from "../../communication_system/inner_router_url/inner_router_url";

const PointHistoryPage = () => {
  const { advertiserAccount } = useAdvertiserAccount();
  const { showLoading, hideLoading } = useLoading();

  let navigate = useNavigate();

  const [page, setPage] = useState(1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [advertiserPointReasonId, setAdvertiserPointReasonId] = useState("");

  const handleAdvertiserPointReasonIdChange = (e) => {
    if (e.target.value === "") {
      setAdvertiserPointReasonId("");
    } else {
      setAdvertiserPointReasonId(parseInt(e.target.value));
    }
  };

  const [
    administratorAdvertisementProductId,
    setAdministratorAdvertisementProductId,
  ] = useState("");

  const handleAdministratorAdvertisementProductIdChange = (e) => {
    if (e.target.value === "") {
      setAdministratorAdvertisementProductId("");
    } else {
      setAdministratorAdvertisementProductId(parseInt(e.target.value));
    }
  };

  const [advertiserPointHistories, setAdvertiserPointHistories] =
    useState(null);

  useEffect(() => {
    async function getData() {
      showLoading();

      const response = await getAdvertiserPointHistories(
        startDate,
        endDate,
        advertiserPointReasonId === "" ? null : advertiserPointReasonId,
        administratorAdvertisementProductId === ""
          ? null
          : administratorAdvertisementProductId,
        page,
        10
      );

      console.log(response.data.advertiserPointHistories);
      setAdvertiserPointHistories(response.data.advertiserPointHistories);

      hideLoading();
    }

    getData();
  }, [page]);

  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>포인트 내역</HeaderTitleH2>
      </ContentHeader>
      <MainBody>
        <SearchBodyHeader>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              fontWeight: "bold",
              padding: "10px",
            }}
            onClick={() => {
              navigate(best_point_charge_inner_router_url);
            }}
          >
            <div
              style={{
                marginRight: "10px",
              }}
            >
              <span
                style={{
                  fontSize: "1.1rem",
                }}
              >
                보유 포인트
              </span>
            </div>
            <span
              style={{
                fontSize: "1.1rem",
                color: "#000",
              }}
            >
              {advertiserAccount === null
                ? 0
                : numberFormatFunction(advertiserAccount.leftPoint)}
            </span>
          </div>
          <SearchWrap>
            <SearchWrapDropdownFrame>
              <SearchSelectOptionDropdown
                id="advertiserPointReasonId"
                name="advertiserPointReasonId"
                onChange={handleAdvertiserPointReasonIdChange}
                value={advertiserPointReasonId}
              >
                <option value={""}>결제 상태 전체</option>
                <option value={1}>충전</option>
                <option value={2}>사용</option>
                <option value={3}>환불</option>
              </SearchSelectOptionDropdown>
            </SearchWrapDropdownFrame>
            <SearchWrapDropdownFrame>
              <SearchSelectOptionDropdown
                id="administratorAdvertisementProductId"
                name="administratorAdvertisementProductId"
                onChange={handleAdministratorAdvertisementProductIdChange}
                value={administratorAdvertisementProductId}
              >
                <option value={""}>상품 종류 전체</option>
                <option value={1}>N스토어 트래픽 - 단독</option>
                <option value={2}>N스토어 트래픽 - 가격비교</option>
                <option value={3}>N스토어 알림</option>
                <option value={4}>N스토어 상품 찜</option>
                <option value={5}>N플레이스 트래픽</option>
                <option value={6}>N플레이스 저장</option>
                <option value={7}>N플레이스 알림</option>
                <option value={8}>N플레이스 지도 찜</option>
                <option value={9}>N플레이스 블로그 공유</option>
                <option value={10}>N플레이스 Keep 공유</option>
                <option value={11}>N플레이스 블로그 리뷰</option>
                <option value={12}>카카오 선물 위시</option>
                <option value={13}>카카오 선물 후기 좋아요</option>
                <option value={14}>카카오 맵 저장</option>
                <option value={15}>구글 맵 저장</option>
                <option value={16}>구글 맵 리뷰</option>
                <option value={17}>인스타 팔로우</option>
                <option value={18}>인스타 좋아요</option>
                <option value={19}>인스타 저장</option>
                <option value={20}>유튜브 구독</option>
                <option value={21}>유튜브 좋아요</option>
                <option value={22}>배민 찜</option>
                <option value={23}>배민 원 찜</option>
              </SearchSelectOptionDropdown>
            </SearchWrapDropdownFrame>
            <SearchWrapInputFrame>
              <SearchWrapInput
                type="date"
                value={startDate === null ? "" : startDate}
                onChange={(e) => {
                  if (e.target.value > endDate) {
                    alert("시작일은 종료일 이전의 날짜만 선택 가능합니다.");
                    setStartDate(endDate);
                  } else {
                    setStartDate(e.target.value);
                  }
                }}
              ></SearchWrapInput>
            </SearchWrapInputFrame>
            <SearchWrapInputFrame>
              <SearchWrapInput
                type="date"
                value={endDate === null ? "" : endDate}
                onChange={(e) => {
                  if (e.target.value < startDate) {
                    alert("종료일은 시작일 이후의 날짜만 선택 가능합니다.");
                    setEndDate(startDate);
                  } else {
                    setEndDate(e.target.value);
                  }
                }}
              ></SearchWrapInput>
            </SearchWrapInputFrame>
            <SearchWrapFlexLi>
              <SmallBrownBtn
                onClick={async () => {
                  showLoading();

                  const response = await getAdvertiserPointHistories(
                    startDate,
                    endDate,
                    advertiserPointReasonId === ""
                      ? null
                      : advertiserPointReasonId,
                    administratorAdvertisementProductId === ""
                      ? null
                      : administratorAdvertisementProductId,
                    page,
                    10
                  );

                  console.log(response.data.advertiserPointHistories);
                  setAdvertiserPointHistories(
                    response.data.advertiserPointHistories
                  );

                  hideLoading();
                }}
              >
                검색
              </SmallBrownBtn>
            </SearchWrapFlexLi>
            <SearchWrapFlexLi>
              <SmallBorderBlackBtn>엑셀 다운로드</SmallBorderBlackBtn>
            </SearchWrapFlexLi>
          </SearchWrap>
        </SearchBodyHeader>
        <TableFrame>
          <thead>
            <tr
              style={{
                backgroundColor: "#fff",
                display: "table-row",
                verticalAlign: "inherit",
              }}
            >
              <TableTitleDarkGray>일자</TableTitleDarkGray>
              <TableTitleDarkGray>결제 상태</TableTitleDarkGray>
              <TableTitleDarkGray>상품 종류</TableTitleDarkGray>
              <TableTitleDarkGray>개수</TableTitleDarkGray>
              <TableTitleDarkGray>포인트</TableTitleDarkGray>
            </tr>
          </thead>
          {advertiserPointHistories !== null && (
            <tbody>
              {advertiserPointHistories.advertiserPointHistories.length ===
              0 ? (
                <tr
                  style={{
                    backgroundColor: "#fff",
                    display: "table-row",
                    verticalAlign: "inherit",
                  }}
                >
                  <TableCellNormal colSpan="5">
                    데이터가 없습니다.
                  </TableCellNormal>
                </tr>
              ) : (
                advertiserPointHistories.advertiserPointHistories.map(
                  (advertiserPointHistory) => {
                    return (
                      <tr
                        style={{
                          backgroundColor: "#fff",
                          display: "table-row",
                          verticalAlign: "inherit",
                        }}
                      >
                        <TableCellNormal>
                          {dateFormatFunction(
                            advertiserPointHistory.paymentAppliedAt
                          )}
                        </TableCellNormal>
                        <TableCellNormal>
                          {advertiserPointHistory.advertiserPointReasonId === 1
                            ? "충전"
                            : advertiserPointHistory.advertiserPointReasonId ===
                              2
                            ? "사용"
                            : "환불"}
                        </TableCellNormal>
                        <TableCellNormal>
                          {advertiserPointHistory.AdvertiserPaymentHistory !==
                          null
                            ? ""
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              1
                            ? "N스토어 트래픽 - 단독"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              2
                            ? "N스토어 트래픽 - 가격비교"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              3
                            ? "N스토어 알림"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              4
                            ? "N스토어 상품 찜"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              5
                            ? "N플레이스 트래픽"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              6
                            ? "N플레이스 저장"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              7
                            ? "N플레이스 알림"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              8
                            ? "N플레이스 지도 찜"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              9
                            ? "N플레이스 블로그 공유"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              10
                            ? "N플레이스 Keep 공유"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              11
                            ? "N플레이스 블로그 리뷰"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              12
                            ? "카카오 선물 위시"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              13
                            ? "카카오 선물 후기 좋아요"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              14
                            ? "카카오 맵 저장"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              15
                            ? "구글 맵 저장"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              16
                            ? "구글 맵 리뷰"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              17
                            ? "인스타 팔로우"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              18
                            ? "인스타 좋아요"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              19
                            ? "인스타 저장"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              20
                            ? "유튜브 구독"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              21
                            ? "유튜브 좋아요"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              22
                            ? "배민 찜"
                            : advertiserPointHistory.administratorAdvertisementProductId ===
                              23
                            ? "배민 원 찜"
                            : ""}
                        </TableCellNormal>
                        <TableCellNormal>
                          {numberFormatFunction(advertiserPointHistory.number)}
                        </TableCellNormal>
                        <TableCellNormal>
                          {numberFormatFunction(
                            advertiserPointHistory.totalPoint
                          )}
                        </TableCellNormal>
                      </tr>
                    );
                  }
                )
              )}
            </tbody>
          )}
        </TableFrame>
        <Pagination
          totalPages={
            advertiserPointHistories === null
              ? 1
              : advertiserPointHistories.totalPages === 0
              ? 1
              : advertiserPointHistories.totalPages
          }
          limit={10}
          page={page}
          setPage={setPage}
        ></Pagination>
      </MainBody>
    </Section>
  );
};

export default PointHistoryPage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 93px);
`;

const SearchWrap = styled.ul`
  justify-content: end;
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
`;

const SearchSelectOptionDropdown = styled.select`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const SearchWrapInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  font-weight: bold;
  height: 100%;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const SearchWrapFlexLi = styled.li`
  display: flex;
  background-color: #f9fafe;
  border-radius: 5px;
  border: 1px solid #d3dbec;
  margin-right: 10px;
  overflow: hidden;
  height: 40px;
  list-style: none;
`;

const TableFrame = styled.table`
  background-color: #fff;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #d3dbec;
  table-layout: fixed;
  font-size: 0.85rem;
  display: table;
  text-indent: initial;
  border-spacing: 2px;
  overflow-y: auto;
`;

const TableTitleDarkGray = styled.th`
  background-color: #454d55;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 5px #00000030;
  border: 0.5px solid rgb(116, 115, 115);
  padding: 5px;
  text-align: center;
  line-height: 150%;
  display: table-cell;
  vertical-align: inherit;
`;

const TableCellNormal = styled.td`
  white-space: normal;
  word-break: break-all;
  border: 0.5px solid #dee2e6;
  padding: 5px;
  text-align: center;
  line-height: 150%;
`;

import React from "react";
import styled from "styled-components";
import { ContentHeader } from "../../design_system/components/content_header/ContentHeader";

const AnnouncementPage = () => {
  return (
    <Section>
      <ContentHeader>
        <HeaderTitleH2>공지사항</HeaderTitleH2>
      </ContentHeader>
      <MainBody>
        <h3
          style={{
            marginBottom: 15,
            marginLeft: 10,
          }}
        >
          공지사항
        </h3>
        <Contents>
          <NoticeWrap>
            <p
              style={{
                padding: "10px 0",
              }}
            >
              자료가 없습니다.
            </p>
          </NoticeWrap>
        </Contents>
      </MainBody>
    </Section>
  );
};

export default AnnouncementPage;

const Section = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px #00000030;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
  overflow: hidden;
`;

const HeaderTitleH2 = styled.h2`
  font-size: 1.5rem;
`;

const MainBody = styled.div`
  position: relative;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 93px);
`;

const Contents = styled.div`
  height: auto;
  background-color: #fff;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid rgba(221, 178, 11, 0.3);
`;

const NoticeWrap = styled.ul`
  width: 100%;
  height: auto;
  list-style: none;
`;

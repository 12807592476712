import axios from "axios";
import { moneymoa_server_router_url } from "../communication_router_url/communication_router_url";
import axiosMiddleware from "./axios_middleware/axios_middleware";

export const postCompanyNumberCheck = async (companyNumber) => {
  try {
    console.log(typeof companyNumber);
    const response = await axios.post(
      `${moneymoa_server_router_url}/odcloud/api/nts-businessman/v1/status?serviceKey`,
      {
        companyNumber: companyNumber,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    console.error(
      "API Error:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const advertiserLogin = async (email, password) => {
  try {
    const response = await axios.post(
      `${moneymoa_server_router_url}/advertiser-auth/advertiser-log-in`,
      {
        email,
        password,
      }
    );

    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
        user: response.data.user,
      };
    }
  } catch (error) {
    console.log("Error during login:", error.response.status);
    return {
      status: error.response.status,
    };
  }
};

export const advertiserSignUp = async (
  businessTypeId,
  advertiserName,
  businessRegistrationNumber,
  businessRegistrationImageUrl,
  businessName,
  email,
  password,
  phoneNumber,
  privacyPolicyAgreed,
  servicePolicyAgreed,
  refundPolicyAgreed,
  marketingPolicyAgreed
) => {
  try {
    console.log("advertiserSignUp", marketingPolicyAgreed);
    const response = await axios.post(
      `${moneymoa_server_router_url}/advertiser-auth/advertiser-sign-up`,
      {
        businessTypeId,
        advertiserName,
        businessRegistrationNumber,
        businessRegistrationImageUrl,
        businessName,
        email,
        password,
        phoneNumber,
        privacyPolicyAgreed,
        servicePolicyAgreed,
        refundPolicyAgreed,
        marketingPolicyAgreed,
      }
    );
    console.log(response);
    if (response.data.accessToken && response.data.refreshToken) {
      return {
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        status: response.status,
      };
    } else {
      console.error("Error during signup: Tokens not provided");
      return null;
    }
  } catch (error) {
    console.log("Error during signup:", error);
    return {
      status: error.response.status,
    };
  }
};

export const uploadImage = async (imageFile) => {
  const formData = new FormData();
  formData.append("image", imageFile);
  console.log(imageFile);
  try {
    const response = await axios.post(
      `${moneymoa_server_router_url}/image/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    console.error("Error uploading the image:", error);
    throw error;
  }
};

export const deleteImage = async (imageUrl) => {
  try {
    console.log("deleteImage", imageUrl);
    const response = await axiosMiddleware.post("/image/delete", {
      imageUrl: imageUrl,
    });

    return response;
  } catch (error) {
    console.error("Error uploading the image:", error);
    throw error;
  }
};

export const getAdvertiserHomeInfo = async () => {
  try {
    const response = await axiosMiddleware.get(
      "/advertiser-home/get-advertiser-home-info"
    );

    return response;
  } catch (error) {
    console.error("Error getAdvertiserHomeInfo:", error);
    throw error;
  }
};

export const getAdvertiserAccount = async () => {
  try {
    const response = await axiosMiddleware.get(
      "/advertiser-account/get-advertiser-account"
    );

    return response;
  } catch (error) {
    console.error("Error getAdvertiserHomeInfo:", error);
    throw error;
  }
};

export const postAdvertiserAccountResetPassword = async (
  email,
  oldPassword,
  newPassword
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-account/advertiser-account-reset-password",
      {
        email,
        oldPassword,
        newPassword,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postAdvertiserAccountResetPassword:", error);
    throw error;
  }
};

export const advertiserAccountBankInfo = async (
  bankTypeId,
  bankAccountNumber
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-account/advertiser-account-update",
      {
        bankTypeId,
        bankAccountNumber,
      }
    );

    return response;
  } catch (error) {
    console.error("Error advertiserAccountBankInfo:", error);
    throw error;
  }
};

export const advertiserAccountDetailInfo = async (
  advertiserName,
  phoneNumber,
  marketingPolicyAgreed
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-account/advertiser-account-update",
      {
        advertiserName,
        phoneNumber,
        marketingPolicyAgreed,
      }
    );

    return response;
  } catch (error) {
    console.error("Error advertiserAccountDetailInfo:", error);
    throw error;
  }
};

export const getAdvertiserChargePointHistories = async (
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-point-history/get-advertiser-charge-point-histories",
      {
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getAdvertiserHomeInfo:", error);
    throw error;
  }
};

export const postAdvertiserChargePointHistory = async (
  totalPaymentPrice,
  valuedAddedTax,
  depositorInfo,
  paymentMethodTypeId,
  paymentStateId,
  privacyPolicyAgreed,
  servicePolicyAgreed,
  refundPolicyAgreed,
  totalPoint,
  point,
  bonusPoint,
  advertiserPointReasonId,
  advertisementProductId,
  administratorAdvertisementProductId,
  bankTypeId,
  bankAccountNumber,
  bankAccountHolder,
  paymentAppliedAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-point-history/post-advertiser-charge-point-history",
      {
        totalPaymentPrice,
        valuedAddedTax,
        depositorInfo,
        paymentMethodTypeId,
        paymentStateId,
        privacyPolicyAgreed,
        servicePolicyAgreed,
        refundPolicyAgreed,
        totalPoint,
        point,
        bonusPoint,
        advertiserPointReasonId,
        advertisementProductId,
        administratorAdvertisementProductId,
        bankTypeId,
        bankAccountNumber,
        bankAccountHolder,
        paymentAppliedAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postAdvertiserChargePointHistory:", error);
    throw error;
  }
};

export const getAdvertiserPointHistories = async (
  startDate,
  endDate,
  advertiserPointReasonId,
  administratorAdvertisementProductId,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-point-history/get-advertiser-point-histories",
      {
        startDate,
        endDate,
        advertiserPointReasonId,
        administratorAdvertisementProductId,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getAdvertiserPointHistories:", error);
    throw error;
  }
};

export const getAdministratorAdvertisementProduct = async () => {
  try {
    const response = await axiosMiddleware.get(
      "/advertiser-administrator-advertisement-product/get-administrator-advertisement-products"
    );

    console.log(response);

    return response;
  } catch (error) {
    console.error("Error getAdministratorAdvertisementProduct:", error);
    throw error;
  }
};

export const postNStoreTrafficSingle = async (
  storeName,
  userSearchKeyword,
  mid,
  productId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/post-n-store-traffic-single",
      {
        storeName,
        userSearchKeyword,
        mid,
        productId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNStoreTrafficSingle:", error);
    throw error;
  }
};

export const postNStoreTrafficPriceComparison = async (
  storeName,
  userSearchKeyword,
  entryMid,
  arrivalMid,
  productId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/post-n-store-traffic-price-comparison",
      {
        storeName,
        userSearchKeyword,
        entryMid,
        arrivalMid,
        productId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNStoreTrafficPriceComparison:", error);
    throw error;
  }
};

export const postNStoreAlarm = async (
  storeName,
  storeId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/post-n-store-alarm",
      {
        storeName,
        storeId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNStoreAlarm:", error);
    throw error;
  }
};

export const postNStoreProductJjim = async (
  storeName,
  storeId,
  productId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/post-n-store-product-jjim",
      {
        storeName,
        storeId,
        productId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNStoreProductJjim:", error);
    throw error;
  }
};

export const postNPlaceTraffic = async (
  placeName,
  userSearchKeyword,
  placeId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/post-n-place-traffic",
      {
        placeName,
        userSearchKeyword,
        placeId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNPlaceTraffic:", error);
    throw error;
  }
};

export const postNPlaceSave = async (
  placeName,
  placeId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/post-n-place-save",
      {
        placeName,
        placeId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNPlaceSave:", error);
    throw error;
  }
};

export const postNPlaceAlarm = async (
  placeName,
  placeId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/post-n-place-alarm",
      {
        placeName,
        placeId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNPlaceAlarm:", error);
    throw error;
  }
};

export const postNPlaceMapJjim = async (
  placeName,
  userSearchKeyword,
  placeId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/post-n-place-map-jjim",
      {
        placeName,
        userSearchKeyword,
        placeId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNPlaceMapJjim:", error);
    throw error;
  }
};

export const postNPlaceBlogShare = async (
  placeName,
  placeId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/post-n-place-blog-share",
      {
        placeName,
        placeId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNPlaceBlogShare:", error);
    throw error;
  }
};

export const postNPlaceKeepShare = async (
  placeName,
  placeId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/post-n-place-keep-share",
      {
        placeName,
        placeId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNPlaceKeepShare:", error);
    throw error;
  }
};

export const postNPlaceBlogReview = async (
  placeName,
  placeId,
  hashTag,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/post-n-place-blog-review",
      {
        placeName,
        placeId,
        hashTag,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postNPlaceBlogReview:", error);
    throw error;
  }
};

export const postKakaoPresentWish = async (
  productName,
  productId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-kakao/post-kakao-present-wish",
      {
        productName,
        productId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postKakaoPresentWish:", error);
    throw error;
  }
};

export const postKakaoPresentReviewLike = async (
  productName,
  productId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-kakao/post-kakao-present-review-like",
      {
        productName,
        productId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postKakaoPresentReviewLike:", error);
    throw error;
  }
};

export const postKakaoMapSave = async (
  mapPlaceName,
  mapPlaceId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-kakao/post-kakao-map-save",
      {
        mapPlaceName,
        mapPlaceId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postKakaoMapSave:", error);
    throw error;
  }
};

export const postGoogleMapSave = async (
  mapPlaceName,
  mapPlaceUrl,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-google/post-google-map-save",
      {
        mapPlaceName,
        mapPlaceUrl,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postGoogleMapSave:", error);
    throw error;
  }
};

export const postGoogleMapReview = async (
  mapPlaceName,
  mapPlaceUrl,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  address,
  detailAddress,
  latitude,
  longitude,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-google/post-google-map-review",
      {
        mapPlaceName,
        mapPlaceUrl,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        address,
        detailAddress,
        latitude,
        longitude,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postGoogleMapReview:", error);
    throw error;
  }
};

export const postInstaFollow = async (
  instaId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-insta/post-insta-follow",
      {
        instaId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postInstaFollow:", error);
    throw error;
  }
};

export const postInstaLike = async (
  instaId,
  instaPostId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-insta/post-insta-like",
      {
        instaId,
        instaPostId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postInstaLike:", error);
    throw error;
  }
};

export const postInstaSave = async (
  instaId,
  instaPostId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-insta/post-insta-save",
      {
        instaId,
        instaPostId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postInstaSave:", error);
    throw error;
  }
};

export const postYoutubeSubscribe = async (
  youtubeChannelName,
  youtubeChannelId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-youtube/post-youtube-subscribe",
      {
        youtubeChannelName,
        youtubeChannelId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postYoutubeSubscribe:", error);
    throw error;
  }
};

export const postYoutubeLike = async (
  youtubeChannelName,
  youtubeChannelVideoPostId,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-youtube/post-youtube-like",
      {
        youtubeChannelName,
        youtubeChannelVideoPostId,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postYoutubeLike:", error);
    throw error;
  }
};

export const postBaeminJjim = async (
  baeminBusinessName,
  baeminPlaceUrl,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId = 22,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-baemin/post-baemin-jjim",
      {
        baeminBusinessName,
        baeminPlaceUrl,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postBaeminJjim:", error);
    throw error;
  }
};

export const postBaeminOneJjim = async (
  baeminOneBusinessName,
  baeminOnePlaceUrl,
  totalWorkload,
  todayTargetDailyWorkload,
  todayCompleteDailyWorkload,
  nextTargetDailyWorkload,
  totalSpentPoint,
  todayAdvertiserPrice,
  nextAdvertiserPrice,
  memo,
  todayAdvertisementProgressType,
  nextAdvertisementProgressType,
  administratorAdvertisementProductId = 23,
  lastRunAt
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-baemin/post-baemin-one-jjim",
      {
        baeminOneBusinessName,
        baeminOnePlaceUrl,
        totalWorkload,
        todayTargetDailyWorkload,
        todayCompleteDailyWorkload,
        nextTargetDailyWorkload,
        totalSpentPoint,
        todayAdvertiserPrice,
        nextAdvertiserPrice,
        memo,
        todayAdvertisementProgressType,
        nextAdvertisementProgressType,
        administratorAdvertisementProductId,
        lastRunAt,
      }
    );

    return response;
  } catch (error) {
    console.error("Error postBaeminOneJjim:", error);
    throw error;
  }
};

export const getNStoreIntegratedByConditions = async (
  administratorAdvertisementProductId,
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/get-n-store-integrated-by-conditions",
      {
        administratorAdvertisementProductId,
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNStoreIntegratedByConditions:", error);
    throw error;
  }
};

export const getNPlaceIntegratedByConditions = async (
  administratorAdvertisementProductId,
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-integrated-by-conditions",
      {
        administratorAdvertisementProductId,
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceIntegratedByConditions:", error);
    throw error;
  }
};

export const getKakaoIntegratedByConditions = async (
  administratorAdvertisementProductId,
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-kakao/get-kakao-integrated-by-conditions",
      {
        administratorAdvertisementProductId,
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getKakaoIntegratedByConditions:", error);
    throw error;
  }
};

export const getGoogleIntegratedByConditions = async (
  administratorAdvertisementProductId,
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-google/get-google-integrated-by-conditions",
      {
        administratorAdvertisementProductId,
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getGoogleIntegratedByConditions:", error);
    throw error;
  }
};

export const getInstaIntegratedByConditions = async (
  administratorAdvertisementProductId,
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-insta/get-insta-integrated-by-conditions",
      {
        administratorAdvertisementProductId,
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getInstaIntegratedByConditions:", error);
    throw error;
  }
};

export const getYoutubeIntegratedByConditions = async (
  administratorAdvertisementProductId,
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-youtube/get-youtube-integrated-by-conditions",
      {
        administratorAdvertisementProductId,
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getYoutubeIntegratedByConditions:", error);
    throw error;
  }
};

export const getBaeminIntegratedByConditions = async (
  administratorAdvertisementProductId,
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-baemin/get-baemin-integrated-by-conditions",
      {
        administratorAdvertisementProductId,
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getBaeminIntegratedByConditions:", error);
    throw error;
  }
};

export const putNStoreTrafficSingle = async (
  nStoreTrafficSingleId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-store/put-n-store-traffic-single/${nStoreTrafficSingleId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNStoreTrafficSingle:", error);
    throw error;
  }
};

export const putNStoreTrafficPriceComparison = async (
  nStoreTrafficPriceComparisonId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-store/put-n-store-traffic-price-comparison/${nStoreTrafficPriceComparisonId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNStoreTrafficPriceComparison:", error);
    throw error;
  }
};

export const putNStoreAlarm = async (
  nStoreAlarmId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-store/put-n-store-alarm/${nStoreAlarmId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNStoreAlarm:", error);
    throw error;
  }
};

export const putNStoreProductJjim = async (
  nStoreProductJjimId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-store/put-n-store-product-jjim/${nStoreProductJjimId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNStoreProductJjim:", error);
    throw error;
  }
};

export const putNPlaceTraffic = async (
  nPlaceTrafficId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-place/put-n-place-traffic/${nPlaceTrafficId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNPlaceTraffic:", error);
    throw error;
  }
};

export const putNPlaceSave = async (
  nPlaceSaveId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-place/put-n-place-save/${nPlaceSaveId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNPlaceSave:", error);
    throw error;
  }
};

export const putNPlaceAlarm = async (
  nPlaceAlarmId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-place/put-n-place-alarm/${nPlaceAlarmId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNPlaceAlarm:", error);
    throw error;
  }
};

export const putNPlaceMapJjim = async (
  nPlaceMapJjimId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-place/put-n-place-map-jjim/${nPlaceMapJjimId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNPlaceMapJjim:", error);
    throw error;
  }
};

export const putNPlaceBlogShare = async (
  nPlaceBlogShareId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-place/put-n-place-blog-share/${nPlaceBlogShareId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNPlaceBlogShare:", error);
    throw error;
  }
};

export const putNPlaceKeepShare = async (
  nPlaceKeepShareId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-place/put-n-place-keep-share/${nPlaceKeepShareId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNPlaceKeepShare:", error);
    throw error;
  }
};

export const putNPlaceBlogReview = async (
  nPlaceBlogReviewId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-n-place/put-n-place-blog-review/${nPlaceBlogReviewId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putNPlaceBlogReview:", error);
    throw error;
  }
};

export const putKakaoPresentWish = async (
  kakaoPresentWishId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-kakao/put-kakao-present-wish/${kakaoPresentWishId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putKakaoPresentWish:", error);
    throw error;
  }
};

export const putKakaoPresentReviewLike = async (
  kakaoPresentReviewLikeId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-kakao/put-kakao-present-review-like/${kakaoPresentReviewLikeId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putKakaoPresentReviewLike:", error);
    throw error;
  }
};

export const putKakaoMapSave = async (
  kakaoMapSaveId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-kakao/put-kakao-map-save/${kakaoMapSaveId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putKakaoMapSave:", error);
    throw error;
  }
};

export const putGoogleMapSave = async (
  googleMapSaveId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-google/put-google-map-save/${googleMapSaveId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putGoogleMapSave:", error);
    throw error;
  }
};

export const putGoogleMapReview = async (
  googleMapReviewId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-google/put-google-map-review/${googleMapReviewId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putGoogleMapReview:", error);
    throw error;
  }
};

export const putInstaFollow = async (
  instaFollowId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-insta/put-insta-follow/${instaFollowId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putInstaFollow:", error);
    throw error;
  }
};

export const putInstaLike = async (
  instaLikeId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-insta/put-insta-like/${instaLikeId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putInstaLike:", error);
    throw error;
  }
};

export const putInstaSave = async (
  instaSaveId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-insta/put-insta-save/${instaSaveId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putInstaSave:", error);
    throw error;
  }
};

export const putYoutubeSubscribe = async (
  youtubeSubscribeId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-youtube/put-youtube-subscribe/${youtubeSubscribeId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putYoutubeSubscribe:", error);
    throw error;
  }
};

export const putYoutubeLike = async (
  youtubeLikeId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-youtube/put-youtube-like/${youtubeLikeId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putYoutubeLike:", error);
    throw error;
  }
};

export const putBaeminJjim = async (
  baeminJjimId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-baemin/put-baemin-jjim/${baeminJjimId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putBaeminJjim:", error);
    throw error;
  }
};

export const putBaeminOneJjim = async (
  baeminOneJjimId,
  nextTargetDailyWorkload,
  nextAdvertisementProgressType,
  memo
) => {
  try {
    const response = await axiosMiddleware.put(
      `/advertiser-baemin/put-baemin-one-jjim/${baeminOneJjimId}`,
      {
        nextTargetDailyWorkload,
        nextAdvertisementProgressType,
        memo,
      }
    );

    return response;
  } catch (error) {
    console.error("Error putBaeminOneJjim:", error);
    throw error;
  }
};

export const getNStoreTrafficSingleByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/get-n-store-traffic-single-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNStoreTrafficSingleByConditions:", error);
    throw error;
  }
};

export const getNStoreTrafficPriceComparisonByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/get-n-store-traffic-price-comparison-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNStoreTrafficPriceComparisonByConditions:", error);
    throw error;
  }
};

export const getNStoreAlarmByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/get-n-store-alarm-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNStoreAlarmByConditions:", error);
    throw error;
  }
};

export const getNStoreProductJjimByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-store/get-n-store-product-jjim-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNStoreProductJjimByConditions:", error);
    throw error;
  }
};

export const getNPlaceTrafficByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-traffic-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceTrafficByConditions:", error);
    throw error;
  }
};

export const getNPlaceSaveByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-save-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceSaveByConditions:", error);
    throw error;
  }
};

export const getNPlaceAlarmByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-alarm-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceAlarmByConditions:", error);
    throw error;
  }
};

export const getNPlaceMapJjimByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-map-jjim-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceMapJjimByConditions:", error);
    throw error;
  }
};

export const getNPlaceBlogShareByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-blog-share-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceBlogShareByConditions:", error);
    throw error;
  }
};

export const getNPlaceKeepShareByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-keep-share-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceKeepShareByConditions:", error);
    throw error;
  }
};

export const getNPlaceBlogReviewByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-n-place/get-n-place-blog-review-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getNPlaceBlogReviewByConditions:", error);
    throw error;
  }
};

export const getKakaoPresentWishByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-kakao/get-kakao-present-wish-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getKakaoPresentWishByConditions:", error);
    throw error;
  }
};

export const getKakaoPresentReviewLikeByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-kakao/get-kakao-present-review-like-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getKakaoPresentReviewLikeByConditions:", error);
    throw error;
  }
};

export const getKakaoMapSaveByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-kakao/get-kakao-map-save-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getKakaoMapSaveByConditions:", error);
    throw error;
  }
};

export const getGoogleMapSaveByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-google/get-google-map-save-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getGoogleMapSaveByConditions:", error);
    throw error;
  }
};

export const getGoogleMapReviewByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-google/get-google-map-review-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getGoogleMapReviewByConditions:", error);
    throw error;
  }
};

export const getInstaFollowByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-insta/get-insta-follow-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getInstaFollowByConditions:", error);
    throw error;
  }
};

export const getInstaLikeByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-insta/get-insta-like-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getInstaLikeByConditions:", error);
    throw error;
  }
};

export const getInstaSaveByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-insta/get-insta-save-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getInstaSaveByConditions:", error);
    throw error;
  }
};

export const getYoutubeSubscribeByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-youtube/get-youtube-subscribe-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getYoutubeSubscribeByConditions:", error);
    throw error;
  }
};

export const getYoutubeLikeByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-youtube/get-youtube-like-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getYoutubeLikeByConditions:", error);
    throw error;
  }
};

export const getBaeminJjimByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-baemin/get-baemin-jjim-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getBaeminJjimByConditions:", error);
    throw error;
  }
};

export const getBaeminOneJjimByConditions = async (
  todayAdvertisementProgressType,
  searchTypeId,
  searchText,
  startDate,
  endDate,
  page,
  pageSize
) => {
  try {
    const response = await axiosMiddleware.post(
      "/advertiser-baemin/get-baemin-one-jjim-by-conditions",
      {
        todayAdvertisementProgressType,
        searchTypeId,
        searchText,
        startDate,
        endDate,
        page,
        pageSize,
      }
    );

    return response;
  } catch (error) {
    console.error("Error getBaeminOneJjimByConditions:", error);
    throw error;
  }
};

export const postSendAuthSMS = async (phoneNumber) => {
  try {
    const response = await axios.post(
      `${moneymoa_server_router_url}/advertiser-auth/send-auth-SMS`,
      {
        phoneNumber,
      }
    );

    return response;
  } catch (error) {
    console.log("Error during login:", error.response.status);
    return {
      status: error.response.status,
    };
  }
};

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  login_inner_router_url,
  sign_up_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";
import styled from "styled-components";

const TermsAndConditionsPage = () => {
  let navigate = useNavigate();

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn
            onClick={() => {
              navigate(login_inner_router_url);
            }}
          >
            로그인
          </ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn
              onClick={() => {
                navigate(sign_up_inner_router_url);
              }}
            >
              시작하기
            </ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Content>
        <ContentInfo>
          <ContentInfoInner>
            <ContentInfoInnerTitle>이용약관</ContentInfoInnerTitle>
            <p>
              <strong>제1조 (시행)</strong>
              <br></br>본 약관(이하 “약관”)은 2024년 01월 16일부터 시행되며,
              이전 “약관”을 대체합니다.
            </p>
            <br></br>
            <p>
              <strong>제2조 (목적)</strong>
              <br></br>본 “약관＂은 주식회사 핑거네이션(이하 “회사“)가 제공하는
              비즈핏파트너스 콘텐츠 플랫폼(이하 “플랫폼“)을 이용함에 있어
              “회사＂와 “플랫폼＂이용자(이하＂회원사))와 광고주 간의 제반 사항을
              규정함을 목적으로 합니다.본 “약관＂은 주식회사 핑거네이션(이하
              “회사“)가 제공하는 비즈핏파트너스 콘텐츠 플랫폼(이하 “플랫폼“)을
              이용함에 있어 “회사＂와 “플랫폼＂이용자(이하＂회원사))와 광고주
              간의 제반 사항을 규정함을 목적으로 합니다.
            </p>
            <br></br>
            <p>
              <strong>제3조 (용어의 정의)</strong>
              <br></br>1. “회원사”는 본 “약관”에 동의하여 “회사”가 제공하는
              “플랫폼”을 이용하고 있는 상대방을 총칭합니다.<br></br>
              2. “콘텐츠플랫폼”이란 “회사”가 제공하는 비즈핏파트너스 “플랫폼”을
              통해서 기획, 개발, 운영 등 관련 업무가 수행되는 모든 유형의 개발
              활동 상품을 의미합니다.<br></br>3. “이용자”란 "플랫폼"을 통해
              “회원사”가 제공하는 “콘텐츠플랫폼”을 사용하는 개인 및 법인을
              의미합니다.
              <br></br>
              4. “통신수단”이란 “회사”가 “회원사”에게 “플랫폼” 이용에 필요가
              있다고 인정되는 각종 정보를 전달하는 방식을 의미하며, “사이트”
              공지, 이메일, 팩스, SMS, MMS, 전화 및 기타 상호 간의 동의된 방식을
              포함합니다.<br></br>5. “매체이용대가”이란 회원사가 플랫폼을
              이용하며 발생한 수익 금액(부가세 제외)을 말합니다.<br></br>
              6. “광고주”란 비즈핏파트너에서 제공하는 광고유형을 이용하는
              회원사를 말합니다.
            </p>
            <br></br>
            <p>
              <strong>제4조 (“약관”의 효력 및 변경)</strong>
              <br></br>1. “약관”은 “사이트” 또는 “회원사”의 전자우편,
              문자메시지(LMS/SMS) 등을 통하여 공지함으로써 효력이 발생됩니다.
              <br></br>
              2. “회사”는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및
              정보보호 등에 관한 법률」 등 관련법을 위배하지 않는 범위에서
              “약관”을 개정할 수 있습니다.<br></br>3. “회사”는 “약관”을 개정할
              경우 적용일자 및 주요 변경 사항을 명시하여 그 개정 “약관”의
              적용일자 7일 전부터(중요하거나 “회원사”에게 불리한 내용인 경우
              30일) 고지하며, 변경된 “약관”은 본 조 제1항에 따라 공지합니다.
              <br></br>
              4. “회사”는 “회원사”에게 불리한 “약관”의 개정의 경우에는 공지 외에
              일정 서비스 기간 내 이메일, 팩스, SMS, LMS, 전화, 전화쪽지, 로그인
              시 동의창 등의 방식으로 고지하고, 통지 시 “회원사”가 개정 “약관”에
              대해 시행일까지 동의 또는 거부의 의사표시를 하지 않으면 동의한
              것으로 본다는 내용도 함께 통지하며, “회원사”가 개정 “약관”
              시행일까지 거부의 의사표시를 하지 않으면 개정 “약관”에 동의한
              것으로 간주합니다.<br></br>5. “회원사”가 개정약관의 적용에
              동의하지 않는 경우 “회사”는 개정약관의 내용을 적용할 수 없으며,
              개정약관에 동의하지 않는 “회원사”는 개정약관의 적용을 받는 서비스
              이용이 제한될 수 있습니다. 개정약관의 적용이 불가피 한 특별한
              사정이 있는 경우 “회사”는 개정약관에 동의하지 않는 “회원사”와의
              이용계약을 해지할 수 있습니다.<br></br>6. “회원사”와 “회사”가
              약관에서 정하고 있는 사항에 관하여 약관의 내용과 다르게 합의한
              사항이 있을 때에는 그 합의 사항이 약관보다 우선합니다.
            </p>
            <br></br>
            <p>
              <strong>제5조 (계약의 성립)</strong>
              <br></br>1. “회원사”는 “회사”가 정한 절차에 따라 본 약관에
              동의하고 “사이트” 내 계정 등록.매체등록을 신청하며, “회사”가 이를
              승낙함으로써 이용계약이 체결됩니다. 단, “사이트” 접근이 어려운
              경우 인쇄 가능한 약관을 “회사”로부터 제공받아 날인 후 “회사”로
              발송한 경우 이용계약이 체결된 것으로 봅니다.<br></br>2. “플랫폼”을
              이용하고자 하는 “회원사”는 “회사”에서 요청하는 제반 정보를
              제공하여야 합니다.<br></br>3. 모든 “회원사”는 “플랫폼” 이용 시
              반드시 “회원사” 법인명 또는 개인의 실명을 제공하여야만 “플랫폼”을
              이용할 수 있으며, “회사”는 필요 시 실명 확인 조치를 할 수
              있습니다. 실명으로 등록하지 않은 “회원사”는 일체의 권리를 주장할
              수 없습니다.<br></br>4. “회사”가 필요하다고 인정하는 경우
              “회원사”에게 추가 자료의 제출을 요청할 수 있으며, 제출 전까지
              “회원사”의 “플랫폼” 이용을 제한할 수 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제6조 (승인 유보 또는 철회)</strong>
              <br></br>1. “회사”는 다음 각 호에 해당하는 경우 "회원사"의
              이용신청에 대해 승인을 거부할 수 있으며, 등록이 된 이후에도 아래
              각 호에 해당하는 경우에는 승인을 철회할 수 있습니다.<br></br>a.
              가입 신청자가 실명이 아니거나 타인의 명의를 이용한 경우<br></br>
              b. 허위의 정보를 기재하거나, “회사”가 제시하는 내용을 기재하지
              않은 경우<br></br>c. “회사”에 의하여 이용계약이 해지된 날로부터
              2개월 이내에 재이용신청을 하는 경우<br></br>d. 본 약관에
              위배되거나 위법 또는 부당한 이용신청임이 확인된 경우<br></br>e.
              “회원사”의 책임 있는 사유로 인하여 “회사”가 승인할 수 없는 경우
              <br></br>f. 기타 “회사”가 필요하다고 인정한 경우<br></br>
              2. “회사”는 다음과 같은 사유가 있는 경우, 이용신청에 대한 승인을
              유보할 수 있습니다.<br></br>a. 설비에 여유가 없는 경우<br></br>b.
              기술상 지장이 있는 경우<br></br>c. “회사”의 실명확인절차에서 실명
              여부가 확인되지 않는 경우<br></br>d. 기타 “회사”가 필요하다고
              인정하는 경우
            </p>
            <br></br>
            <p>
              <strong>제7조 (“플랫폼”내 회원사 정보 변경)</strong>
              <br></br>1. “회원사”는 “사이트”에서 언제든지 자사의 정보를
              열람하고 수정할 수 있습니다.<br></br>2. “회원사”는 플랫폼”
              이용신청 시 기재한 사항이 변경되었을 경우, “사이트”에서 수정을
              해야 하며 “회원사”의 정보를 변경하지 아니하여 발생되는 문제의
              책임은 “회원사”에게 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제8조 (“회사”의 의무)</strong>
              <br></br>1. “회사”는 “회사”의 “플랫폼”을 “회원사”에게 제공해서
              “회원사”가 이를 도입하고 운영할 수 있도록 합니다.<br></br>2.{" "}
              “회사”는 "콘텐츠플랫폼"을 안정적으로 등록하여 "회원사"의
              "매체지면"내에서 이용자들에게 제공되도록 합니다.<br></br>3.{" "}
              “회사”는 “회원사”의 “매체지면” 이용자들이 상기 “콘텐츠플랫폼”에
              참여를 완료한 건들에 대해서 “회사”의 집계 기준에 의한
              “매체이용대가”를 “회원사”에게 지급하기로 합니다.<br></br>4.{" "}
              “회사”는 본 계약의 의무를 이행하는 과정 및 본 계약상 “회원사”에게
              제공하는 “플랫폼” 등이 관련법률을 위반하거나 제3자의 권리나 명예를
              침해하지 않음을 보증하며, 문제 발생시 관련된 법적 기준을 근거하여
              “회사”의 책임으로부터 “회원사”를 면책하기로 합니다.
            </p>
            <br></br>
            <p>
              <strong>제9조 (“회원사”의 의무)</strong>
              <br></br>1. “회원사”는 본 “약관”에서 규정하는 사항 및 “플랫폼”의
              이용안내 또는 공지사항 등을 통하여 “회사”가 공지하는 사항을
              준수하기로 합니다.<br></br>2. “회원사”는 운영중인 “매체지면”을
              “회사”가 제공하는 “플랫폼”에 도입 및 연동하여 “회사”에 의해
              “콘텐츠플랫폼”이 노출 및 제어될 수 있도록 합니다.<br></br>
              3. “회원사”는 “회사”가 제공하는 “콘텐츠플랫폼” 사용하기 위해서
              “회원사”의 이용자들로부터 동의를 받는 등 필요한 법적 조치를 취할
              의무가 있으며, 이를 소홀히 했을 경우에 발생하는 모든 문제에서
              “회사”를 면책합니다.<br></br>4. “회원사”는 “회사”의 명시적인
              동의가 없는 한 본 “약관”상 권리를 타인에게 양도, 증여할 수 없으며,
              이를 담보로 제공할 수 없습니다.<br></br>5. “회원사”는 본 계약의
              의무를 이행하는 과정에서 제3자의 권리나 명예를 침해하지 않도록
              최선의 노력을 다하며 문제발생시 “회사”를 면책하고 “회원사”가 이에
              대한 모든 법적 책임을 집니다.<br></br>6. “회원사”는 다음 각 호의
              하나에 해당되는 행위를 하지 않기로 합니다.<br></br>a. “회사”의
              특허, 상표, 영업비밀, 저작권, 기타 지식재산권을 침해하는 내용을
              게시, 전자메일 발송 또는 기타의 방법으로 타인에게 유포하는 행위
              <br></br>
              b. “회원사”에게 권리가 없는 특허, 상표, 영업비밀, 저작권 등
              지식재산권을 권리자의 허락없이 행사하거나 공유하는 행위<br></br>c.{" "}
              “회원사”가 운영하는 “매체지면” 및 서비스의 이용자가 불법적이거나
              “회사”가 어뷰징으로 정의한 행위로 “회사”의 콘텐츠상품을 남용하는
              것을 묵인하는 행위. 본 “약관”에서 어뷰징은 다음과 같이 정의하기로
              하며, “회사”에 의해서 어뷰징으로 판단되는 행위에 대한 정의가
              추가될 수 있습니다.<br></br>- 디바이스 ID를 변조하여 1개의
              콘텐츠를 동일기기로 반복 참여 및 완료한 경우
              <br></br>- 봇이나 에뮬레이터를 사용하여 1개의 콘텐츠를 동일기기로
              반복 참여 및 완료한 경우 -VPN을 사용하여 한국 내 “이용자”가 참여
              및 완료한 경우<br></br>- “이용자”가 “리워드” 획득을 목적으로
              허위사실을 유포하여 콘텐츠 참여 및 완료한 경우<br></br>- 기타 업계
              관행상 또는 상식적으로 어뷰징으로 인정되는 행위<br></br>d.
              “회사”의 승인을 받지 않고 다른 “회원사”의 개인정보를 수집 또는
              저장하는 행위<br></br>e. 범죄와 결부된다고 객관적으로 인정되는
              행위<br></br>f. 기타 관계법령에 위배되는 행위<br></br>7. 회원사는
              「아동 온라인 개인정보 보호법」(Childern's Online Privacy
              Protection Act, 이하 “COPPA”)를 준수해야 합니다. 본 계약에 따라
              "콘텐츠플랫폼"이 제공되는 "회원사" 온라인 서비스가 다음 각 호에
              해당되는 경우, "회원사"는 해당 사실을 지체없이 "회사"에 통지해야
              합니다. "회원사"가 본 항의 의무를 위반하거나 이행을 지체함으로
              "회사"가 손해를 입은 경우 "회원사"는 그 손해를 배상할 책임을
              부담합니다.<br></br>a. "회원사"의 온라인 서비스가 만 13세 미만의
              아동을 대상으로 하는 경우<br></br>b. "회원사"의 온라인 서비스가
              일반인을 대상으로 하지만, 만 13세 미만 아동의 개인정보를 수집,
              이용 또는 공개하는 것에 대한 실질적인 인식이 있는 경우<br></br>c.{" "}
              "회원사"가 "회원사"의 온라인 서비스와 연계된 제3자의 서비스가 만
              13세 미만 아동의 개인정보를 수집, 이용 또는 공개함에 대한 실질적인
              인식이 있는 경우"
            </p>
            <br></br>
            <p>
              <strong>제10조 (“매체이용대가”의 결정, 변경 및 지급)</strong>
              <br></br>1. “회원사”가 “매체지면”에 “회사”의 “콘텐츠플랫폼”을
              제공하기 위해 “회원사”의 어플리케이션 또는 서비스에
              인앱연동.링크연동 또는 기타 “회사”가 제공한 형식으로 기술적 연동을
              완료하고 “회사”의 승인을 받은 후, “회사”는 “회원사”에게 “플랫폼”을
              통해서 제공하는 “콘텐츠플랫폼”의 내용, 그리고 이에 따른
              “매체이용대가”의 산출 기준 및 단가를 “통신수단”을 통해서
              고지합니다. 단, “회사”는 필요시 특정 콘텐츠플랫폼에 대해서 기존에
              고지된 “매체이용대가”이 아닌, 자체적으로 결정한 단가를 사전 통지
              없이 일시적으로 적용할 수 있습니다.<br></br>2. 새로운
              “콘텐츠플랫폼”의 추가 또는 각종 내, 외부 요인으로 인해 고지된
              “매체이용대가”이 영구적으로 변경될 경우, “회사”는 “통신수단”을
              통해서 “회원사”에게 변경 사항 적용 3일 전까지 사전 통지하기로
              합니다.<br></br>3. 회원사"가 위반한 경우에는 "매체이용대가"의
              조정이 있을 수 있습니다.<br></br>4. “회사”는 “회원사”에게 본
              “약관”에서 정해진 “매체이용대가”을 지급합니다. 이때 “매체이용대가”
              산정은 “회사”의 집계를 기준으로 하기로 합니다.<br></br>5.{" "}
              “회원사”는 매월 5일까지 직전 월 1개월 동안 발생한 “매체이용대가”에
              대해 “회사”의 집계기준을 근거로 “회사”에 청구하고, 전월 말일자로
              세금계산서를 발행하기로 합니다. “회사”는 “회원사”의 대금
              청구일로부터 30일 이내에 청구된 금액을 현금으로 결제하기로 합니다.
              <br></br>6. 단, “회원사”가 제9조(회원사의 의무)에 규정된
              “회원사”의 의무를 위반하거나 “회원사”의 이용자들이 “회사”가
              어뷰징으로 판단한 행동을 통해서 “광고상품”을 소진한 경우, “회사”는
              “회원사”에 사전 통지 후 이에 해당하는 “매체이용대가” 지급을 보류할
              수 있습니다. 이 경우, “회원사”는 “회사”의 통지가 있었던 날로부터
              30일 이내에 전술한 위반 사실이 없음을 주장 할 수 있으며, 상기 기한
              내에 이러한 주장이 제기되지 않거나 위반 사실이 없음을 객관적이고
              합리적인 근거를 토대로 입증하지 못하는 경우 보류된
              “매체이용대가”은 지급되지 않습니다.<br></br>7. “회사”는
              "콘텐츠플랫폼" 공급으로 발생한 대금의 수금 여부와 상관없이 제5항에
              명시된 지급조건에 따라 "매체이용대가"를 "회원사"에 지급하기로
              합니다.<br></br>8. "매체이용대가"는 본 조 제5항에 따라 청구할 수
              있었던 날로부터 5년 이내에 청구되지 아니하면 소멸하게 됩니다.
            </p>
            <br></br>
            <p>
              <strong>제11조 (“콘텐츠플랫폼”의 제공)</strong>
              <br></br>1. “회사”의 “콘텐츠플랫폼”은 “회사”의 업무상 또는 기술상
              특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다.
              <br></br>
              2. “회사”는 시스템 정기점검, 증설 및 교체를 위해 “회사”가 정한
              날이나 시간에 “플랫폼” 운영을 일시 중단할 수 있으며, 예정되어 있는
              작업으로 인한 “플랫폼”의 일시 중단은 “사이트”에 사전 공지하거나
              “통신수단”을 통해서 “회원사”에게 통지하기로 합니다.<br></br>3.
              “회사”는 긴급한 시스템 점검, 증설 및 교체 등 이에 준하는 부득이한
              사유로 인한 “콘텐츠플랫폼” 중단 시 “사이트”에 사후 공지하거나
              “통신수단”을 통해서 “회원사”에게 통지하기로 합니다.<br></br>4.
              기존의 “콘텐츠플랫폼”이 아닌 새로운 서비스로의 교체 등으로 인하여
              서비스를 완전히 중단하는 것에 대해서 “회사”는 “콘텐츠플랫폼” 중단
              30일 이전에 “사이트”에 공지하거나 “통신수단”을 통해서 “회원사”에게
              통지하기로 합니다.<br></br>5. “회사”는 천재지변, 국가비상사태,
              정전, 기간통신 사업자 또는 클라우드 사업자의 사고 및 장애,
              트래픽의 비정상적인 폭주 등 회사가 통제할 수 없는 사유로 정상적인
              “콘텐츠플랫폼” 운영이 불가능할 경우, “콘텐츠플랫폼”의 전부 또는
              일부를 제한하거나 중지할 수 있습니다. 이 경우, “회사”는 최대한
              빠른 시간 내에 “사이트”에 공지하거나 “통신수단”을 통해서
              “회원사”에게 통지하기로 하기로 합니다.<br></br>6. “콘텐츠플랫폼”을
              통해 수집된 정보는 “회사”의 소유이며, “콘텐츠플랫폼” 및 서비스가
              운용되는 서버의 관리, 유지, 보수, 교체 및 추가를 위해서 정보의
              보관 및 이전에 대한 권리는 “회사”에 속합니다.<br></br>7. “회사”는
              “회원사”가 “회사”의 “콘텐츠플랫폼” 및 서비스를 제공받는 과정에서
              “회사”의 서버에 저장되는 정보가 제3자에게 유출되지 않도록 비밀로
              관리하며, 임직원, 대리인, 상담역 등으로 하여금 누설하지 않도록
              필요한 모든 조치를 합니다. 단 “회사”는 “콘텐츠플랫폼”을 통해
              수집된 정보를 특정 “회원사” 또는 특정 “회원사”의 모바일
              어플리케이션이 식별되지 않는 범위에서 “콘텐츠플랫폼” 및 “회사”의
              홍보, 분석, 보도자료 또는 “콘텐츠플랫폼” 등 “회사”가 제공하는
              서비스의 개선을 위한 통계적인 목적으로 활용할 수 있습니다.
              <br></br>
              8. “회사”는 “회원사”에게 “콘텐츠플랫폼” 이용에 필요가 있다고
              인정되는 각종 정보 및 신규 기능과 서비스에 대한 정보를
              “통신수단”을 이용해서 “회원사”에게 제공할 수 있습니다.
            </p>
            <br></br>
            <p>
              <strong>제12조 (환불)</strong>
              <br></br>1. 회원사는 회사에 환불을 요구할 수 있습니다. 환불은
              회사가 안내하는 정책 및 방법에 따라 진행됩니다.<br></br>2. 환불은
              의뢰인이 광고포인트를 충전한 후 '보유중'인 금액의 반환을 요청하는
              것을 말합니다. 별도의 환불 수수료가 발생합니다.<br></br>-
              보유포인트 100% 환불을 원칙으로 합니다. (단, 의뢰인의 과실이나
              단순 변심, 광고 매체 규제 등으로 인한 불이익은 환불이 불가하오니
              이점에 유의해 주시기 바랍니다.)<br></br>- 의뢰인의 과실이라함은
              잘못된 정보를 기입함에 따라 이미 잘못된 정보로 광고가 등록이
              되었을 때를 말합니다.<br></br>- 광고 매체 규제라함은 불법적이거나
              음란한 정보를 등록하여 회사에 해를 끼쳤을 경우를 말합니다.
              <br></br>- 환불 수수료<br></br>- 단순 변심에 의한 요청 시
              보유포인트 금액의 35% 차감 (보너스포인트 포함)<br></br>-
              보너스포인트 지급 후 사용을 일부만 하였다 할지라도 보너스포인트를
              포함한 전체 포인트는 환불이 불가합니다.<br></br>- 내부 시스템
              문제로 7일 이내에 작업진행이 안될 때 0% (충전시 지급되었던
              보너스포인트만 공제함)<br></br>- 환불은 기본적으로 동일 결제
              수단을 통해 환불됩니다.<br></br>3. 보유 포인트 유효 기간은
              365일입니다.<br></br>- 365일 동안 보유한 포인트를 한번도 사용하지
              않은 보유 포인트를 말합니다.<br></br>- 365일 이전에 보유 포인트를
              사용 시 사용 일자 기준으로 365일이 연장됩니다.
            </p>
            <br></br>
            <p>
              <strong>제13조 (이용의 제한)</strong>
              <br></br>“회원사”가 다음 각 호의 하나에 해당되는 경우 사전 경고
              없이 “회원사”의 “플랫폼” 이용을 전부 또는 부분적으로 제한할 수
              있습니다.<br></br>- “회원사”가 “회사”에게 허위 정보를 제공한 경우
              <br></br>- “회원사”가 본 “약관” 상의 의무를 위반하여 “회사”로부터
              서면으로 시정 요구를 받은 후 정당한 사유 없이 7일 이내에 이를
              이행하지 않거나 시정을 완료하지 않을 경우<br></br>- “회원사”가 본
              “약관”의 의무를 이행하지 아니하거나 관련 법령을 위반함으로 인하여
              “회사” 또는 제3자에게 손해가 발생한 경우<br></br>- “회원사”가
              운영하는 “매체지면”의 이용자가 불법적이거나 “회사”가 어뷰징으로
              정의한 행위로 “회사”의 “콘텐츠플랫폼”을 남용하는 것을 묵인하는
              경우<br></br>- “회원사”가 법에 위반되는 내용을 고의로 유포시킨
              경우
              <br></br>- “회원사”가 공공질서 및 미풍양속에 저해되는 내용을
              고의로 유포시킨 경우<br></br>- “회원사”가 주요자산에 대해
              (가)압류, (가)처분, 체납처분 또는 이와 유사한 처분을 받거나 화의,
              회사 정리 절차 또는 파산의 신청이 있거나 당한 경우<br></br>-
              “회원사”가 발행한 어음이나 수표가 지급 정지된 경우<br></br>-
              “회원사”가 “콘텐츠플랫폼” 운영을 고의로 방해한 경우<br></br>-
              “회원사”가 1년 이상 “콘텐츠플랫폼”을 사용하지 않았을 경우 (예:
              이용자가 선택한 “콘텐츠플랫폼”이 없는 경우)<br></br>- “회원사”의
              “매체지면”에 3개월 이상 “콘텐츠플랫폼”이 전혀 노출되지 않는 경우
            </p>
            <br></br>
            <p>
              <strong>제14조 (휴면정책)</strong>
              <br></br>“회사”는 본 조의 휴면정책에 따라 “회원사”가 장기간
              “플랫폼”을 이용하지 않으면 정보통신망 이용촉진 및 정보보호 등에
              관한 법률에 따라 개인정보를 파기 또는 분리 보관 후 이용계약을
              해지할 수 있습니다.<br></br>- 1년 동안 “콘텐츠플랫폼” 접속 이력이
              없는 경우 휴면 전환되어 “회원사”의 개인정보를 분리 보관합니다.
              <br></br>- “회사”는 “회원사”가 휴면 상태로 전환되기 30일 전 이메일
              알림을 통해 사전에 안내합니다.<br></br>- 개인정보 분리 보관 이후
              4년 동안 로그인 하지 않을 경우, “콘텐츠플랫폼”에 등록된 개인정보를
              파기합니다. 휴면 전환 이후 회원은 “콘텐츠플랫폼” 로그인 시
              자동으로 휴면이 해제됩니다.
            </p>
            <br></br>
            <p>
              <strong>제15조 (지석재산권)</strong>
              <br></br>1. “콘텐츠플랫폼”의 적용을 위해 “회사”로부터 제공되는
              모든 문서의 저작권과 지식소유권은 “회사”에 있으며, 이 권리는
              대한민국의 저작권법과 국제 저작권 조약에 의해 보호됩니다.<br></br>
              2. “회원사”는 본 “약관”에 의거하여 “회사”로부터 “콘텐츠플랫폼”에
              대한 이용권한을 부여받는 것이며, 본 “약관”은 “회사”의
              “콘텐츠플랫폼”에 대한 소유권, 지식재산권 및 자산 등에 대한 어떠한
              권리이전도 의미하지 않습니다.<br></br>3. “콘텐츠플랫폼”의 소유,
              유통, 개발 등의 모든 권한은 “회사”에 있습니다.<br></br>4.
              “회원사”는 “콘텐츠플랫폼”을 본래의 목적에 맞게 사용되어야 하며,
              다음 각 호에 해당하는 행위를 하였을 경우, “회사”는 사전통지 없이
              이용을 전부 또는 부분적으로 제한하거나 또는 기간을 정하여
              “콘텐츠플랫폼”의 이용을 중지할 수 있으며, 이에 대한 손해배상을
              청구할 수 있습니다.<br></br>- “회원사”가 “콘텐츠플랫폼”을 제
              3자에게 제공하였을 경우<br></br>- “회원사”가 “콘텐츠플랫폼”을 본
              계약 목적 이외에 해킹, 식별가능한 개인정보 수집, 그 외에 본 계약
              명시되지 않은 각종 불법적인 목적으로 사용하였을 경우<br></br>-
              “회원사”가 “회사”의 동의 없이 “콘텐츠플랫폼”을 리버스엔지니어링,
              디컴파일, 디스어셈블 등의 행위를 한 경우<br></br>- “회원사”가
              “콘텐츠플랫폼”을 활용하여 다른 상품을 개발할 경우<br></br>-
              “회사”의 사전 서면승인 없이 “콘텐츠플랫폼” 및 관련 문서를 복제
              혹은 복사하여 배포하였을 경우
            </p>
            <br></br>
            <p>
              <strong>제16조 (면책)</strong>
              <br></br>1. “회사”의 면책: 아래 각 호의 하나에 해당하는 경우
              “회사”는 책임을 지지 않기로 한다.<br></br>a. 천재지변, 폭동, 전쟁,
              정부의 규제 등 사회 통념상 불가항력적 사유로 인하여 정상적인
              “콘텐츠플랫폼” 운영이 불가능할 경우<br></br>b. 국가비상사태, 정전,
              기간통신 사업자 또는 클라우드 사업자의 사고 및 장애, 트래픽의
              비정상적인 폭주 등 회사가 통제할 수 없는 사유로 정상적인
              “콘텐츠플랫폼” 운영이 불가능할 경우<br></br>c. “콘텐츠플랫폼” 관련
              설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 손해가
              발생하는 경우<br></br>d. “회원사”의 컴퓨터 오류에 의해 손해가
              발생한 경우<br></br>e. “회원사”가 신상정보 및 전자우편 주소를
              부실하게 기재하여 손해가 발생한 경우<br></br>f. “회원사”가
              “콘텐츠플랫폼”을 이용하여 기대하는 수익을 얻지 못하거나 상실하는
              경우<br></br>g. “회원사”가 “콘텐츠플랫폼”을 이용하면서 얻은 자료로
              인해 손해가 발생하는 경우<br></br>h. “회원사”가 “콘텐츠플랫폼”에
              게재한 각종 정보, 자료, 사실의 신뢰도나 정확성 등 “회원사”가 직접
              게재한 정보로 인해 제3자와 분쟁이 발생하거나 이로 인한 손해가
              발생하는 경우<br></br>i. “회사”의 고의나 중과실이 없이,
              “회원사”에게 무료로 제공하는 “회사”의 “콘텐츠플랫폼“과 관련하여
              손해가 발생하는 경우<br></br>j. “회원사” 상호간 또는 “회원사”와
              제3자 상호간에 “회사”의 “콘텐츠플랫폼”을 매개로 발생한 분쟁이
              발생하거나 이로 인한 손해가 발생하는 경우<br></br>2. “회원사”의
              면책: 아래 각 호의 하나에 해당하는 경우 “회원사”는 책임을 지지
              않기로 한다.<br></br>- “회사”에서 제공하여 “회원사”의 “매체지면”에
              노출되는 콘텐츠 중 저작권에 위배되는 사항이 발생할 경우<br></br>-
              “회원사”의 “매체지면”에 노출되는 콘텐츠가 “회사”와 계약한 효과
              미약 등의 이유로 별도의 조정 내지 미지급되는 경우
            </p>
            <br></br>
            <p>
              <strong>제17조 (기밀유지)</strong>
              <br></br>1. “회원사”는 다음의 각 호의 하나에 해당하는 경우를
              제외하고는 “회사”의 사업상 정보, 노하우 또는 영업상 비밀 등 일체의
              정보(이하 “비밀정보”라 함)를 비밀로 하고, “비밀정보"를 제3자에게
              제공하거나 다른 목적으로 사용할 수 없으며, 종업원, 대리인, 상담역
              등으로 하여금 누설하지 않도록 필요한 모든 조치를 하여야 합니다
              <br></br>a. “회사”가 공개하기로 합의한 사항<br></br>b. 공지된 정보
              <br></br>c. 정보를 제공받은 “회원사”가 제3자로부터 이미 적법하게
              취득한 정보
              <br></br>d. 법원 또는 정부기관이 적법하게 공개 또는 제공을 요구한
              정보 (단, 사전에 “회사”에게 서면으로 통지하여야 합니다)<br></br>2.
              모든 “비밀정보”는 “회사”의 독점적 소유이며, “비밀정보”의 제공은
              이에 대한 지식재산권의 실시권 또는 사용권을 “회원사”에게 허여하는
              것이 아님을 확인합니다.<br></br>3. 본 조의 의무는 “약관”이 해지된
              이후 3년간 유효하게 적용되며, 본 조의 위반으로 “회사”에게 손해가
              발생한 경우에는 “회원사”는 이에 대한 모든 손해를 배상하여야
              합니다.
            </p>
            <br></br>
            <p>
              <strong>제18조 (분쟁해결)</strong>
              <br></br>1. 본 “약관”은 대한민국 법률에 따라 규율 되고 해석됩니다.
              <br></br>2. 본 “약관”과 관련하여 발생한 모든 분쟁은
              서울중앙지방법원을 관할법원으로 합니다.
            </p>
            <br></br>
            <p>
              <strong>제19조 (기타)</strong>
              <br></br>1. 본 “약관”에 명시되지 아니한 사항에 대해서는 전자문서
              및 전자거래기본법, 정보통신망이용촉진 및 정보보호 등에 관한 법률
              등 관련법령 및 상관례에 따르기로 하며, “회원사”와 “회사”간 별도의
              계약 사항이 있는 경우 해당 내용을 준용 합니다.<br></br>2. 본
              “약관”이 한국어 이외의 언어로 번역되어 제공됐을 때에, 번역 및
              해석의 오차가 발생할 경우 한국어를 우선으로 하기로 합니다.
            </p>
            <br></br>
            <p>
              <strong>부칙</strong>
              <br></br>본 이용약관은 2024년 01월 16일부터 시행합니다.
            </p>
          </ContentInfoInner>
        </ContentInfo>
      </Content>
    </Wrapper>
  );
};

export default TermsAndConditionsPage;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  color: #353535;
  font-size: 18px;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Content = styled.div`
  margin: 90px auto 0 auto;
  box-sizing: border-box;
`;

const ContentInfo = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 0 150px 0;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    padding: 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0 60px;
  }
`;

const ContentInfoInner = styled.div`
  line-height: 26px;
  padding-bottom: 50px;
  margin-top: 90px;
  letter-spacing: -1.5px;
  font-size: 15px;
  border-bottom: 1px solid #464646;
  margin-bottom: 130px;
  font-family: "Noto Sans Korean";
  font-weight: 400;
  color: #656565;
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;

  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 102px;
  }
`;

const ContentInfoInnerTitle = styled.h1`
  font-size: 2.1875em;
  line-height: 49px;
  font-family: HelveticaNeueLT-Bold, "Noto Sans Korean Bold",
    HelveticaNeueLT-Medium, "Noto Sans Korean Regular", AppleSDGothic,
    "Malgun Gothic", "Gulim", sans-serif;
  font-weight: normal;
  margin: 0.67em 0;
  box-sizing: border-box;
  font-weight: 700;

  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 33px;
  }
`;

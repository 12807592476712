import styled from "styled-components";

export const HoverUrlDiv = styled.div`
  text-decoration: underline;
  text-decoration-color: #222;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

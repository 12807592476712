import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/login_page/LoginPage";
import SignUpPage from "../pages/sign_up_page/SignUpPage";
import { PrivateRoute } from "./private_route/PrivateRoute";
import MainPage from "../pages/main_page/MainPage";
import {
  best_all_inner_router_url,
  home_inner_router_url,
  login_inner_router_url,
  marketing_policy_inner_router_url,
  my_page_inner_router_url,
  point_charge_inner_router_url,
  point_history_inner_router_url,
  privacy_policy_inner_router_url,
  refund_policy_inner_router_url,
  sign_up_inner_router_url,
  terms_and_conditions_inner_router_url,
} from "../communication_system/inner_router_url/inner_router_url";
import HomePage from "../pages/home_page/HomePage";
import PointHistoryPage from "../pages/point_history_page/PointHistoryPage";
import PointChargePage from "../pages/point_charge_page/PointChargePage";
import { sidebar_data } from "../data_system/data/sidebar_data/sidebar_data";
import TermsAndConditionsPage from "../pages/terms_and_conditions_page/TermsAndConditionsPage";
import PrivacyPolicyPage from "../pages/privacy_policy_page/PrivacyPolicyPage";
import RefundPolicyPage from "../pages/refund_policy_page/RefundPolicyPage";
import MyPage from "../pages/my_page/MyPage";
import MarketingPolicyPage from "../pages/marketing_policy_page/MarketingPolicyPage";

const TotalRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage></MainPage>}></Route>
        <Route path={login_inner_router_url} element={<LoginPage />}></Route>
        <Route path={sign_up_inner_router_url} element={<SignUpPage />}></Route>
        <Route
          path={terms_and_conditions_inner_router_url}
          element={<TermsAndConditionsPage />}
        ></Route>
        <Route
          path={privacy_policy_inner_router_url}
          element={<PrivacyPolicyPage />}
        ></Route>
        <Route
          path={refund_policy_inner_router_url}
          element={<RefundPolicyPage />}
        ></Route>
        <Route
          path={marketing_policy_inner_router_url}
          element={<MarketingPolicyPage />}
        ></Route>

        <Route path={best_all_inner_router_url} element={<PrivateRoute />}>
          <Route path={home_inner_router_url} element={<HomePage></HomePage>} />
          <Route path={my_page_inner_router_url} element={<MyPage></MyPage>} />
          <Route
            path={point_history_inner_router_url}
            element={<PointHistoryPage></PointHistoryPage>}
          />
          <Route
            path={point_charge_inner_router_url}
            element={<PointChargePage></PointChargePage>}
          />
          {sidebar_data.map((main) => {
            if (main["router_url"] !== null) {
              console.log(main["router_url"]);
              return (
                <Route
                  key={main["mainId"]}
                  path={main["router_url"]}
                  element={main["components"]}
                ></Route>
              );
            }

            return main.subTitleList.map((sub) => {
              return (
                <Route
                  key={sub["subId"]}
                  path={sub["router_url"]}
                  element={sub["components"]}
                ></Route>
              );
            });
          })}
        </Route>
      </Routes>
    </Router>
  );
};

export default TotalRouter;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalStyleFonts from "./design_system/fonts/fonts";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: none;
  }

  html, body {
    overflow-x: hidden;
  }
`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalStyleFonts />
    <GlobalStyle />
    <App />
  </React.StrictMode>
);

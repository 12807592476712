import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../data_system/context/auth_context/AuthContext";
import {
  best_home_inner_router_url,
  login_inner_router_url,
  sign_up_inner_router_url,
} from "../../communication_system/inner_router_url/inner_router_url";

const LoginPage = () => {
  let navigate = useNavigate();

  const { handleLogin, logout } = useAuth();

  // const [userType, setUserType] = useState("");

  // const handleSelectChange = (event) => {
  //   setUserType(event.target.value);
  // };

  const [emailText, setEmailText] = useState("");
  const [passwordText, setPasswordText] = useState("");

  const totalHandleLogin = async () => {
    const response = await handleLogin(emailText, passwordText);

    console.log(response);
    if (parseInt(response.status) === 200) {
      setEmailText("");
      setPasswordText("");
      navigate(best_home_inner_router_url);
    } else if (parseInt(response.status) === 400) {
      alert(
        "가입이 되지 않은 계정이거나 이메일 또는 비밀번호가 일치하지 않습니다."
      );
    } else if (parseInt(response.status) === 401) {
      alert("승인이 필요한 계정입니다. 관리자에게 문의하세요.");
    } else if (parseInt(response.status) === 403) {
      alert("차단된 계정입니다. 관리자에게 문의하세요.");
    }
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Wrapper>
      <Header>
        <HeaderBrandName>
          <HeaderBrandNameA href="/">moneymoa</HeaderBrandNameA>
        </HeaderBrandName>
        <HeaderBtnSection>
          <ChangeBtn
            onClick={() => {
              navigate(login_inner_router_url);
            }}
          >
            로그인
          </ChangeBtn>
          <HeaderBtnMargin>
            <ChangeBtn
              onClick={() => {
                navigate(sign_up_inner_router_url);
              }}
            >
              시작하기
            </ChangeBtn>
          </HeaderBtnMargin>
        </HeaderBtnSection>
      </Header>
      <Main>
        <Container>
          <InnerMain>
            <MainWrap>
              <MainWrapH2>머니모아 로그인</MainWrapH2>
              <Form>
                {/* <JoinWrapDiv>
                  <JoinWrapDivLabel>회원 유형</JoinWrapDivLabel>
                  <JoinWrapDivSelect
                    id="userType"
                    name="userType"
                    onChange={handleSelectChange}
                    value={userType}
                  >
                    <option value="">선택하세요</option>
                    <option value="individual">개인</option>
                    <option value="individual_business">개인사업자</option>
                    <option value="corp_business">법인사업자</option>
                  </JoinWrapDivSelect>
                </JoinWrapDiv> */}
                <JoinWrapDiv>
                  <JoinWrapDivLabel>계정이메일</JoinWrapDivLabel>
                  <JoinWrapDivInput
                    type="email"
                    value={emailText}
                    onChange={(e) => {
                      setEmailText(e.target.value);
                    }}
                  ></JoinWrapDivInput>
                </JoinWrapDiv>
                <JoinWrapDiv>
                  <JoinWrapDivLabel>비밀번호</JoinWrapDivLabel>
                  <JoinWrapDivInput
                    type="password"
                    value={passwordText}
                    onChange={(e) => {
                      setPasswordText(e.target.value);
                    }}
                  ></JoinWrapDivInput>
                </JoinWrapDiv>
                <BtnPart>
                  <YellowBtn
                    onClick={async () => {
                      await totalHandleLogin(emailText, passwordText);
                    }}
                  >
                    {/* {userType === "" ? "회원 유형을 선택해주세요" : "로그인"} */}
                    로그인
                  </YellowBtn>
                </BtnPart>
              </Form>
            </MainWrap>
          </InnerMain>
        </Container>
      </Main>
    </Wrapper>
  );
};

export default LoginPage;

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #dac102;
  background-color: #ffe100;
`;

const HeaderBrandName = styled.h1`
  float: left;
  display: block;
  margin: 0 0 10px 0;
  padding: 23px 49px;
  line-height: 1;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
    font-size: 30px;
  }

  @media only screen and (max-width: 400px) {
    font-size: 26px;
  }
`;

const HeaderBrandNameA = styled.a`
  display: block;
  text-decoration: none;
  color: #353535;
`;

const HeaderBtnSection = styled.div`
  display: flex;
  align-items: center;
  padding: 23px 49px;

  @media only screen and (max-width: 767px) {
    padding: 23px 10px;
  }
`;

const HeaderBtnMargin = styled.div`
  margin-left: 20px;

  @media only screen and (max-width: 767px) {
    margin-left: 10px;
  }
`;

const ChangeBtn = styled.button`
  display: flex;
  color: #353535;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #ddb20b;
  border: 1px solid #ddb20b;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 25px;

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: #ddb20b;
    background-color: #353535;
    border: 1px solid #353535;
  }

  @media only screen and (max-width: 767px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }
`;

const Wrapper = styled.div`
  background-color: #353535;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Main = styled.div`
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Pretendard";
`;

const Container = styled.div`
  width: 1000px;
  height: 80vh;
  background-color: #ffffff90;
  border-radius: 20px;
  position: relative;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const InnerMain = styled.div`
  width: 50%;
  height: 90%;
  margin: 50px;
  box-sizing: border-box;
`;

const MainWrap = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const MainWrapH2 = styled.h2`
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 1.7rem;
`;

const Form = styled.div`
  width: 85%;
  height: auto;
  box-sizing: border-box;
`;

const BtnPart = styled.div`
  border: none;
  background-color: transparent;
  margin-bottom: 0;
  display: flex;
  line-height: 45px;
  overflow: hidden;
`;

const YellowBtn = styled.button`
  padding: 0;
  margin-right: 0;
  height: 50px;
  line-height: 55px;
  background-color: #ffe100;
  color: #353535;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3px;
  border-radius: 5px;
  flex-grow: 1;
  cursor: pointer;
  width: 100%;
`;

const JoinWrapDiv = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const JoinWrapDivLabel = styled.label`
  font-weight: bold;
  font-size: 1rem;
  width: 170px;
`;

const JoinWrapDivInput = styled.input`
  height: 30px;
  padding: 5px;
  border: 1px solid #9f9f9f;
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const JoinWrapDivSelect = styled.select`
  height: 30px;
  padding: 5px;
  border: 1px solid #9f9f9f;
  background-color: #ffffff;
  border-radius: 5px;
  width: calc(100% - 10px); // padding을 고려하여 width 조정

  &:focus {
    outline: none;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { TipTextBox } from "../../../../design_system/components/tip_text_box/TipTextBox";
import { TipTextBody } from "../../../../design_system/components/tip_text_body/TipTextBody";
import { TipTextTitle } from "../../../../design_system/components/tip_text_title/TipTextTitle";
import { BigRoundBrownBtn } from "../../../../design_system/components/big_round_brown_btn/BigRoundBrownBtn";
import { useAdministratorAdvertisementProduct } from "../../../../data_system/context/administrator_advertisement_product_context/AdministratorAdvertisementProductContext";
import { isOnlyNumber } from "../../../../data_system/data_function/is_only_number";
import { postNStoreTrafficPriceComparison } from "../../../../communication_system/axios_apis/axios_apis";
import StandardModal from "../../../../design_system/components/modal/standard_modal/StandardModal";
import { TipText } from "../../../../design_system/components/tip_text/TipText";
import { isValidHttpUrl } from "../../../../data_system/data_function/is_valid_http_url";

const NStoreTrafficPriceComparisonPostTab = () => {
  const { administratorAdvertisementProducts } =
    useAdministratorAdvertisementProduct();

  const [storeName, setStoreName] = useState("");
  const [userSearchKeyword, setUserSearchKeyword] = useState("");
  const [entryMid, setEntryMid] = useState("");
  const [arrivalMid, setArrivalMid] = useState("");
  const [productId, setProductId] = useState("");
  const [nextTargetDailyWorkload, setNextTargetDailyWorkload] = useState("");

  const [
    showPostAdvertiserAdvertisementProductCheckModal,
    setShowPostAdvertiserAdvertisementProductCheckModal,
  ] = useState(false);

  return (
    <>
      <>
        <>
          <ProductPostDivTitle>스토어명 *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="스토어명을 입력해주세요."
                value={storeName}
                onChange={(e) => {
                  setStoreName(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>유저 검색 키워드 *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="3페이지 안으로 나오도록 유저 검색 키워드를 입력해주세요."
                value={userSearchKeyword}
                onChange={(e) => {
                  setUserSearchKeyword(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>원부 상품 MID *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="원부 상품 MID 값"
                value={entryMid}
                onChange={(e) => {
                  setEntryMid(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>최종 도착 상품 MID *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="최종 도착 상품 MID 값"
                value={arrivalMid}
                onChange={(e) => {
                  setArrivalMid(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>상품 ID *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="상품 ID"
                value={productId}
                onChange={(e) => {
                  setProductId(e.target.value);
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>일일 목표 작업량 *</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                placeholder="1 이상으로 입력(최소 1건 이상)"
                value={nextTargetDailyWorkload}
                onChange={(e) => {
                  if (
                    (parseInt(e.target.value) >= 1 &&
                      isOnlyNumber.test(e.target.value)) ||
                    e.target.value === ""
                  ) {
                    setNextTargetDailyWorkload(e.target.value);
                  }
                }}
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <>
          <ProductPostDivTitle>광고 단가</ProductPostDivTitle>
          <ProductPostDivFrame>
            <InputFrame>
              <ProductPostInput
                value={`${administratorAdvertisementProducts[1].nextAdvertiserPrice}원`}
                disabled
              ></ProductPostInput>
            </InputFrame>
          </ProductPostDivFrame>
        </>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <BigRoundBrownBtn
            onClick={() => {
              if (storeName === "") {
                alert("스토어명을 입력해주세요.");
              } else {
                if (userSearchKeyword === "") {
                  alert("유저 검색 키워드를 입력해주세요.");
                } else {
                  if (entryMid === "") {
                    alert("원부 상품 MID 값을 입력해주세요.");
                  } else {
                    if (arrivalMid === "") {
                      alert("최종 도착 상품 MID 값을 입력해주세요.");
                    } else {
                      if (productId === "") {
                        alert("상품 ID를 입력해주세요.");
                      } else {
                        if (nextTargetDailyWorkload === "") {
                          alert("일일 목표 작업량을 입력해주세요.");
                        } else {
                          if (parseInt(nextTargetDailyWorkload) < 1) {
                            alert(
                              "일일 목표 작업량은 1 이상으로 입력해주세요."
                            );
                          } else {
                            if (!isValidHttpUrl(entryMid)) {
                              if (!isValidHttpUrl(arrivalMid)) {
                                setShowPostAdvertiserAdvertisementProductCheckModal(
                                  true
                                );
                              } else {
                                alert(
                                  "최종 도착 상품 MID 형식을 확인해주세요."
                                );
                              }
                            } else {
                              alert("윈부 상품 MID 형식을 확인해주세요.");
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }}
          >
            광고 등록하기
          </BigRoundBrownBtn>
        </div>
        <TipTextBox>
          <TipTextTitle>TIP.</TipTextTitle>
          <TipTextBody>
            <TipText>
              상품유형 선택: 진행할 상품을 선택 후 등록해주시면 됩니다.
            </TipText>
            <TipText>
              유저 검색 키워드: 유저가 검색해서 N스토어 상품을 찾을 수 있는
              키워드를 입력해주세요. 꼭 3페이지 안에 나와야 합니다. 15자 이내로
              작성해주세요.
            </TipText>
            <TipText>
              원부 상품 MID: 초기에 진입할 상품 URL을 입력해주세요.
            </TipText>
            <TipText>
              최종 도착 상품 MID: 마지막에 도착할 최종 상품 MID를 입력해주세요.
            </TipText>
            <TipText>
              일일 목표 작업량: 하루에 몇 건의 작업량을 목표로 하는지의
              수치입니다. 1 이상으로 입력해주세요(최소 1건 이상). 일일 목표
              작업량은 하루에 얼마나 광고를 집행할지 결정하는 수치입니다.
            </TipText>
            <TipText>진행순서: 광고 등록하기 &#62; 진행</TipText>
            <TipText>
              주의 및 참고사항:<br></br>1. 일일 목표 작업량은 서버 점검 시간
              이외에 언제든지 변경할 수 있습니다.<br></br>2. 당일 저녁 11시 전에
              다음 날 작업을 진행하지 않도록 광고 멈춤 설정을 할 수 있습니다.
              <br></br>
              3. 당일 등록한 광고는 당일 저녁 11시에 시작하는 서버 점검 기간부터
              작업합니다.
              <br></br>4. 매일 저녁 11시부터 다음 날 새벽 1시까지는 서버
              점검으로 광고 등록 및 관리가 불가능합니다.<br></br>
              5. 저녁 11시부터 다음 날 새벽 1시까지 서버 점검 동안 다음 날
              광고를 서버에서 유저에게 전달합니다. 즉, 이미 저녁 11시부터 광고가
              전달되었으므로 저녁 11시부터 환불이 불가능합니다.
              <br></br>6. 일일 목표 작업량을 당일 전부 수행하지 못 하면 당일
              저녁 11시부터 새벽 1시까지 점검 기간 동안 수행하지 못 한 작업량은
              포인트로 환불됩니다.<br></br>7. 잘못된 정보 등록으로 등록 광고의
              평균 작업 속도가 해당 광고의 평균 작업 속도 보다 현저하게 느리다면
              자동으로 광고가 중단됩니다.
              <br></br>8. 잘못된 정보로 등록하여 작업된 광고는 환불이
              불가능합니다.
            </TipText>
          </TipTextBody>
        </TipTextBox>
        <div
          style={{
            height: 20,
          }}
        ></div>
      </>
      {showPostAdvertiserAdvertisementProductCheckModal && (
        <StandardModal
          onClose={() => {
            setShowPostAdvertiserAdvertisementProductCheckModal(false);
          }}
          titleText={"광고 등록 전 확인!"}
          modalBodyComponent={
            <>
              <div>
                입력 내용이 맞는지 확인해주세요!<br></br>
                <br></br>잘못된 정보로 인한 광고 등록은 환불이 불가능합니다.
                <br></br>
                <br></br>한번 입력된 정보는 수정이 불가능합니다. 다만, 일일 목표
                작업량과 광고 실행 및 중단 변경은 가능합니다.
                <br></br>
                <br></br>광고를 등록하시겠습니까?<br></br>
                <TipTextBox>
                  <TipTextBody>
                    <TipTextTitle>포인트 충전 확인!</TipTextTitle>
                    <TipText>
                      포인트 충전을 확인해주세요. 포인트가 부족할 때 자동으로
                      광고가 중단됩니다.
                    </TipText>
                    <TipText>
                      당일 등록된 광고는 다음 날부터 광고가 집행됩니다.
                    </TipText>
                  </TipTextBody>
                </TipTextBox>
              </div>
            </>
          }
          rightBtnText={"확인"}
          onRightBtnClick={async () => {
            const response = await postNStoreTrafficPriceComparison(
              storeName.trim(),
              userSearchKeyword.trim(),
              entryMid.trim(),
              arrivalMid.trim(),
              productId.trim(),
              0,
              0,
              0,
              parseInt(nextTargetDailyWorkload),
              0,
              0,
              parseInt(
                administratorAdvertisementProducts[1].nextAdvertiserPrice
              ),
              "",
              2,
              1,
              2,
              null
            );

            if (response.status === 201) {
              setStoreName("");
              setUserSearchKeyword("");
              setEntryMid("");
              setArrivalMid("");
              setProductId("");
              setNextTargetDailyWorkload(1);
              setShowPostAdvertiserAdvertisementProductCheckModal(false);
              alert("광고가 성공적으로 등록되었습니다.");
            }
          }}
        ></StandardModal>
      )}
    </>
  );
};

export default NStoreTrafficPriceComparisonPostTab;

const ProductPostDivFrame = styled.div`
  flex-wrap: wrap;
  display: flex;
  line-height: 40px;
  align-items: stretch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ProductPostDivTitle = styled.div`
  font-weight: bold;
  font-size: 1rem;
`;

const InputFrame = styled.div`
  background-color: #fff;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 0px 10px;
  line-height: 40px;
`;

const ProductPostInput = styled.input`
  font-size: 0.9rem;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    outline: none;
    cursor: not-allowed;
  }
`;

import styled from "styled-components";

export const SmallBrownBtn = styled.button`
  background-color: #ddb20b;
  color: #fff;
  padding: 6px 15px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
`;

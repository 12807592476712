import React, { createContext, useContext, useState, useEffect } from "react";
import { getAdministratorAdvertisementProduct } from "../../../communication_system/axios_apis/axios_apis";
import { useAuth } from "../auth_context/AuthContext";

const AdministratorAdvertisementProductContext = createContext();

export const useAdministratorAdvertisementProduct = () => {
  return useContext(AdministratorAdvertisementProductContext);
};

export const AdministratorAdvertisementProductProvider = ({ children }) => {
  const [
    administratorAdvertisementProducts,
    setAdministratorAdvertisementProducts,
  ] = useState(null);
  const { isLoggedIn } = useAuth();

  const fetchAdministratorAdvertisementProduct = async () => {
    try {
      const response = await getAdministratorAdvertisementProduct();
      console.log(
        "response.data.administratorAdvertisementProducts:",
        response.data.administratorAdvertisementProducts
      );
      setAdministratorAdvertisementProducts(
        response.data.administratorAdvertisementProducts
      );
    } catch (error) {
      console.error(
        "Error while fetching administratorAdvertisementProducts:",
        error
      );
    }
  };

  const refetchAdministratorAdvertisementProduct = () => {
    if (isLoggedIn) {
      fetchAdministratorAdvertisementProduct();
    }
  };

  return (
    <AdministratorAdvertisementProductContext.Provider
      value={{
        administratorAdvertisementProducts,
        setAdministratorAdvertisementProducts,
        refetchAdministratorAdvertisementProduct,
      }}
    >
      {children}
    </AdministratorAdvertisementProductContext.Provider>
  );
};

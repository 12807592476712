import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { numberFormatFunction } from "../../../data_system/data_function/number_format_function";
import { FaCheck } from "../../../design_system/icons/icons";
import {
  privacy_policy_inner_router_url,
  refund_policy_inner_router_url,
  terms_and_conditions_inner_router_url,
} from "../../../communication_system/inner_router_url/inner_router_url";
import { postAdvertiserChargePointHistory } from "../../../communication_system/axios_apis/axios_apis";
import { useLoading } from "../../../data_system/context/loading_context/LoadingContext";
import { useAdvertiserAccount } from "../../../data_system/context/advertiser_account_context/AdvertiserAccountContext";

const PointChargeChargeTab = () => {
  const { advertiserAccount } = useAdvertiserAccount();
  const { showLoading, hideLoading } = useLoading();

  const [selectedPointId, setSelectedPointId] = useState(null);

  const selectPoint = (id) => {
    setSelectedPointId(id);
  };

  const selectPointList = [
    {
      id: 0,
      point: 50000,
      bonus: 50,
    },
    {
      id: 1,
      point: 100000,
      bonus: 200,
    },
    {
      id: 2,
      point: 500000,
      bonus: 2000,
    },
    {
      id: 3,
      point: 1000000,
      bonus: 10000,
    },
    {
      id: 4,
      point: 2000000,
      bonus: 80000,
    },
    {
      id: 5,
      point: 5000000,
      bonus: 400000,
    },
    {
      id: 6,
      point: 10000000,
      bonus: 1200000,
    },
    {
      id: 7,
      point: 20000000,
      bonus: 2800000,
    },
    {
      id: 8,
      point: 50000000,
      bonus: 8000000,
    },
  ];

  const [isNecessaryAgreeChecked, setIsNecessaryAgreeChecked] = useState(false);

  const handleNecessaryAgreeCheckboxChange = (e) => {
    setIsNecessaryAgreeChecked(e.target.checked);
  };

  const [depositorInfo, setDepositorInfo] = useState("");

  useEffect(() => {
    async function getData() {
      showLoading();

      setDepositorInfo(
        advertiserAccount === null ? "" : advertiserAccount.businessName
      );

      hideLoading();
    }

    getData();
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "70%",
          marginRight: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            flexShrink: 0,
            fontSize: "0.9rem",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "stretch",
            padding: "0 10px 10px 10px",
            backgroundColor: "#fff",
            border: "1px solid rgba(221, 178, 11, 0.3)",
          }}
        >
          <ChargeTitle>충전 포인트 선택</ChargeTitle>
          <HasPointSection>
            <div
              style={{
                padding: "0.5rem 0.75rem",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              보유 포인트{" "}
              <div
                style={{
                  color: "#ff4356",
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  letterSpacing: "-1px",
                  marginLeft: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                {advertiserAccount === null
                  ? 0
                  : numberFormatFunction(advertiserAccount.leftPoint)}
                <div
                  style={{
                    fontStyle: "normal",
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    color: "#404040",
                    marginLeft: "0.25rem",
                  }}
                >
                  포인트
                </div>
              </div>
            </div>
          </HasPointSection>
          <BuyList>
            <BuyListHeader>
              <p
                style={{
                  width: "8%",
                }}
              ></p>
              <p
                style={{
                  verticalAlign: "baseline",
                  color: "#707070",
                  fontSize: "0.9rem",
                  width: "35%",
                  flexGrow: 1,
                  textAlign: "right",
                  flexShrink: 0,
                  fontWeight: 600,
                }}
              >
                충전 포인트
              </p>
              <p
                style={{
                  verticalAlign: "baseline",
                  color: "#707070",
                  fontSize: "0.9rem",
                  width: "35%",
                  flexGrow: 1,
                  textAlign: "right",
                  flexShrink: 0,
                  fontWeight: 600,
                }}
              >
                보너스 적립
              </p>
            </BuyListHeader>
            {selectPointList.map((point) => {
              return (
                <div
                  key={point.id}
                  onClick={() => {
                    selectPoint(point.id);
                  }}
                  style={{
                    display: "flex",
                    listStyle: "none",
                  }}
                >
                  <PointTile
                    isselected={selectedPointId === point.id ? "true" : "false"}
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      padding: "1.25rem 2rem 1.25rem 0",
                      borderRadius: "3rem",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        width: "8%",
                        textAlign: "center",
                        flexGrow: 1,
                        flexShrink: 0,
                        fontWeight: 600,
                        color: "#404040",
                      }}
                    >
                      <em
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          display: "inline-block",
                          borderRadius: "50%",
                          backgroundColor:
                            selectedPointId === point.id
                              ? "rgba(221, 178, 11, 1)"
                              : "rgba(221, 178, 11, 0.3)",
                          color: "#fff",
                          padding: "0.2rem",
                        }}
                      >
                        <FaCheck></FaCheck>
                      </em>
                    </p>
                    <p
                      style={{
                        verticalAlign: "baseline",
                        width: "35%",
                        flexGrow: 1,
                        textAlign: "right",
                        flexShrink: 0,
                        fontWeight: 600,
                        color: "#404040",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1.15rem",
                          marginRight: "0.25rem",
                          fontWeight: "bold",
                          letterSpacing: "-1px",
                          textAlign: "right",
                          color: "#404040",
                        }}
                      >
                        {numberFormatFunction(point.point)}
                      </span>
                      포인트
                    </p>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        width: "35%",
                        flexGrow: 1,
                        textAlign: "right",
                        flexShrink: 0,
                        fontWeight: 600,
                        color: "#404040",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          marginRight: "0.25rem",
                          letterSpacing: "-1px",
                          color: "rgba(221, 178, 11, 1)",
                        }}
                      >
                        +{numberFormatFunction(point.bonus)}(
                        {((point.bonus / point.point) * 100).toFixed(1)}%)
                      </span>
                      포인트
                    </p>
                  </PointTile>
                </div>
              );
            })}
          </BuyList>
        </div>
      </div>
      <div
        style={{
          width: "30%",
        }}
      >
        <div
          style={{
            width: "100%",
            flexShrink: 0,
            fontSize: "0.9rem",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "stretch",
            padding: "0 10px 10px 10px",
            height: "auto",
            backgroundColor: "#fff",
            border: "1px solid rgba(221, 178, 11, 0.3)",
            marginBottom: "10px",
          }}
        >
          <ChargeTitle>결제정보</ChargeTitle>
          <div
            style={{
              backgroundColor: "#353535",
              borderRadius: "0.5rem",
              margin: "0 10px",
              flexGrow: 1,
              padding: "0.5rem 0",
              listStyle: "none",
            }}
          >
            <div
              style={{
                display: "block",
                textAlign: "center",
                fontWeight: 400,
                fontSize: "0.9rem",
                marginBottom: "0.5rem",
                width: "100%",
                color: "#fff",
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            >
              포인트 금액
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                color: "#fff",
                fontWeight: 400,
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            >
              충전 포인트
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "#fff",
                }}
              >
                <span
                  style={{
                    fontSize: "1.1rem",
                    marginRight: "0.25rem",
                    letterSpacing: "-1px",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  {numberFormatFunction(
                    selectedPointId === null
                      ? 0
                      : selectPointList[selectedPointId].point
                  )}
                </span>
                포인트
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                color: "#fff",
                fontWeight: 400,
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            >
              충전보너스
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "#fff",
                }}
              >
                <span
                  style={{
                    fontSize: "1.1rem",
                    marginRight: "0.25rem",
                    letterSpacing: "-1px",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  +
                  {numberFormatFunction(
                    selectedPointId === null
                      ? 0
                      : selectPointList[selectedPointId].bonus
                  )}
                </span>
                포인트
              </p>
            </div>
            <div
              style={{
                borderBottom: "1px solid #cdcdcd",
                margin: "0 1rem",
                width: "calc(100% - 2rem)",
                display: "flex",
                justifyContent: "space-between",
                color: "#fff",
                fontWeight: 400,
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            ></div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                color: "#fff",
                fontWeight: 400,
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            >
              총 충전 포인트
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "#fff",
                }}
              >
                <span
                  style={{
                    fontSize: "1.1rem",
                    marginRight: "0.25rem",
                    letterSpacing: "-1px",
                    color: "rgba(221, 178, 11, 1)",
                    fontWeight: 600,
                  }}
                >
                  +
                  {numberFormatFunction(
                    selectedPointId === null
                      ? 0
                      : selectPointList[selectedPointId].point +
                          selectPointList[selectedPointId].bonus
                  )}
                </span>
                포인트
              </p>
            </div>
          </div>
          <div
            style={{
              borderTop: "1px dotted rgb(211, 219, 236)",
              marginTop: "0.5rem",
              flexGrow: 1,
              padding: "0.5rem 0",
              listStyle: "none",
            }}
          >
            <div
              style={{
                margin: "0 10px",
                width: "calc(100% - 20px)",
                display: "block",
                textAlign: "center",
                fontWeight: 400,
                fontSize: "0.9rem",
                color: "#707070",
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            >
              결제금액
            </div>
            <div
              style={{
                margin: "0 10px",
                width: "calc(100% - 20px)",
                display: "flex",
                justifyContent: "space-between",
                color: "#707070",
                fontWeight: 400,
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            >
              합계금액
              <p
                style={{
                  fontSize: "0.9rem",
                  color: "#404040",
                }}
              >
                <span
                  style={{
                    fontSize: "1.1rem",
                    marginRight: "0.25rem",
                    letterSpacing: "-1px",
                    color: "#333",
                    fontWeight: 600,
                  }}
                >
                  {numberFormatFunction(
                    selectedPointId === null
                      ? 0
                      : selectPointList[selectedPointId].point
                  )}
                </span>
                원
              </p>
            </div>
            <div
              style={{
                margin: "0 10px",
                width: "calc(100% - 20px)",
                fontSize: "0.9rem",
                fontWeight: 400,
                color: "#707070",
                display: "flex",
                justifyContent: "space-between",
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            >
              VAT
              <p
                style={{
                  fontSize: "0.9rem",
                  fontWeight: 400,
                  color: "#404040",
                }}
              >
                <span
                  style={{
                    fontSize: "0.9rem",
                    fontWeight: 400,
                    color: "#707070",
                    marginRight: "0.25rem",
                    letterSpacing: "-1px",
                  }}
                >
                  {numberFormatFunction(
                    selectedPointId === null
                      ? 0
                      : selectPointList[selectedPointId].point * 0.1
                  )}
                </span>
                원
              </p>
            </div>
            <div
              style={{
                margin: "0 1.5rem",
                width: "calc(100% - 3rem)",
                borderBottom: "1px solid #cdcdcd",
                display: "flex",
                justifyContent: "space-between",
                color: "#707070",
                fontWeight: 400,
                padding: "0.25rem 1rem",
                listStyle: "none",
              }}
            ></div>
            <div
              style={{
                margin: "10px 10px 0 10px",
                fontWeight: "bold",
                width: "calc(100% - 20px)",
                padding: "0.5rem 1rem",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                color: "#707070",
                listStyle: "none",
              }}
            >
              총 입금금액
              <p
                style={{
                  color: "#222",
                  fontSize: "0.9rem",
                }}
              >
                <span
                  style={{
                    color: "#222",
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    marginRight: "0.25rem",
                    letterSpacing: "-1px",
                  }}
                >
                  {numberFormatFunction(
                    selectedPointId === null
                      ? 0
                      : selectPointList[selectedPointId].point +
                          selectPointList[selectedPointId].point * 0.1
                  )}
                </span>
                원
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            flexShrink: 0,
            fontSize: "0.9rem",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "stretch",
            padding: "0 10px 10px 10px",
            height: "auto",
            backgroundColor: "#fff",
            border: "1px solid rgba(221, 178, 11, 0.3)",
          }}
        >
          <ChargeTitle>결제정보</ChargeTitle>
          <ul
            style={{
              margin: "0 1rem",
              listStyle: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#909090",
                padding: "0.25rem 0",
                listStyle: "none",
              }}
            >
              <span
                style={{
                  color: "#909090",
                }}
              >
                입금금액
              </span>
              <span
                style={{
                  fontWeight: 600,
                  color: "#707070",
                }}
              >
                {numberFormatFunction(
                  selectedPointId === null
                    ? 0
                    : selectPointList[selectedPointId].point +
                        selectPointList[selectedPointId].point * 0.1
                )}
                원
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#909090",
                padding: "0.25rem 0",
                listStyle: "none",
              }}
            >
              <span
                style={{
                  color: "#909090",
                }}
              >
                입금자정보
              </span>
              <span
                style={{
                  minWidth: "7rem",
                  fontWeight: 600,
                  color: "red",
                }}
              >
                <BuyerNameInput
                  type="text"
                  value={depositorInfo}
                  onChange={(e) => {
                    setDepositorInfo(e.target.value);
                  }}
                ></BuyerNameInput>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#909090",
                padding: "0.25rem 0",
                listStyle: "none",
              }}
            >
              <span
                style={{
                  color: "#909090",
                }}
              >
                결제방법
              </span>
              <span
                style={{
                  fontWeight: 600,
                  color: "#707070",
                }}
              >
                무통장
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#909090",
                padding: "0.25rem 0",
                listStyle: "none",
              }}
            >
              <span
                style={{
                  color: "#909090",
                }}
              >
                예금주
              </span>
              <span
                style={{
                  fontWeight: 600,
                  color: "#707070",
                }}
              >
                주식회사 핑거네이션
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#909090",
                padding: "0.25rem 0",
                listStyle: "none",
              }}
            >
              <span
                style={{
                  color: "#909090",
                }}
              >
                은행명
              </span>
              <span
                style={{
                  fontWeight: 600,
                  color: "#707070",
                }}
              >
                KB국민은행
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#909090",
                padding: "0.25rem 0",
                listStyle: "none",
              }}
            >
              <span
                style={{
                  color: "#909090",
                }}
              >
                계좌번호
              </span>
              <span
                style={{
                  fontWeight: 600,
                  color: "#707070",
                }}
              >
                868201-00-060687
              </span>
            </div>
            <div
              style={{
                margin: "10px 0",
                display: "flex",
                color: "#909090",
                padding: "0.25rem 0",
                justifyContent: "center",
                listStyle: "none",
              }}
            >
              <AgreeSection>
                <CheckBox
                  id="necessaryAgreeCheckbox"
                  type="checkbox"
                  checked={isNecessaryAgreeChecked}
                  onChange={handleNecessaryAgreeCheckboxChange}
                />
                <AgreeLabel>
                  머니모아의{" "}
                  <AgreeLink
                    href={terms_and_conditions_inner_router_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    이용약관
                  </AgreeLink>
                  과{" "}
                  <AgreeLink
                    href={privacy_policy_inner_router_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    개인정보처리방침
                  </AgreeLink>{" "}
                  그리고{" "}
                  <AgreeLink
                    href={refund_policy_inner_router_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    환불정책
                  </AgreeLink>
                  에 동의합니다.
                </AgreeLabel>
              </AgreeSection>
            </div>
          </ul>
          <button
            style={{
              backgroundColor: "rgba(221, 178, 11, 1)",
              color: "#fff",
              width: "100%",
              padding: "15px",
              fontSize: "1rem",
              fontWeight: "bold",
              borderRadius: "5px",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={async () => {
              console.log("결제하기");

              if (!isNecessaryAgreeChecked) {
                alert("필수 약관에 동의해주세요.");
              } else {
                if (depositorInfo === "") {
                  alert("입금자 정보를 입력해주세요.");
                } else {
                  if (selectedPointId === null) {
                    alert("포인트를 선택해주세요.");
                  } else {
                    showLoading();

                    const response = await postAdvertiserChargePointHistory(
                      selectedPointId === null
                        ? 0
                        : selectPointList[selectedPointId].point +
                            selectPointList[selectedPointId].point * 0.1,
                      selectedPointId === null
                        ? 0
                        : selectPointList[selectedPointId].point * 0.1,
                      depositorInfo,
                      1,
                      1,
                      true,
                      true,
                      true,
                      selectedPointId === null
                        ? 0
                        : selectPointList[selectedPointId].point +
                            selectPointList[selectedPointId].bonus,
                      selectedPointId === null
                        ? 0
                        : selectPointList[selectedPointId].point,
                      selectedPointId === null
                        ? 0
                        : selectPointList[selectedPointId].bonus,
                      1,
                      null,
                      null,
                      1,
                      "868201-00-060687",
                      "주식회사 핑거네이션",
                      new Date()
                    );

                    console.log(response);

                    if (parseInt(response.status) === 201) {
                      setSelectedPointId(null);
                      setIsNecessaryAgreeChecked(false);
                      alert("결제가 완료되었습니다.");
                    }
                    hideLoading();
                  }
                }
              }
            }}
          >
            결제하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default PointChargeChargeTab;

const ChargeTitle = styled.h3`
  padding: 0.75rem 0.5rem 0.25rem 0.5rem;
  margin: 0 auto 10px auto;
  width: auto;
  flex-grow: 0;
  border-bottom: 3px solid rgba(221, 178, 11, 1);
  color: rgba(221, 178, 11, 1);
  font-size: 1.2rem;
`;

const HasPointSection = styled.div`
  width: 80%;
  display: flex;
  justify-content: right;
  color: #707070;
  margin: 0 auto;
  margin-bottom: 0.5rem;
`;

const BuyList = styled.ul`
  border-top: 3px solid rgba(221, 178, 11, 0.5);
  width: 80%;
  margin: 0 auto;
  list-style: none;
`;

const BuyListHeader = styled.li`
  border-bottom: 1px solid rgba(221, 178, 11, 0.5);
  font-weight: bold;
  padding: 1rem;
  padding-left: 0;
  padding-right: 2rem;
  margin-bottom: 1rem;
  display: flex;
  list-style: none;
`;

const PointTile = styled.li`
  background-color: ${(props) =>
    props.isselected === "true" ? "rgba(221, 178, 11, 0.1)" : "#fff"};
`;

const BuyerNameInput = styled.input`
  color: #ff4345;
  font-weight: 600;
  text-align: right;
  border: 1px solid rgb(211, 219, 236);
  padding: 0.25rem;
  border-radius: 0.25rem;
  max-width: fit-content !important;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const AgreeSection = styled.div`
  background-color: #f4f4f4;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  justify-content: center;
`;

const CheckBox = styled.input`
  width: auto;
  margin-top: 2px;
  padding: 0;
  background-color: transparent;
`;

const AgreeLabel = styled.label`
  font-weight: 400;
  width: 350px;
  margin-left: 5px;
  font-size: 0.8rem;
`;

const AgreeLink = styled.a`
  color: black;

  &:visited {
    color: black;
  }
`;
